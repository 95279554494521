import PageDashboard from "pages/PageDashboard";
import PageVictims from "pages/PageVictims";
import PageVictimsSearch from "pages/PageVictimsSearch";
import PageVictimsNew from "pages/PageVictimsNew";

import PageSurvivors from "pages/PageSurvivors";
import PageSurvivorsNew from "pages/PageSurvivorsNew";
import PageSurvivorSearch from "pages/PageSurvivorSearch";

import PageReportsTouchpoints from "pages/PageReportsTouchpoints";
import PageReportsCases from "pages/PageReportsCases";

import PageCaseSubmissions from "pages/PageCaseSubmissions";

import PageContactsList from "pages/PageContactsList";
import PageTasksList from "pages/PageTasksList";

import PageNotificationsList from "pages/PageNotificationsList";
import PageAdminImport from "pages/PageAdminImport";

var dashRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: PageDashboard,
    layout: "",
  },
  {
    collapse: true,
    name: "Victims",
    state: "victimsCollapse",
    views: [
      {
        path: "/victims/browse",
        name: "Browse",
        component: PageVictims,
        layout: "",
      },
      {
        path: "/victims/search",
        name: "Search",
        component: PageVictimsSearch,
        layout: "",
      },
      {
        path: "/victims/new",
        name: "New",
        component: PageVictimsNew,
        layout: "",
      },
    ],
  },

  {
    collapse: true,
    name: "Survivors",
    state: "survivorsCollapse",
    views: [
      {
        path: "/survivors/browse",
        name: "Browse",
        component: PageSurvivors,
        layout: "",
      },
      {
        path: "/survivors/search",
        name: "Search",
        component: PageSurvivorSearch,
        layout: "",
      },
      {
        path: "/survivors/new",
        name: "New",
        component: PageSurvivorsNew,
        layout: "",
      },
    ],
  },
  {    
    path: "/contacts/browse",
    name: "Contacts",
    component: PageContactsList,
    layout: "",
  },  
  {
    path: "/submissions/browse",
    name: "Case Submissions",
    component: PageCaseSubmissions,
    layout: "",
  },
  {
    path: "/tasks/browse",
    name: "Tasks",
    component: PageTasksList,
    layout: "",   
  },
  {
    path: "/notifications/browse",
    name: "Notifications",
    component: PageNotificationsList,
    layout: "",   
  }, 
  {
    collapse: true,
    name: "Reports",
    state: "reportsCollapse",
    role: "admin",
    views: [
      {
        path: "/reports/touchpoints",
        name: "Touchpoint Reports",
        component: PageReportsTouchpoints,
        layout: "",
        role: "admin"
      },
      {
        path: "/reports/cases",
        name: "Case Reports",
        component: PageReportsCases,
        layout: "",
        role: "admin"
      },
    ],
  }, 
  {
    collapse: true,
    name: "Admin",
    state: "adminCollapse",
    role: "admin",
    views: [
      {
        path: "/admin/import",
        name: "CSV Import",
        component: PageAdminImport,
        layout: "",
        role: "admin"   
      },
      
    ],
  },
];
export default dashRoutes;
