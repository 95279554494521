import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetAnniversaries(token) {
  const [anniversaries, setAnniversaries] = React.useState();

  const handleResponse = (data) => {
    if (data) setAnniversaries(data);
    else setAnniversaries([]);
  };

  React.useEffect(() => {
    if (token && !anniversaries) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(PATHS.apiAnniversaries, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch(() => setAnniversaries([]));
    }
  }, [token]);

  return { anniversaries };
}
