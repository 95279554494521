import React from "react";
import PATHS from "config/paths";
const cache = new Map();

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetSurvivorsTouchpoints(token, id, page, version,  search) {
  const [touchpoints, setTouchpoints] = React.useState();
  const [touchpointError, setTouchpointError] = React.useState();
  const [touchpointsPageCount, setTouchpointsPageCount] = React.useState();
  const [isLoadingTouchpoints, setIsLoadingTouchpoints] = React.useState(true);

  const handleResponse = (data) => {
    if (data?.length > 0) {
      setTouchpoints(data);
      cache.set(`${page}${id}${search}`, data);
      setIsLoadingTouchpoints(false);
    } else {
      setIsLoadingTouchpoints(false);
      setTouchpointError("no-data");
    }
  };

  React.useEffect(() => {
    const cachedData = page ? cache.get(`${page}${id}${search}`) : null;
    const cachedPageCount = cache.get("page-count");

    if (cachedData && cachedPageCount) {
      setTouchpoints(cachedData);
      setTouchpointsPageCount(cachedPageCount);
      setIsLoadingTouchpoints(false);
    } else if (token && page) {
      setIsLoadingTouchpoints(true);

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      /**
       * Filters
       */
      let filters = "";
      if (page !== 1) filters = `page=${page}`;
      if (filters.length > 0 && search?.length > 0) {
        filters = `${filters}&${search}`;
      } else if (search?.length > 0) {
        filters = search;
      }

      /**
       * Make request
       */
      fetch(`${PATHS.apiSurvivors}/${id}/touchpoints?${filters}`, options)
        .then((response) => {
          const total = Number.parseInt(response.headers.get("total"));
          const perPage = Number.parseInt(response.headers.get("per-page"));
          const count = Math.ceil(total / perPage);
          setTouchpointsPageCount(count);
          cache.set("page-count", count);
          return response.json();
        })
        .then((result) => handleResponse(result))
        .catch((error) => setTouchpointError(error));
    }
  }, [token, page, version, search]);

  return { touchpointsGet: touchpoints, touchpointError, touchpointsPageCount, isLoadingTouchpoints };
}
