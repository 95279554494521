import React from "react";
import { StateContext } from "state.js";
import { useTranslation } from "react-i18next";
import { usePostUserDocumentsApproved, usePostUserDocumentsUnapproved, usePostUserDocumentsApproval } from "hooks";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useSnackbar } from 'notistack';


import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import { Button, IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField, Badge } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/Edit";
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  },
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function UserSubmittedDocuments (props) {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();


  const [userDocumentsToApprove, setUserDocumentsToApprove] = React.useState();
  const { userDocumentsApprovalResponse, userDocumentApprovalError } = usePostUserDocumentsApproval(token, props.caseSubmissionId, userDocumentsToApprove);

  const hasDocuments = props?.submittedDocuments?.length > 0;

  const [preview, setPreview] = React.useState();
  const [selected, setSelected] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const onEdit = (file) => {
    setSelected(file);
    setOpenEdit(true);
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelected(undefined)
  }

  const onPreview = (file) => {
    setPreview([{ uri: file }]);
    setOpen(true);
  }


  const handleClose = () => {
    setOpen(false);
    setPreview([]);
  };

  /********** User Document Approval **************/
  const handleApproveUserDocuments = () => {
    const images = { case_submission: { id: props.caseSubmissionId, approved_documents_attributes: approvedDocuments.map((x) => { return { approved: x.approved, notes: x.notes, id: x.id } }) } }
    setUserDocumentsToApprove(images);
    handleCloseEdit();
  };

  const [approvedDocuments, setApprovedDocuments] = React.useState(props.submittedDocuments);
  const approvedCount = approvedDocuments?.filter(x => x.approved).length;

  const onApproveDocument = (approved, image) => {
    setApprovedDocuments(approvedDocuments.map((item) => (item.id == image.id ? { ...item, approved } : item)));
  };

  React.useEffect(() => {
    if (userDocumentsApprovalResponse) {
      props.refresh(userDocumentsApprovalResponse);
      enqueueSnackbar('Documents Approved', { 
        variant: 'success',
      });
    }
  }, [userDocumentsApprovalResponse])

  const [shouldApproveUserDocuments, setShouldApproveUserDocuments] = React.useState();
  const [shouldUnapproveUserDocuments, setShouldUnapproveUserDocuments] = React.useState();
  const { userDocumentsApprovedSuccess, userDocumentsApprovedError } = usePostUserDocumentsApproved(token, props.caseSubmissionId, shouldApproveUserDocuments);
  const { userDocumentsUnapprovedSuccess, userDocumentsUnapprovedError } = usePostUserDocumentsUnapproved(token, props.caseSubmissionId, shouldUnapproveUserDocuments);

  React.useEffect(() => {
    if (userDocumentsApprovedSuccess) {
      setShouldApproveUserDocuments(false);
      props.refresh(userDocumentsApprovedSuccess);
      enqueueSnackbar('Documents Marked as Final', { 
        variant: 'success',
      });
    }
  }, [userDocumentsApprovedSuccess])

  React.useEffect(() => {
    if (userDocumentsUnapprovedSuccess) {
      setShouldUnapproveUserDocuments(false);
      props.refresh(userDocumentsUnapprovedSuccess);
      enqueueSnackbar('Documents Unmarked as Final', { 
        variant: 'info',
      });
    }
  }, [userDocumentsUnapprovedSuccess])

  const onFinalUserDocumentApproval = (approved) => {
    if (approved) {
      setShouldApproveUserDocuments(true);
    } else {
      setShouldUnapproveUserDocuments(true);
    }
  }

  const handleNoteChange = (document, notes) => {
    console.log(document, notes)
    setApprovedDocuments(approvedDocuments.map((item) => (item.id == document.id ? { ...item, notes } : item)));
  }
  /********** End User Document Approval **************/

  return (
    <>
      <GridContainer className={classes.root} >

        <GridItem xs={8}>
          <h3>User Submitted Documents</h3>
          {!hasDocuments && (
            <div>No User Submitted Documents</div>
          )}
        </GridItem>
        <GridItem xs={4}>
          <Badge badgeContent={approvedCount} color="secondary">
            <Button variant="contained" color="primary" onClick={handleApproveUserDocuments} fullWidth={false} disabled={props.disabled}>
              Approve User Submitted Documents
            </Button>
          </Badge>
          <FormControlLabel
            control={
              <Checkbox
                checked={props?.userDocumentsApproved}
                name="approved-final"
                color="primary"
                onChange={(evt) => onFinalUserDocumentApproval(evt.target.checked)}
                disabled={props.disabled}
              />
            }
            label="Final"
          />
        </GridItem>
        <GridItem xs={12}>
          <TableContainer className={classes.table}>
            <Table aria-label="simple table" className="hover">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell>Approved</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {approvedDocuments?.map((doc) => {
                  return (
                    <TableRow key={doc.id} >
                      <TableCell>{`${doc.id || ""}`}</TableCell>
                      <TableCell onClick={() => onPreview(doc.url)}>{doc.url.split('/').pop()}</TableCell>
                      <TableCell>{doc.notes}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={doc.approved}
                              name={"approved-image-" + doc.id}
                              color="primary"
                              onChange={(e) => {
                                onApproveDocument(e.target.checked, doc);
                              }}
                              disabled={props.disabled}
                            />
                          }
                          label=""
                        />
                        <IconButton aria-label="edit" color="primary" onClick={() => onEdit(doc)} disabled={props.disabled}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      </GridContainer>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Preview
            </Typography>
            
          </Toolbar>
        </AppBar>
        <DocViewer
          prefetchMethod="GET"
          documents={preview}
          pluginRenderers={DocViewerRenderers}
          style={{ minHeight: '100%' }}
        />
      </Dialog>
      <Dialog open={openEdit} onClose={handleCloseEdit} TransitionComponent={Transition}>

        <DialogTitle id="form-dialog-title">Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add notes and approve documents.
          </DialogContentText>

          {selected && (
            <GridContainer p={12}>

              
              <GridItem xs={12} >
                <TextField
                  id={"user-selecteds-note-" + selected.id}
                  label="Note"
                  defaultValue={selected.note}
                  onBlur={(e) => handleNoteChange(selected, e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} >
                <DocViewer
                  documents={[{ uri: selected.url }]}
                  pluginRenderers={DocViewerRenderers}
                  style={{ minHeight: 500 }}
                />
              </GridItem>

            </GridContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseEdit} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApproveUserDocuments} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
