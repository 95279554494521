import React from "react";
import CustomInput from "components-material-ui/CustomInput/CustomInput.js";

export function InputText({ label, name, onChange, helperText, value, type = "text" }) {
  return (
    <label>
      {label}
      <CustomInput
        helperText={helperText}
        id={name}
        formControlProps={{ fullWidth: true }}
        inputProps={{
          value: value,
          onChange: (e) => onChange(e),
          name: name,
          type: type,
        }}
      />
    </label>
  );
}
