import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetActiveUsersList(token) {
  const [activeUsers, setActiveUsers] = React.useState();

  const handleResponse = (data) => {
    if (data) setActiveUsers(data);
    else setActiveUsers([]);
  };

  React.useEffect(() => {
    if (token) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(PATHS.apiActiveUsersList, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch(() => setActiveUsers([]));
    }
  }, [token]);

  return { activeUsers };
}
