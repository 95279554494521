import React from "react";
import PATHS from "config/paths";

export function useGetTouchpointsReport(token, run, start, end, aggregate) {
  const [data, setData] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (resp) => {
    if (resp) {
      setData(resp);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if (token && run && start && end && aggregate) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };
      const s = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
      const e = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
      const url = `${PATHS.apiReportTouchpoints}/${aggregate}/${s}/${e}`;
      // Make request
      fetch(url, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => console.warn("get data error", error));
    }
  }, [token, run, start, end, aggregate]);

  return { touchpointData: data, touchpointDataError: error };
}
