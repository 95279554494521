import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";

import { formatDate, formatDateTime, readableEnum } from "utilities";
import { useSnackbar } from 'notistack';

import { usePostCaseSubmissionNote } from "hooks";
import { Danger, InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Lists
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';


// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import NoteIcon from '@material-ui/icons/Note';
// Material UI
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';


import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const nowrap = {
  "whiteSpace": "nowrap"
};

export function CaseSubmissionNotes (props) {
  const State = React.useContext(StateContext);
  const [user_id] = React.useState(State?.user?.value?.id);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const { response, error, isLoading } = usePostCaseSubmissionNote(token, requestBody, props.caseSubmissionId);
  const { enqueueSnackbar } = useSnackbar();

  const boxSettings = { mb: 6 };

  const notes = [...props.notes];
  const [selectedNote, setSelectedNote] = React.useState({
    id: null,
    note: "",
    user_id: user_id
  });
  const [noteModal, setNoteModal] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      id: selectedNote.id || null,
      note: selectedNote.note || "",
      user_id: user_id,
    },
    validate: (values) => {
      const errors = {};
      if (!values.note) errors.note = "Required";

      return errors;
    },
    onSubmit: (values) => {
      const body = {
        id: values.id,
        note: values.note,
        user_id: values.user_id
      };
      setRequestBody(body)
    },
    enableReinitialize: true,
  });

  const HelperText = {
    note: formik.errors.note && formik.submitCount ? <Danger>{formik.errors.note}</Danger> : null,
  }

  React.useEffect(() => {
    if (response) {
      setRequestBody(false);
      setSelectedNote({
        id: null,
        note: "",
        user_id: user_id
      })
      setNoteModal(false);
      props.refresh(response);
      formik.resetForm();
      enqueueSnackbar('Note saved', { 
        variant: 'success',
      });
    }
  }, [response]);

  var styles = {
    textarea: {
      width: '400px',
    }
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  // Note Editing Methods
  const handleEditNoteClick = (note) => {
    const body = {
      id: note.id,
      note: note.note,
      user_id: note.user_id
    };
    setSelectedNote(body);
    setNoteModal(true);
  }

  const handleAddNoteClick = () => {
    const body = {
      id: 0,
      note: "",
      user_id: user_id
    };
    setSelectedNote(body);
    setNoteModal(true);
  }

  const handleDeleteNoteClick = (note) => {
    console.log(note);
    setDeleteNote(note);
    setDeleteConfirmModal(true);
  }

  const handleDeletedNote = () => {
    console.log("Handle DeleteNote");

    const objIndex = notes.findIndex((obj => obj.id == deleteNote.id));
    if (objIndex !== -1) {
      notes.splice(objIndex, 1);
    }
    setTask(task);
    setNoteModal(false);
    setDeleteConfirmModal(false);
    setDeleteNote(false);
    setIsDeleteConfirmed(false);
  }

  return (
    <React.Fragment>
      <>
        <GridContainer className={classes.root} >
          <GridItem xs={12}>
            <h3>Notes<IconButton edge="end" aria-label="add" onClick={() => {handleAddNoteClick() }}>
              <Add />
            </IconButton></h3>
          </GridItem>
          <GridItem xs={12}>

            <List className={classes.root}>
              {notes && notes.map(n => {
                return (
                  <ListItem key={n.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <NoteIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={formatDateTime(n.updated_at) + ' - ' + n.user.first_name + ' ' + n.user.last_name} secondary={n.note} secondaryTypographyProps={{ style: {whiteSpace: "break-spaces" }} } />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit" onClick={() => {  
                        handleEditNoteClick(n);
                      }
                      }>
                        <Edit />
                      </IconButton>
                      
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          </GridItem>
        </GridContainer>
        {/** Note Edit Modal */}
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={noteModal}
          transition={Transition}
          keepMounted
          onClose={() => setNoteModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setNoteModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{selectedNote ? "Edit" : "New"} Note</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <form autoComplete="off">
              <Card>
                <CardBody>
                  <h3>{props?.name}</h3>
                  <Box {...boxSettings}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          id="standard-multiline-static"
                          label="Note"
                          name="case_submission_note"
                          maxRows={10}
                          aria-label="Note"
                          multiline
                          minRows={20}
                          fullWidth
                          value={formik.values.note}
                          className={classes.textarea}
                          onChange={(event) => formik.setFieldValue("note", event.target.value)} 
                          InputLabelProps={{ shrink: true }}
                        />
                      </GridItem>
                    </GridContainer>
                  </Box>
                  <Box {...boxSettings}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}></GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button color="info" fullWidth onClick={(e) => formik.handleSubmit(e)}>
                          Submit
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}></GridItem>
                    </GridContainer>
                  </Box>
                </CardBody>
              </Card>
            </form>
          </DialogContent>
        </Dialog>
      </>
    </React.Fragment>
  );
}
