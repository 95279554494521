import React from "react";
import moment from "moment";
import styled from "styled-components";
import AdminLayout from "layouts/Admin.js";
import { StateContext } from "state.js";
import { useGetCalendarEvents } from "hooks";
import { color } from "styles/styled";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import overlap from "react-big-calendar/lib/utils/layout-algorithms/overlap";
import { FormEvent } from "components";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import Button from "components-material-ui/CustomButtons/Button.js";

// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const localizer = momentLocalizer(moment);

/* eslint-disable react-hooks/exhaustive-deps */

const StyledCalendar = styled.div`
  .rbc-toolbar .rbc-btn-group:last-child {
    button:last-child,
    button:nth-child(2) {
      display: none;
    }
  }

  .rbc-row-content {
    height: 150px;
  }
  .rbc-date-cell.rbc-now.rbc-current > a {
    color: #33A5FF;
  }
`;

export function CalendarPage() {
  const State = React.useContext(StateContext);
  const [calDate, setCalDate] = React.useState(new Date());
  const [calView, setCalView] = React.useState("month");
  const [calToday] = React.useState(calDate);

  const [token] = React.useState(State?.user?.value?.token);
  const [user_id] = React.useState(State?.user?.value?.id);
  const [events, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [day, setDay] = React.useState(new Date().getDate());
  const { getCalendarEvents } = useGetCalendarEvents(token, month);
  const [sheet, setSheet] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [calendarEvent, setCalendarEvent] = React.useState();
  const [eventInfo, setEventInfo] = React.useState();
  const [deletedEvent, setDeletedEvent] = React.useState();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const getEventColor = (eventType) => {
    if (eventType === "anniversary") return "red";
    if (eventType === "touchpoint") return "azure";
    return "green";
  };

  /**
   * Add new events from calendar events endpoint
   */
  React.useEffect(() => {
    if (getCalendarEvents?.length > 0) {
      const oldEvents = [...events];
      const newEvents = getCalendarEvents.map((item) => {
        const mStartDate = moment(item.start_time, "YYYY-MM-DDTHH:mm:ss.SSS").toDate();
        //const mStartString = `${mStartDate.format("ddd MMMM DD YYYY hh:mm:ss")} GMT-0400 (Eastern Daylight Time)`;

        const mEndDate = moment(item.end_time, "YYYY-MM-DDTHH:mm:ss.SSS").toDate();
        //const mEndString = `${mEndDate.format("ddd MMMM DD YYYY hh:mm:ss")} GMT-0400 (Eastern Daylight Time)`;

        return {
          id: item.id,
          title: item.title,
          description: item.description,
          allDay: item.all_day,
          start: mStartDate,
          end: mEndDate,
          color: getEventColor(item.type),
          link: `/${item.entity_type}s/${item.entity_id}`,
          entity_id: item.entity_id,
          entity_type: item.type,
        };
      });
      const updatedEvents = oldEvents.concat(newEvents);

      setEvents(updatedEvents);
    }
  }, [getCalendarEvents]);

  /**
   * Generate new CSV sheet on date change and view change
   */
  React.useEffect(() => {
    getSheetEvents();
  }, [events, calView, day, year, month]);

  const getSheetEvents = () => {
    if (events.length === 0) return;
    const sheetEvents = [];
    events.forEach((item) => {
      const itemMonth = moment(item.start, "ddd MMMM dd yyyy").month() + 1;
      const itemDay = moment(item.start, "ddd MMMM dd yyyy").date();
      if (itemMonth === month) {
        if (calView === "month") sheetEvents.push(item);
        else if (itemDay === day) sheetEvents.push(item);
      }
    });

    if (sheetEvents.length === 0) {
      setSheet(null);
      return;
    }

    let newSheet = `data:text/csv;charset=utf-8,`;
    sheetEvents.forEach((item) => {
      const formattedDate = moment(item.start).format("MMMM Do YYYY");
      newSheet = `${newSheet}${item.title}, ${formattedDate} \r\n`;
    });
    setSheet(encodeURI(newSheet));
  };

  /**
   * Make download button for CSV sheet and then remove it after click
   */
  const handleDownloadCsv = () => {
    if (!sheet) return;

    var link = document.createElement("a");
    link.setAttribute("href", sheet);
    if (calView === "month") link.setAttribute("download", `pcc-calendar-${month}-${year}.csv`);
    else link.setAttribute("download", `pcc-calendar-${month}-${day}-${year}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();

    setTimeout(() => {
      link.remove();
    }, 500);
  };

  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color ? (backgroundColor = backgroundColor + event.color) : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const selectedEvent = (event) => {
    if (event.entity_type === "event") {
      setCalendarEvent(event);
      setModal(true);
    } else {
      window.open(event.link, "_blank").focus();
    }
  };

  const addNewEventAlert = (slotInfo) => {
    console.log("addNewEventAlert", slotInfo);
    const d = slotInfo.start;
    const newEvent = {
      start: new Date(slotInfo.start),
      end: new Date(slotInfo.start),
      id: undefined,
      title: "",
      description: "",
      user_id: user_id,
      allDay: false,
    };
    setCalendarEvent(newEvent);
    setModal(true);
  };

  /**
   * Handle jump to date
   */
  React.useEffect(() => {
    const jumpToDate = localStorage.getItem("pccCalJumpToDate");
    if (jumpToDate) {
      const newDate = new Date(jumpToDate);
      setCalDate(newDate);
      setMonth(newDate.getMonth() + 1);
      setYear(newDate.getFullYear());
      setCalView("day");
      localStorage.removeItem("pccCalJumpToDate");
    }
  }, []);

  /**
   * Update Calendar add/edit event
   */
  React.useEffect(() => {
    if (eventInfo) {
      const newEvents = [...events];
      const eId = eventInfo.id;
      const eIndex = newEvents.findIndex((item) => eId != null && eId != "" && item.id === eId);
      const mStartDate = moment(eventInfo.start_time, "YYYY-MM-DDTHH:mm:ss.SSS").toDate();
      const mEndDate = moment(eventInfo.end_time, "YYYY-MM-DDTHH:mm:ss.SSS").toDate();
      const newFormattedEvent = {
        id: eventInfo.id,
        title: eventInfo.title,
        description: eventInfo.description,
        allDay: eventInfo.all_day,
        start: mStartDate,
        end: mEndDate,
        color: getEventColor(eventInfo.type),
        link: `/${eventInfo.type}s/${eventInfo.id}`,
        entity_id: eventInfo.entity_id,
        entity_type: eventInfo.type,
      };

      if (eIndex > -1) {
        newEvents[eIndex] = newFormattedEvent;
      } else {
        newEvents.push(newFormattedEvent);
      }

      console.log("events", events);
      console.log("newEvents", newEvents);
      console.log("eventInfo", eventInfo);

      setEvents(newEvents);
      setModal(false);
    }
  }, [eventInfo]);

  React.useEffect(() => {
    if (deletedEvent) {
      const newEvents = [...events];
      const idx = newEvents.findIndex((item) => deletedEvent != null && deletedEvent != "" && item.id === deletedEvent);
      if (idx > -1) {
        newEvents.splice(idx, 1);
      }
      setEvents(newEvents);
      setModal(false);
    }
  }, [deletedEvent]);

  const handleTodayButton = () => {
    setCalDate(calToday);
    setMonth(calToday.getMonth() + 1);
    setYear(calToday.getFullYear());
  };

  const handleBackButton = () => {
    const m = moment(calDate);
    const newDate =
      calView === "month" ? new Date(m.subtract(1, "M").toString()) : new Date(m.subtract(1, "d").toString());

    setCalDate(newDate);
    setMonth(newDate.getMonth() + 1);
    setYear(newDate.getFullYear());
  };

  const handleNextButton = () => {
    const m = moment(calDate);
    const newDate = calView === "month" ? new Date(m.add(1, "M").toString()) : new Date(m.add(1, "d").toString());

    setCalDate(newDate);
    setMonth(newDate.getMonth() + 1);
    setYear(newDate.getFullYear());
  };

  return (
    <div>
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          Calendar
        </Typography>
      </Box>

      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardBody calendar>
              <StyledCalendar>
                <Box py={5} px={2}>
                  <GridContainer>
                    <GridItem xs={4}>
                      <div className="rbc-btn-group">
                        <Button size="sm" color="primary" onClick={handleTodayButton}>
                          Today
                        </Button>{" "}
                        <Button size="sm" color="primary" onClick={handleBackButton}>
                          Back
                        </Button>{" "}
                        <Button size="sm" color="primary" onClick={handleNextButton}>
                          Next
                        </Button>
                      </div>
                    </GridItem>
                    <GridItem xs={4}>
                      <Box component="h3" textAlign="center" m={0} p={0}>
                        {`${calDate.toLocaleString("default", { month: "long" })} ${calDate.getFullYear()} `}
                      </Box>
                    </GridItem>
                    <GridItem xs={4}>
                      <Box textAlign="right" className="rbc-btn-group" display="block">
                        <Button size="sm" color="primary" onClick={() => setCalView("month")}>
                          Month
                        </Button>{" "}
                        <Button size="sm" color="primary" onClick={() => setCalView("day")}>
                          Day
                        </Button>
                      </Box>
                    </GridItem>
                  </GridContainer>
                </Box>

                <BigCalendar
                  onView={(e) => {}}
                  selectable
                  localizer={localizer}
                  events={events}
                  view={calView}
                  //scrollToTime={new Date(1970, 1, 1, 6)}
                  min={moment("7:00am", "h:mma").toDate()}
                  max={moment("9:00pm", "h:mma").toDate()}
                  dayLayoutAlgorithm={(params) => {
                    return overlap({ ...params, minimumStartDifference: 15 });
                  }}
                  date={calDate}
                  onSelectEvent={(event) => selectedEvent(event)}
                  onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                  eventPropGetter={eventColors}
                  onNavigate={(e) => {
                    const newMonth = new Date(e).getMonth() + 1;
                    const newDay = new Date(e).getDate();
                    const newYear = new Date(e).getFullYear();
                    setMonth(newMonth);
                    setDay(newDay);
                    setYear(newYear);
                  }}
                  timeslots={1}
                  step={30}
                  toolbar={false}
                />
              </StyledCalendar>
            </CardBody>
          </Card>

          <GridContainer justifyContent="center" className="hide-from-print">
            <GridItem xs={12} sm={12} md={6}>
              {sheet && (
                <Button color="info" onClick={handleDownloadCsv}>
                  Download CSV
                </Button>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={6} align="right">
              <Button color="info" onClick={() => window.print()}>
                Print
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {/** Event Edit Modal */}

      {modal && (
        <React.Fragment>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            transition={Transition}
            keepMounted
            onClose={() => setModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setModal(false)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>Calendar Event</h4>
            </DialogTitle>
            <DialogContent id="modal-slide-description" className={classes.modalBody}>
              {modal && (
                <FormEvent
                  calendarEvent={calendarEvent}
                  setEventInfo={(e) => {
                    setEventInfo(e);
                  }}
                  setDeletedEvent={(e) => {
                    setDeletedEvent(e);
                  }}
                />
              )}
            </DialogContent>
          </Dialog>
        </React.Fragment>
      )}
    </div>
  );
}

export default function PageCalendar() {
  return <AdminLayout component={<CalendarPage />} />;
}
