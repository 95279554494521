var string = require('lodash/string');

import React from 'react';
import  jsPDF  from 'jspdf'
import html2pdf from 'html2pdf.js'


import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import Contacts from "@material-ui/icons/Contacts"
import ChartistGraph from 'react-chartist';
import DataTable from "react-data-table-component";
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem";
import Button from "components-material-ui/CustomButtons/Button.js";

import html2canvas from "html2canvas";
const pdfConverter = require("jspdf");

export class TouchPointsReport extends React.Component {

    
    render(){
        const summaryData = this.props.summaryData;
        const chartData = this.props.chartData;
        const tableData = this.props.tableData;
        const startDate = this.props.startDate;
        const endDate = this.props.endDate;

        const options = {
            seriesBarDistance: 10,
            axisX: {
                offset: 60
            },
            axisY: {
                offset: 80,
                onlyInteger: true,
                labelInterpolationFnc: function(value) {
                return value + ' '
                },
                scaleMinSpace: 15
            }
        };
    
        const type = 'Bar'

        function convertArrayOfObjectsToCSV(array) {
            let result;
        
            const columnDelimiter = ',';
            const lineDelimiter = '\n';
            const keys = Object.keys(array[0]);
        
            result = '';
            result += keys.map(x => {
              if(x == "touches") return "Touchpoints";
              return _.capitalize(x)
            
            }).join(columnDelimiter);
            result += lineDelimiter;
        
            array.forEach(item => {
                let ctr = 0;
                keys.forEach(key => {
                    if (ctr > 0) result += columnDelimiter;
        
                    result += item[key];
                    
                    ctr++;
                });
                result += lineDelimiter;
            });
        
            return result;
        }

        function downloadCSV(array) {
            const link = document.createElement('a');
            let csv = convertArrayOfObjectsToCSV(array);
            if (csv == null) return;
        
            const filename = 'touchpoint-report-'+ new Date().getTime() +'.csv';
        
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }
        
            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
        }

        function pdfDownloader( ){ 
            try{ 
                var source = document.getElementById('touchpoint-report').innerHTML;

                var opt = { 
                    margin: [30,30,30,30], 
                    filename:  'touchpoint-report-'+ new Date().getTime() +'.pdf', 
                    pagebreak: { after:'#pageBreakers' }, 
                    image: { type: 'jpg', quality: 0.95 }, 
                    supportsForeignObject: false,
                    html2canvas: { 
                        scale: 2, 
                        dpi: 300, 
                        letterRendering: true, 
                        useCORS: true ,
                        allowTaint: true,

                        onclone: function(element){                            						
                            const svgElements = Array.from(element.querySelectorAll('svg'));
                            svgElements.forEach(function(s){
                                const bBox = s.getBBox();
                                s.setAttribute("x", bBox.x);
                                s.setAttribute("y", bBox.y);
                                s.setAttribute("width", bBox.width);
                                s.setAttribute("height", bBox.height);

                                s.removeAttribute('xmlns:ct');
                                s.setAttribute('xmlns', 'http://www.w3.org/2000/xmlns/');
                                //s.removeAttribute('style');
                            });

                            const icons = Array.from(element.getElementsByClassName("MuiSvgIcon-root"));
                            icons.forEach(function(i){
                                i.remove();
                            });

                            const barChart = Array.from(element.getElementsByClassName("ct-chart-bar"))
                            barChart.forEach(function(b){
                                b.remove();
                            });

                            const hide = Array.from(element.getElementsByClassName("print-hide"));
                            hide.forEach(function(h){
                              h.remove();
                          });

                            
                            const foreignElements = Array.from(element.getElementsByTagName("foreignObject"));
                            foreignElements.forEach(function(f){
                                f.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
                            });
                        }
                    }, 
                    jsPDF: { 
                        unit: 'pt', 
                        format: 'a4', 
                        orientation: 'portrait' 
                    }                     
                }; 
                html2pdf()
                    .from(source)
                    .set(opt)
                    .save(); 
            } catch(e) { 
                console.log("Error : ",e) 
            } 
        }
        
        function div2PDF (e) {
            /////////////////////////////
            // Hide/show button if you need
            /////////////////////////////
        
            const but = window.document.getElementsByClassName("btn-print")[0];
            but.style.display = "none";
            let input = window.document.getElementsByClassName("touchpoint-report")[0];
        
            html2canvas(input).then(canvas => {
              const img = canvas.toDataURL("image/png");
              const pdf = new jsPDF("l", "pt");
              pdf.addImage(
                img,
                "png",
                input.offsetLeft,
                input.offsetTop,
                input.clientWidth,
                input.clientHeight
              );
              pdf.save("chart.pdf");
              but.style.display = "block";
            });
          };
        const Print = ({ onPrint }) => <Button className="print-hide" onClick={e => onPrint(e)}>Print</Button>;
        const Export = ({ onExport }) => <Button className="print-hide" onClick={e => onExport(e.target.value)}>Export CSV</Button>;
        const actions = <><Export onExport={() => downloadCSV(tableData)} /><Print onPrint={() => pdfDownloader()} /></>;
        
        return (
            <div id="touchpoint-report" >
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon className="print-hide" color="primary">
                <Contacts />
              </CardIcon>
              <h3>Touchpoints Report</h3>
              <h4>{startDate} to {endDate}</h4>
            </CardHeader>
            <CardBody>
                <h3 className="print-hide">Chart</h3>
                <ChartistGraph data={chartData} options={options} type={type} class="tp-chart"/>
                <div id="tp-table">
              <DataTable
                title={"Touchpoints"}
                actions={actions} 
                data={tableData}
                columns = {[
                  {
                    name: 'Date',
                    selector: 'date',
                    sortable: true,
                  },
                  {
                    name: 'Touchpoints',
                    selector: 'touches',
                    sortable: true,
                  },
                  {
                    name: 'Survivors',
                    selector: 'survivors',
                    sortable: true,
                  }
                ]}
                striped
                persistTableHead            
              />
              </div>
              <h4>Summary</h4>
              <GridContainer>
                <GridItem xs={12} md={3}>
                Total Touchpoints: {summaryData.touches} 
                </GridItem>
                <GridItem xs={12} md={3}>
                Total Survivors: {summaryData.survivors} 
                </GridItem>
                <GridItem xs={12} md={3}>
                Min Touchpoints: {summaryData.min}
                </GridItem>
                <GridItem xs={12} md={3}>
                Max Touchpoints: {summaryData.max}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          </div>
        );
    }
  }
  