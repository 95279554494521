import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostSurvivors(token, requestBody, method = "POST", id) {
  const [body, setBody] = React.useState();
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();

  /**
   * Compair requestBody to body so we don't infinant loop
   */
  React.useEffect(() => {
    if (!lodash.isEqual(requestBody, body)) {
      setError(undefined);
      setResponse(undefined);
      setBody(requestBody);
    }
  }, [requestBody, setBody]);

  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && body) {
      const options = {
        method: method,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      const path = id ? `${PATHS.apiSurvivors}/${id}` : PATHS.apiSurvivors;

      async function postRequest() {
        const request = await fetch(path, options);
        return request.json();
      }

      postRequest()
        .then((data) => {
          if (data) setResponse(data);
          else setError("unknown-error");
        })
        .catch((_error) => setError(_error));
    }
  }, [token, body, setResponse]);

  return { response, survivorsError: error };
}
