import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostStaffDocument(token, id, requestBody) {
  const [upload, setUpload] = React.useState();
  const [uploadError, setUploadError] = React.useState();
  const [body, setBody] = React.useState();

  const handleResponse = (data) => {
    if (data) setUpload(data);
    else setUploadError("error");
  };

  React.useEffect(() => {
    const bodyString = JSON.stringify(body);
    const requestString = JSON.stringify(requestBody);

    if (token && id && requestBody && bodyString !== requestString) {
      setBody(requestBody);
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);

      const formData = new FormData();
      if(requestBody.file) formData.append("file", requestBody.file);

      const options = {
        method: "POST",
        headers: headers,
        redirect: "follow",
        body: formData,
      };

      // Make request
      fetch(`${PATHS.apiCaseSubmissions}/${id}/staff_document`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => setUploadError(error));
    }
  }, [token, requestBody]);

  return { upload, uploadError };
}
