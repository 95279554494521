import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { usePostTask } from "hooks";
import moment from "moment";


import { Danger, ActiveUserDropdown, InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


export function FormTask(props) {
    const State = React.useContext(StateContext);
    const [token] = React.useState(State?.user?.value?.token);
    const [requestBody, setRequestBody] = React.useState();
    const { taskUpdateSuccess, updateTasksError, isLoading } = usePostTask(token, requestBody, props.task ? props.task.id : "");
    const boxSettings = { mb: 6, textAlign: "left" };
    const task=props.task;
    const setTask=props.setTask;

    const formik = useFormik({
        initialValues: {
          id: task && task.id ? task.id : "",
          title: task && task.title ? task.title : "",
          description: task && task.description ? task.description : "",
          assigned_to_id: task && task.created_by ? task.created_by.id : "",
          due_date: task && task.due_date ? task.due_date : moment,
          completed: task && task.completed ? task.completed : false,
        },
        validate: (values) => {
          const errors = {};
          if (!values.title) errors.title = "Required";
          if (!values.assigned_to_id) errors.assigned_to_id = "Please assign a user.";
          if (!values.due_date) errors.due_date = "Please choose a due date.";
    
          return errors;
        },
        onSubmit: (values) => {
          const body = {
            id: values.id,
            title: values.title,
            description: values.description,
            assigned_to_id: values.assigned_to_id,
            due_date: values.due_date
          };
          setRequestBody(body)
        },
        enableReinitialize: true
      });

    const HelperText = {
      title: formik.errors.title && formik.submitCount ? <Danger>{formik.errors.title}</Danger> : null,
      due_date: formik.errors.due_date && formik.submitCount ? <Danger>{formik.errors.due_date}</Danger> : null,
      assigned_to_id: formik.errors.assigned_to_id && formik.submitCount ? <Danger>{formik.errors.assigned_to_id}</Danger> : null,
    }

    var styles = {
      textarea: {
        width: '400px',
      }
    };
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    React.useEffect(() => {
      if (taskUpdateSuccess) {
        setTask(taskUpdateSuccess);
      }
    }, [taskUpdateSuccess]);

    return (
        <React.Fragment>
            <>
              <form autoComplete="off">
                <Card>
                  <CardBody>
                    <h3>{props?.title}</h3>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["title"]}
                            label="Title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                              id="description"
                              label="Description"
                              name="description"
                              maxRows={20}
                              aria-label="Description"
                              multiline
                              rowsmin={10}
                              value={formik.values.description}
                              className={classes.textarea}
                              onChange={formik.handleChange}
                              InputLabelProps={{ shrink: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <ActiveUserDropdown label="Assign To" 
                              onChange={(val) => {formik.setFieldValue("assigned_to_id",val)}} 
                              value={formik.values.assigned_to_id}
                              helperText={HelperText.assigned_to_id} />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          
                          <TextField
                            id="due_date"
                            label="Due Date"
                            type="date"                            
                            defaultValue={moment(formik.values.due_date, "YYYY-MM-DD").format('YYYY-MM-DD')}
                            onChange={formik.handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={HelperText["due_date"]}
                          />
                        </FormControl>
                        </GridItem>
                      </GridContainer>
                    </Box>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button color="info" fullWidth onClick={formik.handleSubmit}>
                            Submit
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                    </Box>
                  </CardBody>
                </Card>
              </form>
            </>
        </React.Fragment>
    );
}
