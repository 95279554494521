import React from "react";

import { StateContext } from "state.js";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormSocial } from "components/FormSocial";
import { useDeleteSocial } from "hooks";


// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Styles
import styles from "assets/jss/pages/PageStyles.js";
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function Social (props) {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const { socials, case_id, setRefresh } = props;
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);

  const classes = useStyles();

  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);

  const initialSocial = {
    id: null,
    post_type: "",
    link: "",
    date_of_post: new Date(),
    description: "",
  };

  const [selectedSocialPost, setSelectedSocialPost] = React.useState(initialSocial);
  const [pendingDeleteSocial, setPendingDeleteSocial] = React.useState(initialSocial);
  const [editedSocial, setSocial] = React.useState();
  const [editSocialModal, setEditSocialModal] = React.useState(false);

  const { socialDeleteSuccess, socialDeleteError, socialIsLoading } = useDeleteSocial(token, pendingDeleteSocial, isDeleteConfirmed) 

  const myRef = React.useRef(null)

  React.useEffect(() => {
    if (isDeleteConfirmed) {
      setPendingDeleteSocial(undefined);
      setRefresh(true);
    }

  }, [isDeleteConfirmed])

  const handleAddSocial = () => {
    setSelectedSocialPost(initialSocial);
    setEditSocialModal(true);
  };

  const handleEditSocial = (s) => {
    setSelectedSocialPost(s);
    setEditSocialModal(true);
  }

  const handleDeleteSocial = (s) => {
    setPendingDeleteSocial(s);
    setDeleteConfirmModal(true);
  }

  React.useEffect(() => {
    if (editedSocial) {
      setSocial(null);
      setEditSocialModal(false);
      setRefresh(true);
    }
  }, [editedSocial])

  React.useEffect(() => {
    if (editedSocial || socialDeleteSuccess) {
      setPendingDeleteSocial(null);
      setDeleteConfirmModal(false);
      setRefresh(true);
    }
  }, [socialDeleteSuccess])

  return <>
    <Box mb={10}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4>Social</h4>
          <Button size="sm" color="primary" onClick={handleAddSocial}>
            Add Social Post
          </Button>
        </GridItem>
        {
          socials.map(function (s) {
            return <GridItem xs={12} sm={12} md={4} key={s.id}>
              <Card className={classes.root}>
                <CardActionArea>

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {s.date_of_post} - {s.post_type}
                    </Typography>
                    <Typography gutterBottom variant="subtitle2" color="textSecondary" component="div">
                      <div><a href={s.link} target="_blank">{s.link}</a></div>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div">
                      <div>{s.description} </div>
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="sm" color="primary" onClick={() => handleEditSocial(s)}>
                    Edit
                  </Button>
                  <Button size="sm" color="primary" onClick={() => handleDeleteSocial(s)}>
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </GridItem>;
          })
        }
      </GridContainer>
    </Box>
    {/** Social Edit Modal */}
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={editSocialModal}
      transition={Transition}
      keepMounted
      onClose={() => setEditSocialModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setEditSocialModal(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Edit Social Post</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <FormSocial case_id={case_id} post={selectedSocialPost} setSocial={setSocial} />
      </DialogContent>
    </Dialog>
    {/** Delete Confirm Dialog */}
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={deleteConfirmModal}
      transition={Transition}
      keepMounted
      onClose={() => setDeleteConfirmModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setDeleteConfirmModal(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Confirm Delete</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <h5>Are you sure you want to do this?</h5>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button onClick={() => setDeleteConfirmModal(false)}>Never Mind</Button>
        <Button onClick={() => setIsDeleteConfirmed(true)} color="success">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}
