import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { useTranslation } from 'react-i18next';

import { usePatchSurvivorsTouchpoints, usePostSurvivorsTouchpoints } from "hooks";
import { Danger, InputDate, InputText } from "components";
import { formatDate, readableEnum } from "utilities";
import { service_provided, touchpoint_category } from "config/enums";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

/* eslint-disable react-hooks/exhaustive-deps */

export function FormTouchpoints(props) {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [patchRequestBody, setPatchRequestBody] = React.useState();
  const [postRequestBody, setPostRequestBody] = React.useState();
  const boxSettings = { mb: 6 };
  const { touchpointsPatch, touchpointsPatchError, touchpointsPatchIsLoading } = usePatchSurvivorsTouchpoints(
    token,
    patchRequestBody
  );

  const { touchpointsPost, touchpointsPostError, touchpointsPostIsLoading } = usePostSurvivorsTouchpoints(
    token,
    postRequestBody,
    props?.survivor_id
  );

  // TODO: Should services_provided be an array of items?

  const formik = useFormik({
    initialValues: {
      id: props.touchpoint?.id ? props.touchpoint?.id : "",
      title: props.touchpoint?.title ? props.touchpoint?.title : "Contact",
      date: props.touchpoint?.date ? props.touchpoint?.date : "",
      category: props.touchpoint?.category ? props.touchpoint?.category : "",
      duration: props.touchpoint?.duration ? props.touchpoint?.duration : "0 minutes",
      services_provided: props.touchpoint?.services_provided ? props.touchpoint?.services_provided : "",
      notes: props.touchpoint?.notes ? props.touchpoint?.notes : "",
      victim: props.touchpoint?.victim ? props.touchpoint.victim : "",
      survivor_id: props.survivor_id,
    },
    validate: (values) => {
      const errors = {};

      if (!values.title) errors.title = "Required";
      if (!values.category) errors.category = "Required";
      if (!values.duration) errors.duration = "Required";
      if (!values.services_provided) errors.services_provided = "Required";
      if (!values.notes) errors.notes = "Required";
      if (!values.survivor_id) errors.survivor_id = "Required";

      if (!values.date) {
        errors.date = "Required";
      } else if (moment(values.date).isAfter(moment())) {
        errors.date = "No future dates";
      }

      return errors;
    },
    onSubmit: (values) => {
      const body = {
        title: values.title,
        date: values.date,
        category: values.category,
        duration: values.duration,
        services_provided: values.services_provided,
        notes: values.notes,
        victim: values.victim,
        survivor_id: values.survivor_id,
      };
      if (props.method === "patch") {
        setPatchRequestBody({ ...body, id: values.id });
      } else if (props.method === "post") {
        setPostRequestBody({ ...body });
      }
    },
    enableReinitialize: true,
  });

  const localOnChange = (val) => {
    console.log('local onchange called', val); // this works
    formik.setFieldValue("victim", val.target.value);
  }

  const HelperText = {
    title: formik.errors.title && formik.submitCount ? <Danger>{formik.errors.title}</Danger> : null,
    date: formik.errors.date && formik.submitCount ? <Danger>{formik.errors.date}</Danger> : null,
    category: formik.errors.category && formik.submitCount ? <Danger>{formik.errors.category}</Danger> : null,
    duration: formik.errors.duration && formik.submitCount ? <Danger>{formik.errors.duration}</Danger> : null,
    services_provided:
      formik.errors.services_provided && formik.submitCount ? <Danger>{formik.errors.services_provided}</Danger> : null,
    notes: formik.errors.notes && formik.submitCount ? <Danger>{formik.errors.notes}</Danger> : null,
    survivor: formik.errors.survivor && formik.submitCount ? <Danger>{formik.errors.survivor}</Danger> : null,
  };

  React.useEffect(() => {
    if (touchpointsPatch) props.callback(touchpointsPatch);
    else if (touchpointsPost) props.callback(touchpointsPost);
  }, [touchpointsPatch, touchpointsPost]);

  return (
    <React.Fragment>
      {(touchpointsPatchError || touchpointsPostError) && (
        <Box textAlign="center" py={5}>
          <Danger>{t('error.general')}</Danger>
        </Box>
      )}

      {(touchpointsPatchIsLoading || touchpointsPostIsLoading) && (
        <Box textAlign="center" py={5}>
          <p>{t('wait')}</p>
          <CircularProgress />
        </Box>
      )}

      {!touchpointsPatchIsLoading && !touchpointsPostIsLoading && (
        <>
          <form autoComplete="off">
            <Card>
              <CardBody>
                <h3>{props?.title}</h3>
                <Box {...boxSettings}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <InputText
                        helperText={HelperText["title"]}
                        label={t('survivor.touchpoint.title')}
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>   
                    <FormControl fullWidth>
                      <InputLabel htmlFor="victim-select">Victim</InputLabel>
                      <Select                        
                        onChange={localOnChange}
                        inputProps={{
                          id: "victimselect",
                          value: formik.values.victim,   
                          onChange: localOnChange,                      
                          name: "victimselect",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {props.associatedSurvivors.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.id} {item.name}
                              </MenuItem>
                            );
                          })}
                        
                      </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </Box>

                <Box {...boxSettings}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="relationship">{t('survivor.touchpoint.category')}</InputLabel>
                        <Select
                          onChange={(e) => formik.handleChange(e)}
                          inputProps={{
                            value: formik.values.category,
                            onChange: (e) => formik.handleChange(e),
                            name: "category",
                            id: "category",
                          }}
                        >
                          <MenuItem disabled>Choose one</MenuItem>
                          {touchpoint_category.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {readableEnum(item)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {HelperText["category"]}
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="services_provided">{t('survivor.touchpoint.service')}</InputLabel>
                        <Select
                          onChange={(e) => formik.handleChange(e)}
                          inputProps={{
                            value: formik.values.services_provided,
                            onChange: (e) => formik.handleChange(e),
                            name: "services_provided",
                            id: "services_provided",
                          }}
                        >
                          <MenuItem disabled>Choose one</MenuItem>
                          {service_provided.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {readableEnum(item)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {HelperText["services_provided"]}
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="duration">{t('survivor.touchpoint.duration')}</InputLabel>
                        <Select
                          onChange={(e) => formik.handleChange(e)}
                          inputProps={{
                            value: formik.values.duration,
                            onChange: (e) => formik.handleChange(e),
                            name: "duration",
                            id: "duration",
                          }}
                        >
                          <MenuItem disabled>Choose one</MenuItem>
                          {[
                            "0 minutes",
                            "15 minutes",
                            "30 minutes",
                            "45 minutes",
                            "1 hour",
                            "1 hour, 15 minutes",
                            "1 hour, 30 minutes",
                            "1 hour, 45 minutes",
                            "2 hours",
                            "2 hours, 15 minutes",
                            "2 hours, 30 minutes",
                            "2 hours, 45 minutes",
                            "3 hours",
                            "3 hours, 15 minutes",
                            "3 hours, 30 minutes",
                            "3 hours, 45 minutes",
                            "4 hours",
                            "4 hours, 15 minutes",
                            "4 hours, 30 minutes",
                            "4 hours, 45 minutes",
                            "5 hours",
                            "5 hours, 15 minutes",
                            "5 hours, 30 minutes",
                            "5 hours, 45 minutes",
                            "6 hours",
                            "6 hours, 15 minutes",
                            "6 hours, 30 minutes",
                            "6 hours, 45 minutes",
                            "7 hours",
                            "7 hours, 15 minutes",
                            "7 hours, 30 minutes",
                            "7 hours, 45 minutes",
                            "8 hours",
                          ].map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {readableEnum(item)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {HelperText["duration"]}
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </Box>

                <Box {...boxSettings}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <InputDate label={t('survivor.touchpoint.date')} name="date" value={formik.values.date} onChange={formik.setFieldValue} />
                      {HelperText["date"]}
                    </GridItem>
                  </GridContainer>
                </Box>

                <Box {...boxSettings}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        className="text-area"
                        style={{ width: "100%" }}
                        name="notes"
                        label={t('survivor.touchpoint.note')}
                        multiline
                        maxRows={4}
                        value={formik.values.notes}
                        onChange={(e) => formik.handleChange(e)}
                      />
                      {HelperText["notes"]}
                    </GridItem>
                  </GridContainer>
                </Box>

                <Box {...boxSettings}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}></GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Button color="info" fullWidth onClick={formik.handleSubmit}>
                      {t('survivor.touchpoint.submit')}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}></GridItem>
                  </GridContainer>
                </Box>
              </CardBody>
            </Card>
          </form>
        </>
      )}
    </React.Fragment>
  );
}
