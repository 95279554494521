import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import usePostEvent from "hooks/event/usePostEvent";
import useDeleteEvent from "hooks/event/useDeleteEvent";

import moment from "moment";

import { Danger, ActiveUserDropdown, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";

// Material UI
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function getInputDateTime(inputDate) {
  if (!inputDate) return "";

  let dateTime = moment(inputDate).format(`YYYY-MM-DDTHH:mm`);

  return dateTime;
}

export function FormEvent(props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const { eventUpdateSuccess } = usePostEvent(token, requestBody, props?.calendarEvent?.id);
  const boxSettings = { mb: 6, textAlign: "left" };
  const calendarEvent = props.calendarEvent;
  const setEventInfo = props.setEventInfo;
  const setDeletedEvent = props.setDeletedEvent;

  const [idToDelete, setIdToDelete] = React.useState();
  const { isDeleteComplete, eventsError } = useDeleteEvent(token, idToDelete);

  const formik = useFormik({
    initialValues: {
      id: calendarEvent?.id || "",
      title: calendarEvent?.title || "",
      description: calendarEvent?.description || "",
      user_id: calendarEvent?.user_id || State?.user?.value?.id,
      start_time: getInputDateTime(calendarEvent?.start),
      end_time: getInputDateTime(calendarEvent?.end),
      allDay: calendarEvent?.allDay || false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.title) errors.title = "Required";
      if (values.end_time < values.start_time) errors.start_time = "Can't end before you start!"
      return errors;
    },
    onSubmit: (values) => {
      const body = {
        id: values.id,
        title: values.title,
        description: values.description,
        user_id: values.user_id,
        start_time: values.start_time,
        end_time: values.end_time,
        all_day: values.allDay,
      };
      setRequestBody(body);
    },
  });

  const handleDelete = () => {
    setIdToDelete(calendarEvent?.id)
  }
  
  React.useEffect(() => {
    if(isDeleteComplete) {
      const id = idToDelete;
      setDeletedEvent(id);
      setIdToDelete(undefined);
    }
  }, [isDeleteComplete])

  const HelperText = {
    title: formik.errors.title && formik.submitCount ? <Danger>{formik.errors.title}</Danger> : null,
    start_time: formik.errors.start_time && formik.submitCount ? <Danger>{formik.errors.start_time}</Danger> : null,
    description: formik.errors.description && formik.submitCount ? <Danger>{formik.errors.description}</Danger> : null,
    assigned_to_id:
      formik.errors.assigned_to_id && formik.submitCount ? <Danger>{formik.errors.assigned_to_id}</Danger> : null,
  };

  const styles = {
    textarea: {
      width: "400px",
    },
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  React.useEffect(() => {
    if (eventUpdateSuccess) {
      setEventInfo(eventUpdateSuccess);
    }
  }, [eventUpdateSuccess]);

  return (
    <React.Fragment>
      <>
        <form autoComplete="off">
          <Card>
            <CardBody>
              <h3>{props?.title}</h3>
              <Box {...boxSettings}>
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputText
                      helperText={HelperText["title"]}
                      label="Title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="description"
                      label="Description"
                      name="description"
                      maxRows={20}
                      aria-label="Description"
                      multiline
                      rowsmin={10}
                      value={formik.values.description}
                      className={classes.textarea}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </GridItem>
                  
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <TextField
                       helperText={HelperText["start_time"]}
                        id="start_time"
                        label="Start Time"
                        type="datetime-local"
                        defaultValue={formik.values.start_time}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="end_time"
                        label="End Time"
                        type="datetime-local"
                        defaultValue={formik.values.end_time}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="allDay"
                          checked={formik.values.allDay}
                          onChange={formik.handleChange}
                          name="allDay"
                          color="primary"                          
                        />
                      }
                      label="All Day Event"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <ActiveUserDropdown
                        label="Assign To"
                        onChange={(val) => {
                          formik.setFieldValue("user_id", val);
                        }}
                        value={formik.values.user_id}
                        helperText={HelperText.user_id}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button color="info" fullWidth onClick={formik.handleSubmit}>
                      Submit
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {calendarEvent?.id  &&
                      <Button color="warning" fullWidth onClick={handleDelete}>
                        Delete
                      </Button>
                    }
                  </GridItem>
                </GridContainer>
              </Box>
            </CardBody>
          </Card>
        </form>
      </>
    </React.Fragment>
  );
}
