import React from "react";
import { useTranslation } from 'react-i18next';

import { Danger, InputText } from "components";
import { readableEnum, formatDate } from "utilities";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { useGetSurvivors, useGetVictims } from "hooks";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";

// Material UI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

/**
 * @param {*} props
 * setObject: way to pass back the victim object that is selected
 * setClose: function that will close the modal this is in
 * setType: set state for parent, the same as searchFor state
 * choice: don't let user switch types
 */

/* eslint-disable react-hooks/exhaustive-deps */

export function Connections(props) {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [searchFor, setSearchFor] = React.useState(props?.type);
  const setType = props?.setType;
  const [token] = React.useState(State?.user?.value?.token);
  const [requestVictimBody, setRequestVictimBody] = React.useState();
  const [requestSurvivorBody, setRequestSurvivorBody] = React.useState();
  const [page, setPage] = React.useState();
  const [pageCount, setPageCount] = React.useState();
  const { victims, victimsError, victimsPageCount, isLoading } = useGetVictims(token, page, requestVictimBody, true);
  const { survivors, survivorsError, survivorsPageCount, survivorsIsLoading } = useGetSurvivors(
    token,
    page,
    requestSurvivorBody,
    true
  );
  const boxSettings = { mb: 6 };
  const [cachedVictims, setCachedVictims] = React.useState();
  const [cachedSurvivors, setCachedSurvivors] = React.useState();

  const handleSearchForReset = () => {
    setPageCount(0);
    setPage(0);
    setCachedSurvivors(undefined);
    setCachedVictims(undefined);
    setRequestSurvivorBody(undefined);
    setRequestVictimBody(undefined);
  };

  const validate = (values) => {
    if (!values.by_first_name && !values.by_last_name) {
      return {
        by_first_name: "Required",
        by_last_name: "Required",
      };
    } else {
    }

    return {};
  };

  const formik = useFormik({
    initialValues: {
      by_first_name: "",
      by_last_name: "",
    },
    validate,
    onSubmit: (values) => {
      handleSearchForReset();
      let count = 0;
      const params = Object.keys(values)
        .map((item) => {
          const seperator = count === 0 ? "" : "&";
          if (values[item]) {
            count++;
            return `${seperator}${item}=${values[item]}`;
          }
          return "";
        })
        .join("")
        .replaceAll(" ", "%20");

      if (searchFor === "victim") setRequestVictimBody(params);
      else if (searchFor === "survivor") setRequestSurvivorBody(params);

      setPage(1);
    },
  });

  React.useEffect(() => {
    if (victimsPageCount && searchFor === "victim") setPageCount(victimsPageCount);
  }, [victimsPageCount]);

  React.useEffect(() => {
    if (survivorsPageCount && searchFor === "survivor") setPageCount(survivorsPageCount);
  }, [survivorsPageCount]);

  React.useEffect(() => {
    if (victims) setCachedVictims(victims);
  }, [victims]);

  React.useEffect(() => {
    if (survivors) setCachedSurvivors(survivors);
  }, [survivors]);

  return (
    <>
      <Box {...boxSettings}>
        {(formik.errors.by_last_name || formik.errors.by_last_name) && (
          <Danger>Enter a first or last name to search</Danger>
        )}

        {props.choice && (
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
              size="sm"
              onClick={() => {
                handleSearchForReset();
                setSearchFor("survivor");
                setType("survivor");
              }}
              disabled={searchFor === "survivor" ? true : false}
            >
              {t('survivor.label')}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                handleSearchForReset();
                setSearchFor("victim");
                setType("victim");
              }}
              disabled={searchFor === "victim" ? true : false}
            >
              {t('victim.label')}
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Box {...boxSettings}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
                Enter a first or last name to search
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <InputText
              label={t('firstname')}
              name="by_first_name"
              value={formik.values.by_first_name}
              onChange={formik.handleChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <InputText
              label={t('lastname')}
              name="by_last_name"
              value={formik.values.by_last_name}
              onChange={formik.handleChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button fullWidth onClick={formik.handleSubmit} color="info">
            {t('search')}
            </Button>
          </GridItem>
        </GridContainer>
      </Box>

      <Box {...boxSettings}>
        {(victimsError === "no-data" || survivorsError === "no-data") && (
          <Box textAlign="center" pb={5}>
            <Danger>{t('victim.none')}</Danger>
          </Box>
        )}

        {cachedVictims && cachedVictims?.length > 0 && !isLoading && searchFor === "victim" && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('causeofdeath')}</TableCell>
                  <TableCell>{t('deathdate')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cachedVictims.map((victim, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {victim.first_name} {victim.last_name} {`(${victim.case.case_number || ''})`}
                      </TableCell>
                      <TableCell>{readableEnum(victim.cause_of_death)}</TableCell>
                      <TableCell>{formatDate(victim.date_of_death)}</TableCell>
                      <TableCell>
                        <Box textAlign="right">
                          <Button
                            color="primary"
                            onClick={() => {
                              if (props.setType) props.setType(searchFor);
                              if (props.setObject) props.setObject(victim);
                              if (props.setClose) props.setClose();
                            }}
                          >
                            Select
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {cachedSurvivors && cachedSurvivors?.length > 0 && !survivorsIsLoading && searchFor === "survivor" && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('survivor.label')} {t('name')}</TableCell>
                  <TableCell>{t('victim.label')} {t('name')}</TableCell>
                  <TableCell>{t('victim.relation')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cachedSurvivors.map((survivor, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {survivor.first_name} {survivor.last_name}
                      </TableCell>
                      <TableCell>
                        {survivor.case.victim.first_name} {survivor.case.victim.last_name} {`(${survivor.case.case_number || ''})`}
                      </TableCell>
                      <TableCell>{readableEnum(survivor.relation_to_victim)}</TableCell>
                      <TableCell>
                        <Box textAlign="right">
                          <Button
                            color="primary"
                            onClick={() => {
                              if (props.setType) props.setType(searchFor);
                              if (props.setObject) props.setObject(survivor);
                              if (props.setClose) props.setClose();
                            }}
                          >
                            Select
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {cachedVictims && searchFor === "victim" && pageCount && (
          <Box textAlign="center" mt={5}>
            <Pagination disabled={isLoading} page={page} count={pageCount} onChange={(e, p) => setPage(p)} />
          </Box>
        )}

        {cachedSurvivors && searchFor === "survivor" && pageCount && (
          <Box textAlign="center" mt={5}>
            <Pagination disabled={survivorsIsLoading} page={page} count={pageCount} onChange={(e, p) => setPage(p)} />
          </Box>
        )}
      </Box>
    </>
  );
}
