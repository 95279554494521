import React, { useEffect } from 'react';
import { StateContext } from "state.js";
import PATHS from "config/paths";

import { useActionCable } from 'use-action-cable';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";


import Button from "components-material-ui/CustomButtons/Button.js";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import Assignment from '@material-ui/icons/Assignment';
import ClearIcon from '@material-ui/icons/Clear';
import PageviewIcon from '@material-ui/icons/Pageview';
import BlockIcon from '@material-ui/icons/Block';
import { IconButton } from '@material-ui/core';

import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { Box } from '@material-ui/core';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {List, ListItem, ListItemText, ListItemSecondaryAction} from "@material-ui/core";
import { ListItemIcon } from '@material-ui/core';
import { useGetNotifications } from 'hooks/notification/useGetNotifications';
import { usePostNotificationRead } from 'hooks/notification/usePostNotificationRead';
import { usePostNotificationAllRead } from 'hooks/notification/usePostNotificationAllRead';

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

export default function MenuNotificationsButton(props) {
  const State = React.useContext(StateContext);
  const history = useHistory();
  const [token] = React.useState(State?.user?.value?.token);
  const setGlobalNotifications = State.notifications.set;
  
  const [openProfile, setOpenProfile] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [incomingNotifications, setIncomingNotifications] = React.useState();
  const [count, setCount] = React.useState(0);
  const [markReadId, setMarkReadId ] = React.useState();
  const [version, setVersion] = React.useState(1);
  const [shouldMarkAllRead, setShouldMarkAllRead] = React.useState();

  const { notificationsResult } = useGetNotifications(
    token, 
    1, 
    null, 
    999, 
    null, 
    true
  );

  const { postNotificationReadSuccess, postNotificationMarkedId } = usePostNotificationRead(token, markReadId);
  const { postNotificationAllReadSuccess } = usePostNotificationAllRead(token, shouldMarkAllRead);

  React.useEffect(() => {
    if (postNotificationReadSuccess && postNotificationMarkedId){
      setMarkReadId(undefined);
      setVersion(version+1);
    }
  }, [postNotificationReadSuccess, postNotificationMarkedId])

  React.useEffect(() => {
    if(postNotificationAllReadSuccess){
      setVersion(version+1);
      setShouldMarkAllRead(false);
    }
  }, [postNotificationAllReadSuccess])

  React.useEffect(() => {
    if (notificationsResult) {
      setGlobalNotifications(notificationsResult);
      const initial = notificationsResult.map((n) => {
        return  {id: n.id, title: n.params.title, message: n.params.message, entity_type: n.params.entity_type, entity_id: n.params.entity_id};
      });
      setNotifications(initial)
      setCount(initial.length);
    }
  }, [notificationsResult]);

  React.useEffect(() => {
    if (incomingNotifications) {
      setVersion(version+1);
    }
  }, [incomingNotifications]);
  
  const useStyles = makeStyles(styles);
  const classes = useStyles();
    
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };


  const handleClickNotifications = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  }

  const handleClickMarkRead = (id) => {
    console.log("handleClickMarkRead"+id)
    setMarkReadId(id);
  }

  const handleClickMarkAllRead = () => {
    setShouldMarkAllRead(true);
  }

  const channelParams = { channel: 'Noticed::NotificationChannel' };
  
  const channelHandlers = {
    connected() {
      console.log("connected");
    },
  
    disconnected() {
      console.log("disconnected");
    },
  
    received(data) {
      const message = {task: data.task, message: data.message};
      setIncomingNotifications([message]);
    }
  }

  const mapPath = (notification) => {
    console.log(notification);
    if(notification?.entity_type == "case_submission") return `${PATHS.caseSubmissionView}/${notification.entity_id}`;
    if(notification?.entity_type == "task") return `${PATHS.tasksView}/${notification.entity_id}`;
    return "";
  }
  
  useActionCable(channelParams, channelHandlers); // doesn't subscribe until channelParams is present
 
 
  return (
   
    <div className={props.className}>
      <Button
        color="transparent"
        aria-label="Notifications"
        justIcon
        aria-owns={openProfile ? "profile-menu-list" : null}
        aria-haspopup="true"
        onClick={handleClickNotifications}
        className={classes.buttonLink}
        
      >
        <Badge badgeContent={count} color="error">
          <MailIcon 
            className={
              classes.headerLinksSvg + " " + (classes.links)
            }
          />
        </Badge>
      </Button>
      {notifications && (
      <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: "0 0 0" }}>
              <Paper className={classes.dropdown} elevation={2} >
              <ClickAwayListener onClickAway={handleCloseProfile}>

                <List>
                  {notifications.length > 0 && (
                    <ListItem 
                      key={"notifications-list-header"}    
                      divider                  
                      >
                      
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Box display="flex"
                              justifyContent="center"
                              p={1}
                              m={1}
                              >
                              <Button
                                variant="contained"
                                color="primary"
                                size="sm"
                                className={classes.button}
                                startIcon={<ClearIcon />}
                                onClick={handleClickMarkAllRead}
                              >
                                Dismiss All
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="sm"
                                className={classes.button}
                                startIcon={<PageviewIcon />}
                                onClick={() => {history.push(`${PATHS.notificationsView}`)}}
                              >
                                View All
                              </Button>
                            </Box>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  )} 
                  {notifications.map((notification, idx) => (
                    <ListItem 
                      key={idx}
                      divider
                      onClick={() => {const path = mapPath(notification); history.push(path);}}
                      >
                      <ListItemIcon>
                        <Assignment />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${notification.title}`}
                        secondary={notification.message}
                      />
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end" 
                          aria-label="comments"
                          onClick = {() => handleClickMarkRead(notification.id)}
                          >
                          <ClearIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>  
                  ))}
                  {notifications.length == 0 && (
                    <ListItem 
                      key={1}                      
                      >
                      <ListItemIcon>
                        <BlockIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="No Notifications"
                        
                      />
                    </ListItem>
                  )}                
                </List>
                </ClickAwayListener>

              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
}
