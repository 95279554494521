import React from "react";
import AdminLayout from "layouts/Admin.js";
import { useHistory } from "react-router-dom";
import { StateContext } from "state.js";
import { useGetContacts } from "hooks";
import { useDeleteContact } from "hooks";
import { useTranslation } from "react-i18next";
import { FormContact, DynamicDataTable } from "components";

import { makeStyles } from "@material-ui/core/styles";
// Bread crumbs
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

// Grid
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";

// material-ui icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";

// core components
import Button from "components-material-ui/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";
// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function Page() {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [sort, setSort] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [token] = React.useState(State?.user?.value?.token);
  const [contact, setContact] = React.useState();
  const [deleteContact, setDeleteContact] = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [modal, setEditContactModal] = React.useState(false);
  // Global editing
  const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [version, setVersion] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  // when called, add 1 to "version"
  const refresh = React.useCallback(() => {
    setVersion((s) => s + 1);
  }, []);

  const history = useHistory();

  const { contacts, contactsError, contactsPageCount, total, isLoading } = useGetContacts(
    token, 
    version, 
    page, 
    search, 
    perPage, 
    sort, 
    true
  );
  const { contactDeleteSuccess, cotactDeleteError, deleteIsLoading } = useDeleteContact(
    token,
    deleteContact,
    isDeleteConfirmed
  );

  const setContactInfo = (response) => {
    setEditContactModal(false);
    refresh();
    history.push("/contacts/view/" + response.id);
  };

  const classes = useStyles();
  const buttons = (id) => {
    return [
      { color: "info", icon: Person, link: "/contacts/view/" + id },
      { color: "success", icon: Edit, link: "/contacts/edit/" + id },
      { color: "error", icon: Delete, link: "/contacts/delete/" + id },
    ].map((prop, key) => {
      return (
        <Button color={prop.color} className={classes.actionButton} key={key} component="a" href={prop.link}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  // Handle errors
  React.useEffect(() => setError(true), [contactsError]);

  React.useEffect(() => {
    if (contacts) {
      const data = contacts.map((k, v) => {
        return {
          name: k.name,
          role: k.role,
          organization: k.organization,
          actions: <>
            <Button
              color="info"
              className={classes.actionButton}
              key={1}
              component="a"
              href={"/contacts/view/" + k.id}
            >
              <Edit className={classes.icon} />
            </Button>
            <Button
              color="success"
              className={classes.actionButton}
              key={2}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleDeleteClick(k);
              }}
            >
              <Delete className={classes.icon} />
            </Button>
          </>,
            };
      });
      setTableData(data);   
    } else {
      setTableData([]);
    }
  }, [contacts])

  React.useEffect(() => {
    if (deleteContact && isDeleteConfirmed && contactDeleteSuccess) handleDeletedContact();
  }, [deleteContact, isDeleteConfirmed, contactDeleteSuccess]);

  const handleDeletedContact = () => {
    console.log("Handle DeleteContact");
    setDeleteConfirmModal(false);
    setIsDeleteConfirmed(false);
    refresh();
  };

  const handleDeleteClick = (contact) => {
    console.log(contact);
    setDeleteContact(contact);
    setDeleteConfirmModal(true);
  };

  const handlePageChange = (page, totalRows) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    const newSort = {
       sortDirection: sortDirection,
       field:column.selector
    };
    setSort(newSort);
  };

  const handleFilter = (filter) => {
    console.log(filter);
    const filterString = `by_name=${filter}`
    setSearch(filterString);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={6}>
          <h1>Contacts</h1>
        </GridItem>
        <GridItem xs={6} align="right">
          <Button
            variant="contained"
            color="primary"
            size="lg"
            className={classes.button}
            startIcon={<Add />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setEditContactModal(true);
            }}
          >
            {t("contact.new")}
          </Button>
        </GridItem>
      </GridContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Dashboard
        </Link>
        <Typography color="textPrimary">Contacts</Typography>
      </Breadcrumbs>
            
      <DynamicDataTable
            title={"Contacts"}
            data={tableData}
            columns = {[
              {
                name: 'Name',
                selector: 'name',
                sortable: true,
                filterable: true,
              },
              {
                name: 'Role',
                selector: 'role',
                sortable: true,
              },
              {
                name: 'Organization',
                selector: 'organization',
                sortable: true                
              },
              {
                name: "",
                selector: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            rows={total}
            defaultSortField="name"
            perRowsChangeCallback={handlePerRowsChange}
            pageChangeCallback={handlePageChange}
            sortCallback={handleSort}
            filterCallback={handleFilter}
            initialPerPage={perPage}
            isLoading= {isLoading || deleteIsLoading}
          />  
  

      {/** Contact Edit Modal */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        transition={Transition}
        keepMounted
        onClose={() => setEditContactModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEditContactModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Edit Contact</h4>
        </DialogTitle>
        <DialogContent id="modal-slide-description" className={classes.modalBody}>
          {modal && (
            <FormContact
              id={contact?.id}
              name={contact?.name}
              role={contact?.role}
              organization={contact?.organization}
              setContact={setContactInfo}
            />
          )}
        </DialogContent>
      </Dialog>
      {/** Delete Confirm Dialog */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={deleteConfirmModal}
        transition={Transition}
        keepMounted
        onClose={() => setDeleteConfirmModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setDeleteConfirmModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Confirm Delete</h4>
        </DialogTitle>
        <DialogContent id="modal-slide-description" className={classes.modalBody}>
          <h5>Are you sure you want to do this?</h5>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
          <Button onClick={() => setDeleteConfirmModal(false)}>Never Mind</Button>
          <Button onClick={() => setIsDeleteConfirmed(true)} color="success">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function PageContactsList() {
  return <AdminLayout component={<Page />} />;
}
