import styled from "styled-components";
import { color } from "./styled";

export const StyledDashboardPage = styled.div`
  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  table {
    margin-bottom: 2rem;
    thead {
      background: ${color.gray};
    }
  }
`;
