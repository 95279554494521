import React from "react";
import styled from "styled-components";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledLoadingScreen = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export default function LoadingScreen() {
  return (
    <StyledLoadingScreen id="page-loading-screen">
      <CircularProgress />
    </StyledLoadingScreen>
  );
}
