import React from "react";
import PATHS from "config/paths";

export function useGetSurvivor(token, id, refresh) {
  const [survivor, setSurvivor] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (data) => {
    if (data) {
      setSurvivor(data);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if ((token && id && !survivor) || (token && id && refresh)) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiSurvivors}/${id}`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => console.warn("get survivor profile error", error));
    }
  }, [token, id, survivor, refresh]);

  return { survivorGet: survivor, survivorError: error };
}
