import React from "react";
import moment from "moment";
import PATHS from "config/paths";
import AdminLayout from "layouts/Admin.js";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "utilities";

import { useHistory } from "react-router-dom";
import { StateContext } from "state.js";
import { useGetAnniversaries, useGetNewVictims, useGetVictimsRemembrances, useGetEvents, useGetCaseSubmissions } from "hooks";
import { readableEnum, formatDate } from "utilities";
import { StyledDashboardPage } from "styles/StyledDashboardPage";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// Icons
import Notifications from "@material-ui/icons/Notifications";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import Work from "@material-ui/icons/Work";

function DashboardPage() {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const { anniversaries } = useGetAnniversaries(token);
  const { remembrances } = useGetVictimsRemembrances(token);
  const { newVictims } = useGetNewVictims(token);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { events } = useGetEvents(token, 1, 1, undefined, 10, undefined, true);
  const [caseSubmissionSort] = React.useState({field: "created_at", sortDirection: "desc"})
  const { caseSubmissions } = useGetCaseSubmissions(token, 1, 1, undefined, 10, caseSubmissionSort, true);

  const greeting = React.useMemo(() => {
    const myDate = new Date();
    const hrs = myDate.getHours();

    if (hrs < 12) {
      return <>{t("greeting.morning")}</>;
    } else if (hrs >= 12 && hrs <= 17) {
      return <>{t("greeting.afternoon")}</>;
    } else if (hrs >= 17 && hrs <= 24) {
      return <>{t("greeting.evening")}</>;
    }
  }, []);

  return (
    <StyledDashboardPage>
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          {greeting} {State.user.value.first_name && State.user.value.first_name}
        </Typography>
      </Box>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <NotificationsNone />
              </CardIcon>
              <h3>{t("calendar.anniversaries.title")}</h3>
            </CardHeader>
            <CardBody>
              {anniversaries && anniversaries.length === 0 && <p>{t("calendar.anniversaries.none")}</p>}
              {anniversaries && anniversaries.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" className="hover">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("case.number")}</TableCell>
                        <TableCell>{t("firstname")}</TableCell>
                        <TableCell>{t("lastname")}</TableCell>
                        <TableCell>{t("incident.date")}</TableCell>
                        <TableCell>{t("anniversary_date")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {anniversaries.slice(0, 5).map((victim, index) => {
                        return (
                          <TableRow key={index} onClick={() => history.push(`${PATHS.victimProfile}/${victim.id}`)}>
                            <TableCell>{`${victim.case.case_number || ""}`}</TableCell>
                            <TableCell>{victim.first_name}</TableCell>
                            <TableCell>{victim.last_name}</TableCell>
                            <TableCell>{victim.date_of_death && formatDate(victim.date_of_death)}</TableCell>
                            <TableCell>{formatDate(victim.anniversary_date)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Button href={PATHS.calendar} size="sm">
                More
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Notifications />
              </CardIcon>
              <h3>{t("case.submissions")}</h3>
            </CardHeader>
            <CardBody>
              {caseSubmissions && caseSubmissions.length === 0 && <p>No New Submissions</p>}
              {caseSubmissions && caseSubmissions.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" className="hover">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{width: '25%'}}>Date Submitted</TableCell>
                        <TableCell style={{width: '30%'}}>Victim</TableCell>
                        <TableCell>Survivor</TableCell>
                        <TableCell>Incident Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {caseSubmissions.map((c, index) => {
                        return (
                          <TableRow
                            key={index}  
                            onClick={() => history.push(`${PATHS.caseSubmissionView}/${c.id}`)}                          
                          >
                            <TableCell>{formatDateTime(c.created_at)}</TableCell>
                            <TableCell>{`${c.victim_first_name} ${c.victim_last_name}`}</TableCell>
                            <TableCell>{`${c.survivor_first_name} ${c.survivor_last_name}`}</TableCell>
                            <TableCell>{formatDate(c.date_of_death)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Button href={PATHS.caseSubmissions} size="sm">
                More
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Work />
              </CardIcon>
              <h3>New Case Approvals</h3>
            </CardHeader>
            <CardBody>
              {newVictims && newVictims?.length > 0 && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" className="hover">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("case.number")}</TableCell>
                        <TableCell>{t("firstname")}</TableCell>
                        <TableCell>{t("lastname")}</TableCell>
                        <TableCell>{t("age")}</TableCell>
                        <TableCell>{t("gender")}</TableCell>
                        <TableCell>{t("race")}</TableCell>
                        <TableCell>{t("cause")}</TableCell>
                        <TableCell>{t("city_of_death")}</TableCell>
                        <TableCell>{t("state_of_death")}</TableCell>
                        <TableCell>{t("incident.date")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {newVictims.slice(0, 20).map((victim, index) => {
                        const date = victim.date_of_death ? new Date(victim.date_of_death) : null;
                        const displaydate = date
                          ? `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
                          : null;

                        return (
                          <TableRow key={index} onClick={() => history.push(`${PATHS.victimProfile}/${victim.id}`)}>
                            <TableCell>{`${victim.case.case_number || ""}`}</TableCell>
                            <TableCell>{victim.first_name}</TableCell>
                            <TableCell>{victim.last_name}</TableCell>
                            <TableCell>{victim.age}</TableCell>
                            <TableCell>{readableEnum(victim.gender)}</TableCell>
                            <TableCell>{readableEnum(victim.race)}</TableCell>
                            <TableCell>{readableEnum(victim.cause_of_death)}</TableCell>
                            <TableCell>{victim.city_of_death}</TableCell>
                            <TableCell>{victim.state_of_death}</TableCell>
                            <TableCell>{displaydate}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Button href={PATHS.caseSubmissions} size="sm">
                More
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </StyledDashboardPage>
  );
}

export default function PageDashboard() {
  return <AdminLayout component={<DashboardPage />} />;
}
