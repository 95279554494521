import React from "react";
import AdminLayout from "layouts/Admin.js";
import { StateContext } from "state.js";
import { useParams } from "react-router-dom";

import { formatDisplayDate, formatDateTime, readableEnum } from "utilities";
import PATHS from "config/paths";

import { useGetCaseSubmission } from "hooks"
import { MinimumCriteriaForm, FollowUpForm, Images, UserSubmittedDocuments,StaffDocuments, CaseApproval, CaseSubmissionNotes } from "components";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { makeStyles } from '@material-ui/core/styles';

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import { IconButton, setRef } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/Edit";
import Slide from '@material-ui/core/Slide';
import Snackbar from "components-material-ui/Snackbar/Snackbar";
import { Alert } from "@material-ui/lab";
import { Print } from "@material-ui/icons";


const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function CaseSubmissionViewPage () {
  let { id } = useParams();
  const classes = useStyles();
  const State = React.useContext(StateContext);
  const refWrapper = React.useRef();
  const [token] = React.useState(State?.user?.value?.token);
  const [tab, setTab] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);

  const { caseSubmissionGet, caseSubmissionError } = useGetCaseSubmission(token, id, refresh);
  const [submission, setSubmission] = React.useState();
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (caseSubmissionGet) {
      setSubmission(caseSubmissionGet);
      setRefresh(false);
      setDisabled(caseSubmissionGet.case_acceptance_criterium.is_case_approved);
    }
  }, [caseSubmissionGet]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const [preview, setPreview] = React.useState();
  const [open, setOpen] = React.useState(false);

  const onPreview = (file) => {
    console.log("onPreview", file);
    if (!file) {
      file = `${PATHS.apiCaseSubmissions}/${submission.id}/pdf_print`;
      setPreview([ {uri: file, fileName: `case_submission_${submission.id}.pdf`} ]);
    } else {
      setPreview([ {uri: file} ]);
    }

    setOpen(true);
  }


  const handleClose = () => {
    setOpen(false);
    setPreview([]);
  };

  return (

    <div className={classes.root}>
      <GridContainer>
        <GridItem xs={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"

            >
              <Tab label="Initial Criteria" />
              <Tab label="Contact" />
              <Tab label="Images" />
              <Tab label="Documents" />
              <Tab label="Notes" />
              <Tab label="Final Approval" />

            </Tabs>
          </Paper>
        </GridItem>
        <GridItem xs={12} sm={12}>
          {submission && (
            <Card>
              <CardHeader color="primary" icon>
                <h3>Progress</h3>
                <h4>Status: {submission?.case_acceptance_criterium?.status}</h4>
              </CardHeader>
              <CardBody>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={"is_minimum_criteria_met_" + submission?.case_acceptance_criterium?.is_minimum_criteria_met || false}
                      checked={submission?.case_acceptance_criterium?.is_minimum_criteria_met}
                      name="is_minimum_criteria_met"
                      color="primary"
                    />
                  }
                  label="Initial Criteria Met"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={submission?.minimum_criterium?.is_initial_contact_complete || false}
                      name="initialContact"
                      color="primary"
                    />
                  }
                  label="Initial Contact"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={submission?.case_acceptance_criterium?.is_feature_photo_approved || false}
                      name="is_feature_photo_approved"
                      color="primary"
                    />
                  }
                  label="Approved Feature Photo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={submission?.case_acceptance_criterium?.is_imported_images_approved || false}
                      name="is_imported_images_approved"
                      color="primary"
                    />
                  }
                  label="Approved Images"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={submission?.case_acceptance_criterium?.is_imported_documents_approved || false}
                      name="is_imported_documents_approved"
                      color="primary"
                    />
                  }
                  label="Approved Documents"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={submission?.case_acceptance_criterium?.is_case_approved || false}
                      name="caseApproved"
                      color="primary"
                    />
                  }
                  label="Case Approved"
                />
              </CardBody>
            </Card>
          )}
        </GridItem>
        <GridItem xs={12} sm={12}>
          {disabled && (            
              <Alert severity="error">
                Editing locked after Case has been Approved.
              </Alert>           
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <Card>
            <CardHeader color="primary" icon>
              <h3>Original Submission (ID: {submission?.wp_id})</h3>
              <IconButton aria-label="edit" color="primary" onClick={() => onPreview()}>
                <Print />
              </IconButton>

            </CardHeader>
            <CardBody>
              {submission && (
                <>
                  <h4>Victim</h4>
                  <div><span className="label">First Name: </span>{submission?.victim_first_name}</div>
                  <div><span className="label">Last Name: </span>{submission?.victim_last_name}</div>
                  <div><span className="label">Preffered Name: </span>{submission?.victim_preferred_name}</div>
                  <div><span className="label">Age at Death: </span>{submission?.age_at_death}</div>
                  <div><span className="label">Case Type: </span>{readableEnum(submission?.case_type)}</div>
                  <div><span className="label">Cause of Death: </span>{readableEnum(submission?.cause_of_death)}</div>
                  <div><span className="label">DOB: </span>{formatDisplayDate(submission?.date_of_birth)}</div>
                  <div><span className="label">Date of Death: </span>{formatDisplayDate(submission?.date_of_death)}</div>
                  <div><span className="label">Gender: </span>{readableEnum(submission?.gender)}</div>
                  <div><span className="label">Race: </span>{readableEnum(submission?.race)}</div>

                  <h4>Incident</h4>
                  <div><span className="label">Address1:</span> {submission?.incident_address1}</div>
                  <div><span className="label">Address2:</span> {submission?.incident_address2}</div>
                  <div><span className="label">City:</span> {submission?.incident_city}</div>
                  <div><span className="label">State:</span> {submission?.incident_state}</div>
                  <div><span className="label">Postal Code: </span>{submission?.incident_postal_code}</div>
                  <div><span className="label">Country: </span>{submission?.incident_country}</div>
                  <div><span className="label">Description:</span>
                    <p>{submission?.incident_description}</p>
                  </div>
                  <div><span className="label">Offense:</span> {readableEnum(submission?.incident_offense)}</div>
                  <div><span className="label">Investigative Agency: </span>{submission?.investigative_agency}</div>
                  <div><span className="label">LE Connection:</span> {submission?.le_connection}</div>
                  <div><span className="label">Police Report Number:</span> {submission?.police_report_number}</div>

                  <h4>Survivor</h4>
                  <div><span className="label">First Name: </span>{submission?.survivor_first_name}</div>
                  <div><span className="label">Last Name: </span>{submission?.survivor_last_name}</div>
                  <div><span className="label">Relation to Victim: </span>{readableEnum(submission?.relationship_to_victim)}</div>
                  <div><span className="label">Email: </span> {submission?.survivor_email}</div>
                  <div><span className="label">Phone: </span>{submission?.survivor_phone}</div>
                  <div><span className="label">Address1: </span>{submission?.survivor_address1}</div>
                  <div><span className="label">Address2: </span>{submission?.survivor_address2}</div>
                  <div><span className="label">City: </span> {submission?.survivor_city}</div>
                  <div><span className="label">State: </span>{submission?.survivor_state}</div>
                  <div><span className="label">Postal Code: </span> {submission?.survivor_postal_code}</div>
                  <div><span className="label">Country: </span>{submission?.survivor_country}</div>

                  <div><span className="label">Last Updates:  </span>{submission?.updated_at}</div>
                  <div><span className="label">Created: </span>{formatDateTime(submission?.created_at)}</div>

                  <div><span className="label">Documents: </span>
                    <ul>{submission?.documents.map((x, i) => 
                      <li key={i}><a href={x} target="_blank">{x}</a></li>
                    )}</ul>
                    </div>
                  <div><span className="label">Photos: </span> 
                  <ul>{submission?.photos.map((x, i) => 
                      <li key={i}><a href={x} target="_blank">{x}</a></li>
                    )}</ul>
                  </div>

                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>

          {tab == 0 && (
            <Card className={classes.root}>
              <CardHeader>
                <h2>Initial Criteria Checklist</h2>
              </CardHeader>
              <CardBody>
                {submission && (
                  <MinimumCriteriaForm
                    incidentDate={submission?.date_of_death}
                    caseSubmissionId={id}
                    minimumCriteria={submission?.minimum_criterium}
                    refresh={setSubmission}
                    disabled={disabled}
                  >
                  </MinimumCriteriaForm>
                )}
              </CardBody>
            </Card>
          )}
          {tab == 1 && (
            <Card className={classes.root}>
              <CardHeader>
                <h2>Survivor Communications</h2>
              </CardHeader>
              <CardBody>
                <FollowUpForm 
                  followups={submission?.followups} 
                  caseSubmissionId={id} 
                  refresh={setSubmission}
                  disabled={disabled}
                  >
                  </FollowUpForm>
              </CardBody>
            </Card>
          )}
          {tab == 2 && (
            <Card className={classes.root}>
              <CardHeader>
                <h2>Photos/Images</h2>
              </CardHeader>
              <CardBody>
                <Images
                  submittedImages={submission?.approved_images}
                  existingImage={null}
                  caseSubmissionId={id}
                  featureApproved={submission?.case_acceptance_criterium?.is_feature_photo_approved}
                  userPhotosApproved={submission?.case_acceptance_criterium?.is_imported_images_approved}
                  refresh={setSubmission}
                  disabled={disabled}>
                </Images>
              </CardBody>
            </Card>
          )}
          {tab == 3 && (
            <Card className={classes.root}>
              <CardHeader>
                <h2>Documents</h2>
              </CardHeader>
              <CardBody>
              <StaffDocuments
                  staffDocuments={submission?.staff_documents}
                  caseSubmissionId={id}                 
                  refresh={setRefresh}
                  onPreview={onPreview}
                  disabled={disabled}>
                </StaffDocuments>
                <br/>
                <UserSubmittedDocuments
                  submittedDocuments={submission?.approved_documents}
                  caseSubmissionId={id}
                  userDocumentsApproved={submission?.case_acceptance_criterium?.is_imported_documents_approved}
                  refresh={setSubmission}
                  disabled={disabled}>
                </UserSubmittedDocuments>
              </CardBody>
            </Card>
          )}
           {tab == 4 && (
            <Card className={classes.root}>
              <CardHeader>
                <h2>Notes</h2>
              </CardHeader>
              <CardBody>
                <CaseSubmissionNotes                  
                  caseSubmissionId={id}
                  notes={submission?.case_submission_notes}
                  refresh={setSubmission}
                  disabled={disabled}>
                </CaseSubmissionNotes>
              </CardBody>
            </Card>
          )}
          {tab == 5 && (
            <Card className={classes.root}>
              <CardHeader>
                <h2>Final Approval</h2>
              </CardHeader>
              <CardBody>
                <CaseApproval
                  caseSubmissionId={id}
                  caseSubmissionApproval={submission?.case_submission_approval}
                  caseSubmissionCriterium={submission?.case_acceptance_criterium}
                  refresh={setSubmission}
                  disabled={disabled}>
                </CaseApproval>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Preview
            </Typography>

          </Toolbar>
        </AppBar>
        <DocViewer
          prefetchMethod="GET"
          documents={preview}
          pluginRenderers={DocViewerRenderers}
          style={{ minHeight: '100%' }}
        />
      </Dialog>
    </div>

  )
}


export default function PageCaseSubmissionView () {
  return <AdminLayout component={<CaseSubmissionViewPage />} />;
}
