import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

const cache = new Map();

export function useGetVictims(token, page, search, noCache = false, forceSearch = false, sort) {
  const [victims, setVictims] = React.useState();
  const [error, setError] = React.useState();
  const [pageCount, setPageCount] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleResponse = (data) => {
    if (data?.length > 0) {
      setVictims(data);
      cache.set(`${page}${search}`, data);
      setIsLoading(false);
      setError(undefined);
    } else {
      setVictims(undefined);
      setIsLoading(false);
      setError("no-data");
    }
  };

  React.useEffect(() => {
    const cachedData = page ? cache.get(`${page}${search}`) : null;
    const cachedPageCount = cache.get("page-count");

    if (cachedData && cachedPageCount && !noCache) {
      setVictims(cachedData);
      setPageCount(cachedPageCount);
      setIsLoading(false);
      setError(undefined);
    } else if (token && page) {
      if (forceSearch && !search) return;

      setIsLoading(true);
      setError(undefined);

      // Add page to pages
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      /**
       * Filters
       */
      let filters = "";
      if (page !== 1) filters = `page=${page}`;
      if (filters.length > 0 && search?.length > 0) {
        filters = `${filters}&${search}`;
      } else if (search?.length > 0) {
        filters = search;
      }

      if (sort?.length > 0 ) {
        if (filters?.length > 0 || search?.length > 0) {
          filters = `${filters}&${sort}`;
        } else {
          filters = `${sort}`;
        }
      }

      /**
       * Make request
       */
      fetch(`${PATHS.apiVictims}?${filters}`, options)
        .then((response) => {
          const total = Number.parseInt(response.headers.get("total"));
          const perPage = Number.parseInt(response.headers.get("per-page"));
          const count = Math.ceil(total / perPage);
          setPageCount(count);
          cache.set("page-count", count);
          return response.json();
        })
        .then((result) => handleResponse(result))
        .catch((error) => setError(error));
    }
  }, [token, page, search, sort]);

  return { victims, victimsError: error, victimsPageCount: pageCount, isLoading };
}
