import React from "react";
import AdminLayout from "layouts/Admin.js";
import { StateContext } from "state.js";
import { useGetTask, useDeleteTaskNote } from "hooks";
import { FormTask, FormTaskNote } from "components";
import { useParams } from "react-router-dom";
import { formatDateTime, formatDate } from "utilities";



// Layout
import Grid from '@material-ui/core/Grid';
// Grid
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
// Box
import Box from "@material-ui/core/Box";

// Card
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";

// Lists
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

// Icons
import NoteIcon from '@material-ui/icons/Note';

// Bread crumbs
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
// Progress
import CircularProgress from "@material-ui/core/CircularProgress";

// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Button from "components-material-ui/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function TaskViewPage() {
  let { id } = useParams();

  const State = React.useContext(StateContext);
  const refWrapper = React.useRef();
  const [token] = React.useState(State?.user?.value?.token);
  const [task, setTask] = React.useState();
  const [view, setView] = React.useState("loading");
  const [modal, setEditTaskModal] = React.useState(false);
  
  // Global editing
  const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);


  // Note Editing
  const [noteModal, setEditNoteModal] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(false);
  const [deleteNote, setDeleteNote] = React.useState(false);
  const { noteDeleteSuccess, noteDeleteError, noteIsLoading } = useDeleteTaskNote(token, id, deleteNote, isDeleteConfirmed); 
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);

  const { taskGet, taskError } = useGetTask(token, id);

  const nowrap = {
    "white-space": "nowrap"
  };
  React.useEffect(() => {
    if (taskGet) {
      setView("profile");
      setTask(taskGet);
    }
  }, [taskGet]);

  React.useEffect(() => {
    if (task) setView("profile");
  }, [task]);

  var styles = {
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  // Task Editing
  const handleEditTaskClick = () => {
    setEditTaskModal(true);
  }

  const setTaskInfo = (response) => {
    const updated = {...task, ...response};
    setTask(updated);
    setEditTaskModal(false);
  }

  // Note Editing Methods
  const handleEditNoteClick = (note) => {
    console.log(note);
    setSelectedNote(note);
    setEditNoteModal(true);
  }

  const handleAddNoteClick = () => {
    setSelectedNote(null);
    setEditNoteModal(true);
  }

  const handleDeleteNoteClick = (note) => {
    console.log(note);
    setDeleteNote(note);
    setDeleteConfirmModal(true);
  }

  const handleDeletedNote = () => {
    console.log("Handle DeleteNote");

    const objIndex = task.task_notes.findIndex((obj => obj.id == deleteNote.id));
    if (objIndex !== -1){
      task.task_notes.splice(objIndex, 1);
    }
    setTask(task);
    setEditNoteModal(false);
    setDeleteConfirmModal(false);
    setDeleteNote(false);
    setIsDeleteConfirmed(false);
  } 

  const setNotes = (response) => {
    console.log(task.task_notes);
    console.log(response);
    const objIndex = task.task_notes.findIndex((obj => obj.id == response.id));
    if (objIndex === -1){
      task.task_notes.push(response);
    }else{
      task.task_notes[objIndex] = response;
    }
    setTask(task);
    setEditNoteModal(false);
    setSelectedNote(false);
  }

  React.useEffect(() => {
    if (deleteNote && isDeleteConfirmed && noteDeleteSuccess) handleDeletedNote();
  }, [deleteNote, isDeleteConfirmed, noteDeleteSuccess]);

  // Handle errors
  //React.useEffect(() => setError(true), [tasksError]);

  return (
    <div ref={refWrapper}>
      <h1>Task Detail</h1>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/" >
          Dashboard
        </Link>
        <Link color="inherit" href="/tasks/browse">
          Tasks
        </Link>
        <Typography color="textPrimary">Task Profile</Typography>
      </Breadcrumbs>
      {view === "loading" && (
        <Box textAlign="center" pb={5}>
          <p>Please wait</p>
          <CircularProgress />
        </Box>
      )}

      {taskError && (
        <Box textAlign="center" py={5}>
          <p>Could not find this task</p>
        </Box>
      )}

      {task && view === "profile" && (
        <>
        <Box py={5}>
           <GridContainer>
            <GridItem xs={12} >
              <Card>
                <CardHeader color="primary">
                <GridContainer spacing={5}>
                  <Grid item xs={11} >
                    <Typography gutterBottom variant="subtitle1">
                      {task.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} >
                    <IconButton edge="end" aria-label="edit" onClick={handleEditTaskClick}>
                      <Edit />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} px={1}>
                      <Typography variant="body2" gutterBottom>
                        {task.description}
                      </Typography>
                  </Grid>
                  <Grid item xs >
                      <Typography gutterBottom variant="body2">
                        Assigned To: {task.assigned_to.first_name + ' ' + task.assigned_to.last_name}
                      </Typography>
                  </Grid >
                  <Grid item xs >
                    <Typography variant="body2">
                      Due: {formatDate(task.due_date)}
                    </Typography>
                  </Grid>
                </GridContainer> 
                    
                </CardHeader>
                <CardBody>
                <GridContainer>                  
                  <GridItem xs={12} >
                    <h4>Notes <IconButton edge="end" aria-label="add" onClick={handleAddNoteClick}>
                            <Add />
                          </IconButton></h4>
                    <List className={classes.root}>
                      {task.task_notes && task.task_notes.map(note => (
                        <ListItem key={note.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <NoteIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={formatDateTime(note.updated_at) + ' - ' + note.created_by.first_name + ' ' + note.created_by.last_name} secondary={note.note} secondaryTypographyProps={{style: nowrap}}/>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEditNoteClick(note);
                            }
                          }>
                            <Edit />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteNoteClick(note);
                            }
                          }>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      ))}  
                    </List> 
                  </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          
        </Box>

        {/** Task Edit Modal */}
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modal}
          transition={Transition}
          keepMounted
          onClose={() => setEditTaskModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditTaskModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Edit Task</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <FormTask task={task} setTask={setTaskInfo} />
          </DialogContent>
        </Dialog>        

        {/** Note Edit Modal */}
        <Dialog 
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={noteModal}
          transition={Transition}
          keepMounted
          onClose={() => setEditNoteModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditNoteModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{selectedNote ? "Edit" : "New" } Note</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <FormTaskNote task_id={task.id} note={selectedNote}   setTask={setNotes} />
          </DialogContent>
        </Dialog>

        {/** Delete Confirm Dialog */}
        <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={deleteConfirmModal}
        transition={Transition}
        keepMounted
        onClose={() => setDeleteConfirmModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={ () => setDeleteConfirmModal(false) }
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Confirm Delete</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <h5>Are you sure you want to do this?</h5>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setDeleteConfirmModal(false)}>Never Mind</Button>
          <Button onClick={() => setIsDeleteConfirmed(true) } color="success">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </>
      )}

    </div>
  );
}

export default function PageTasksView() {
    return <AdminLayout component={<TaskViewPage />} />;
  }
