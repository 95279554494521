import React from "react";
import AdminLayout from "layouts/Admin.js";
import styled from "styled-components";
import { useFormik } from "formik";
import moment from "moment";
import lodash from "lodash";

import { StateContext } from "state.js";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useGetVictim, useGetVictimPhoto, usePostVictims, usePostVictimPhoto,
  useDeleteVictimAssociatedSurvivor, usePostVictimPrimaryContact, useDeleteVictim
} from "hooks";
import { useDeleteVictimAssociatedVictim } from "hooks";
import { FormTask, Social, DeleteConfirmDialog } from "components";
import { Danger, Documents, FormVictim, AddConnections, InputText } from "components";
import { formatDisplayDate, readableEnum } from "utilities";
import { color } from "styles/styled";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import CheckIcon from '@material-ui/icons/Check';

// Material UI
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

// Icons
import Assignment from "@material-ui/icons/Assignment";
import SecurityIcon from "@material-ui/icons/Security";

// Photos
import defaultPhoto from "assets/img/default_photo.jpg";

// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Styles
import styles from "assets/jss/pages/PageStyles.js";
import PATHS from "config/paths";
import { RvHookupSharp } from "@material-ui/icons";

const UploadPhotoButton = styled.button``;
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

/* eslint-disable react-hooks/exhaustive-deps */

export function Page () {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  let { id } = useParams();
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [user_id] = React.useState(State?.user?.value?.id);

  const [victim, setVictim] = React.useState();
  const classes = useStyles();
  const [view, setView] = React.useState("profile");
  const [cashPhoto, setCachePhoto] = React.useState();
  const [photoAction, setPhotoAction] = React.useState();
  const [refresh, setRefresh] = React.useState(false);
  const { victimGet, victimError } = useGetVictim(token, id, refresh);
  const { photo } = useGetVictimPhoto(token, id);
  const [showDeleteVictimAssociationModal, setShowDeleteVictimAssociationModal] = React.useState(false);
  const [isDeleteVictimAssociationConfirmed, setIsDeleteVictimAssociationConfirmed] = React.useState(false);

  const [modal, setEditTaskModal] = React.useState(false);
  const [task, setTask] = React.useState();

  const [selectedRemoveSurvivorConnection, setSelectedRemoveSurvivorConnection] = React.useState(undefined);
  const [selectedRemoveVictimConnection, setSelectedRemoveVictimConnection] = React.useState(undefined);
  const [selectedSurvivorConnection, setSelectedSurvivorConnection] = React.useState(undefined);
  const [selectedVictimConnection, setSelectedVictimConnection] = React.useState(undefined);
  const { isDeleteComplete, eventsError } = useDeleteVictimAssociatedSurvivor(token, id, selectedRemoveSurvivorConnection);
  const { isDeleteVictimAssociationComplete, victimDeleteAssociatedVictimError } = useDeleteVictimAssociatedVictim(token, id, selectedRemoveVictimConnection);
  const [showArchiveVictimModal, setShowArchiveConfirmModal] = React.useState(false);
  const [archive, setArchive] = React.useState();
  const { isDeleteVictimComplete, victimDeleteError } = useDeleteVictim(token, id, archive);

  const [newPrimaryContact, setNewPrimaryContact] = React.useState();
  const { casePrimaryContactResponse, casePrimaryContactError } = usePostVictimPrimaryContact(token, victim?.case?.id, newPrimaryContact)

  const setTaskInfo = () => { };
  const myRef = React.useRef(null)
  const executeScroll = () => myRef.current?.scrollIntoView()

  React.useEffect(() => {
    if (view === "profile") {
      executeScroll();
    }
  }, [view])

  React.useEffect(() => {
    if (isDeleteVictimComplete) {
      history.push("/victims/browse");
      window.location.reload(true);
    }
  }, [isDeleteVictimComplete])

  React.useEffect(() => {
    if (casePrimaryContactResponse) {
      setRefresh(true);
    }
  }, [casePrimaryContactResponse])

  React.useEffect(() => {
    if (selectedSurvivorConnection) {
      setSelectedRemoveSurvivorConnection(selectedSurvivorConnection);
      setSelectedSurvivorConnection(undefined);
    }

  }, [selectedSurvivorConnection])

  React.useEffect(() => {
    if (isDeleteVictimAssociationComplete) {
      setSelectedRemoveVictimConnection(undefined);
      setRefresh(true);
    }

  }, [isDeleteVictimAssociationComplete])

  React.useEffect(() => {
    if (isDeleteComplete) {
      setSelectedRemoveSurvivorConnection(undefined);
      setRefresh(true);
    }

  }, [isDeleteComplete])

  React.useEffect(() => {
    if (selectedVictimConnection && isDeleteVictimAssociationConfirmed) {
      setSelectedRemoveVictimConnection(selectedVictimConnection);
      setSelectedVictimConnection(undefined);
      setShowDeleteVictimAssociationModal(false);
    }
    setIsDeleteVictimAssociationConfirmed(false);
  }, [selectedVictimConnection, isDeleteVictimAssociationConfirmed])

  const handleAddSurvivor = () => {
    localStorage.setItem("pccSetPreSelectVictim", id);
    history.push(PATHS.survivorssNew);
  };

  const handleRemoveAssociatedVictim = (id) => {
    setSelectedVictimConnection(id)
    setShowDeleteVictimAssociationModal(true);
  }

  const handleConfirmRemoveAssociatedVictim = (val) => {
    setIsDeleteVictimAssociationConfirmed(val);
    setShowDeleteVictimAssociationModal(false);
  }

  const handleConfirmRemoveAssociatedSurvivor = (val) => {
    setIsDeleteSurvivorAssociationConfirmed(val);
    setShowDeleteSurvivorAssociationModal(false);
  }

  const handleNewPhoto = (data) => {
    if (data?.url) setCachePhoto(data);
    setView("profile");
  };
  const handleArchiveVictim = (confirmed) => {
    if (confirmed) setArchive(true);
  }

  const handleSetNewPrimaryContact = (id) => {
    setNewPrimaryContact({ primary_contact_id: id });
  }

  React.useEffect(() => {
    if (victimGet) {
      setRefresh(false);
      //setView("profile");
      setVictim(victimGet);
    }
  }, [victimGet]);

  React.useEffect(() => {
    var action = photo || cashPhoto ? t("victim.photo.edit") : t("victim.photo.add");
    setPhotoAction(action);
  }, [photo, cashPhoto]);

  React.useEffect(() => {
    if (victim && view == "edit") setView("profile");
  }, [victim]);

  const exclude_victims = React.useMemo(() => {
    if (victim && victim.related_victims != undefined) {
      if(victim.related_victims && victim.related_victims.length == 0) return [victim.id]
      const ids = victim.related_victims.map((k, v) => { return k.associated_victim.id })
      return [victim.id, ...ids]
    }
  }, [victim])

  const exclude_survivors = React.useMemo(() => {
    if (victim && victim.related_survivors != undefined) {
      if(victim.related_survivors && victim.related_survivors.length == 0) return [victim.id]
      const ids = victim.related_survivors.map((k, v) => { return k.associated_survivor.id })
      return ids
    }
  }, [victim])

  const specialCategories = React.useMemo(() => {
    if (victim?.special_categories.length > 0) return [...victim.special_categories];
    return [];
  }, [victim?.special_categories]);

  const survivors = React.useMemo(() => {
    let primaries = [];
    let associated = [];
    if (victim) {
      if(victim?.case?.survivors) {
      primaries = victim?.case?.survivors.map((surv, index) => {
        return {
          relation_id: null,
          id: surv.id,
          name: `${surv.first_name} ${surv.last_name}`,
          relation_to_victim: readableEnum(surv.relation_to_victim),
          victim_relation_to_survivor: readableEnum(surv.victim_relation_to_survivor),
          special_categories: surv.special_categories,
          isPrimary: victim.case.primary_contact_id == surv.id,
        }
      });
    }
    if(victim?.related_survivors) {
      associated = victim?.related_survivors.map((surv, index) => {
        return {
          relation_id: surv.id,
          id: surv.associated_survivor.id,
          name: `${surv.associated_survivor.first_name} ${surv.associated_survivor.last_name}`,
          relation_to_victim: readableEnum(surv.relationship),
          victim_relation_to_survivor: readableEnum(surv.reverse_relation),
          special_categories: surv.associated_survivor.special_categories,
          isPrimary: victim.case.primary_contact_id == surv.associated_survivor.id,
        }
      });
    }
      let data = [...primaries, ...associated] //victim.case.primary_contact_id == surv.id
      var result = lodash.orderBy(data, ['isPrimary', 'name'], ['desc', 'asc']);
      return result
    }
  }, [victim])

  const displayPhoto = React.useMemo(() => {
    if (cashPhoto) return cashPhoto.url;
    else if (photo) return photo.url;
    else return defaultPhoto;
  }, [photo, cashPhoto]);

  const displayPhotoTitle = React.useMemo(() => {
    if (cashPhoto) return cashPhoto.title;
    else if (photo) return photo.title;
    else return "";
  }, [photo, cashPhoto]);

  const incidentAddress = React.useMemo(() => {
    return (
      <>
        {victim?.case?.incident?.address1 && (
          <>
            <br />
            {victim?.case?.incident?.address1}
            <br />
          </>
        )}
        {victim?.case?.incident?.address2 && (
          <>
            {victim?.case?.incident?.address2}
            <br />
          </>
        )}
        {victim?.case?.incident?.city && <>{victim?.case?.incident?.city}, </>}
        {victim?.case?.incident?.state && <>{victim?.case?.incident?.state} </>}
        {victim?.case?.incident?.postal_code && <>{victim?.case?.incident?.postal_code} </>}
        {victim?.case?.incident?.country && <><br />{victim?.case?.incident?.country} </>}
        {victim?.case?.incident?.county && <><br />County: {victim?.case?.incident?.county} </>}

      </>
    );
  }, [victim?.case?.incident]);

  return (
    <React.Fragment>
      {view === "loading" && (
        <Box textAlign="center" pb={5}>
          <p>{t("wait")}</p>
          <CircularProgress />
        </Box>
      )}

      {victimError && (
        <Box textAlign="center" py={5}>
          <p>{t("error.victim.notfound")}</p>
        </Box>
      )}

      {victim && (
        <>
          <Box paddingBottom={5} ref={myRef}>
            <Typography align="center" component="h1" variant="h2">
              Victim: {victim.first_name} {victim.last_name} {`(${victim.case?.case_number || ''})`}              
            </Typography>
          </Box>
          <Box textAlign="center" paddingBottom={5}>
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button size="sm" onClick={() => setView("profile")} disabled={view === "profile" ? true : false}>
                Victim Profile
              </Button>
              <Button size="sm" onClick={() => setView("social")} disabled={view === "social" ? true : false}>
                Social
              </Button>
              <Button size="sm" onClick={() => setView("edit")} disabled={view === "edit" ? true : false}>
                Edit Profile
              </Button>
              <Button size="sm" onClick={() => setView("documents")} disabled={view === "documents" ? true : false}>
                Documents
              </Button>
              <Button size="sm" onClick={() => setView("connection")} disabled={view === "connection" ? true : false}>
                Add Connection
              </Button>
              <Button size="sm" onClick={handleAddSurvivor}>
                Add Survivor
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}

      {victim && view === "profile" && (
        <React.Fragment>
          <Box mb={10}>
            <GridContainer>
              <GridItem xs={12} px={10}>
                <Button
                  variant="contained"
                  color="primary"
                  size="sm"
                  className={classes.button}
                  //startIcon={<Add />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTask({
                      title: `Call about Support Meeting for victim ${victim.first_name} ${victim.last_name} (${victim.case.case_number})`,
                      due_date: moment(),
                      created_by: { id: user_id },
                    })
                    setEditTaskModal(true);
                  }
                  }>
                  Call About Support Meeting
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="sm"
                  className={classes.button}
                  //startIcon={<Add />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTask({
                      title: `Call about Picture for victim ${victim.first_name} ${victim.last_name} (${victim.case?.case_number})`,
                      due_date: moment(),
                      created_by: { id: user_id },
                    })
                    setEditTaskModal(true);
                  }
                  }>
                  Call About Picture
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <h4>{t("victim.photo.heading")}</h4>
                <p>
                  <img src={displayPhoto} alt="" style={{ width: "100%", height: "auto" }} />
                </p>
                <h5>{displayPhotoTitle}</h5>
                <p>
                  <Button size="sm" onClick={() => setView("photo")}>
                    {photoAction}
                  </Button>
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4>{t("specialcat")}</h4>
                    {[ ...specialCategories].map((item, index) => {
                      return (
                        <Box
                          key={index}
                          style={{
                            backgroundColor: (() => {
                              if (item === "DO_NOT_CALL" || item === "POSSIBLE_SUSPECT" || item === "DECEASED") {
                                return color.red;
                              }

                              if (
                                item === "DISABLED_DEAF" ||
                                item === "DISABLED_HANDICAPPED" ||
                                item === "SENSITIVE_FAMILY_POLITICS" ||
                                item === "DIFFICULT"
                              ) {
                                return color.blueDark;
                              }

                              return color.green;
                            })(),
                            borderRadius: "8px",
                            color: "white",
                            padding: "0.5rem 0.25rem",
                          }}
                          mb="0.5rem"
                          textAlign="center"
                        >
                          {readableEnum(item)}
                        </Box>
                      );
                    })}
                
                    <h4>{t("victim.details")}:</h4>
                    <ul>
                      <li>
                        {t("fullname")}: {victim.full_name}
                      </li>
                      <li>
                        {t("preferredname")}: {victim.preferred_name}
                      </li>
                      <li>
                        {t("dob")}: {formatDisplayDate(victim.date_of_birth)}
                      </li>
                      <li>
                        {t("deathdate")}: {formatDisplayDate(victim.date_of_death)}
                      </li>
                      <li>
                        {t("causeofdeath")}: {readableEnum(victim.cause_of_death)}
                      </li>
                      <li>
                        {t("gender")}: {readableEnum(victim.gender)}
                      </li>
                      <li>
                        {t("race")}: {readableEnum(victim.race)}
                      </li>
                      <li>
                        {t("age")}: {victim.age}
                      </li>
                      <li>
                        {t("cityofdeath")}: {victim.city_of_death}
                      </li>
                      <li>
                        {t("stateofdeath")}: {victim.state_of_death}
                      </li>
                    </ul>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4>{t("case.details")}:</h4>
                    <ul>
                      <li>
                        {t("case.number")}: {victim.case?.case_number}
                      </li>
                      <li>
                        {t("case.investigative")}: {victim.case?.investigative_case_number}
                      </li>
                      <li>
                        {t("case.status")}: {victim.case?.status}
                      </li>
                      <li>
                        Suspect: {victim.case?.incident?.suspect}
                      </li>
                      <li>
                        {t("case.lea")}: {victim.case?.law_enforcement_agency}
                      </li>
                      <li>
                        {t("case.date.reported")}: {formatDisplayDate(victim.case?.reported_date)}
                      </li>
                      <li>
                        {t("case.date.submitted")}: {formatDisplayDate(victim.case?.submitted_date)}
                      </li>
                      <li>
                        {t("case.howsubmitted")}: {readableEnum(victim.case?.how_submitted)}
                      </li>
                      <li>
                        {t("case.metmedia")}: {victim.case?.met_media}
                      </li>
                      <li>
                        {t("case.date.socialpost")}: {formatDisplayDate(victim.case?.social_media_post_date)}
                      </li>
                      <li>
                        {t("case.date.spotlight")}: {formatDisplayDate(victim.case?.written_spotlight_date)}
                      </li>
                      <li>
                        {t("case.date.fbfeature")}: {formatDisplayDate(victim.case?.fb_live_feature_date)}
                      </li>
                      <li>
                        {t("case.date.tv")}: {formatDisplayDate(victim.case?.on_tv_date)}
                      </li>
                      <li>
                        {t("case.news")}: {victim.case?.news_outlet}
                      </li>
                      <li>
                        {t("incident.address")}: {incidentAddress}
                      </li>
                    </ul>
                    {victim.case?.submitted_by_law_enforcement && (
                      <p>
                        <SecurityIcon color="primary" /> {t("case.bylea")}
                      </p>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>

            <h4>{t("incident.description")}:</h4>
            <div style={{ "whiteSpace": "pre-line" }} >
              <div dangerouslySetInnerHTML={{ __html: victim?.case?.incident?.description }} />
            </div>
          </Box>

          <Box my={10}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t("victim.additional.survivors")}</h4>
              </CardHeader>
              <CardBody>
                {survivors?.length > 0 ? (
                  <TableContainer>
                    <Table className="hover">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("name")}</TableCell>
                          <TableCell>Primary Contact</TableCell>
                          <TableCell>{t("relationship")}</TableCell>
                          <TableCell>Victim Relationship</TableCell>
                          <TableCell>{t("specialcat")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {survivors.map((surv, index) => {
                          return (
                            <TableRow
                              key={index}

                            >
                              <TableCell onClick={() => (window.location = `${PATHS.survivorsProfile}/${surv.id}`)}>{surv.name}</TableCell>
                              <TableCell>
                                {victim.case.primary_contact_id == surv.id ? (
                                  <CheckIcon color="primary" />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell>{readableEnum(surv.relation_to_victim)}</TableCell>
                              <TableCell>{readableEnum(surv.victim_relation_to_survivor)}</TableCell>
                              <TableCell>
                                {surv.special_categories.length > 0 ? (
                                  <ul>
                                    {surv.special_categories.map((item, index2) => {
                                      return <li key={index2}>{readableEnum(item)}</li>;
                                    })}
                                  </ul>
                                ) : (
                                  <>None</>
                                )}
                              </TableCell>
                              <TableCell>
                                <>
                                  {surv.relation_id && (
                                    <Button size="sm" onClick={() => setShowDeleteVictimAssociationModal(surv.relation_id)} disabled={view === "connection" ? true : false}>
                                      Remove Connection
                                    </Button>
                                  )}
                                  {victim.case.primary_contact_id != surv.id && (
                                    <Button size="sm" onClick={() => handleSetNewPrimaryContact(surv.id)} variant="contained" color="primary">
                                      Set As Primary
                                    </Button>
                                  )}
                                </>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>{t("victim.additional.none")}</>
                )}
              </CardBody>
            </Card>
          </Box>

          <Box my={10}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t("victim.additional.victims")}</h4>
              </CardHeader>
              <CardBody>
                {victim.related_victims?.length > 0 ? (
                  <TableContainer>
                    <Table className="hover">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("name")}</TableCell>
                          <TableCell>{t("relationship")}</TableCell>
                          <TableCell>{"Reverse Relation"}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {victim.related_victims.map((vic, index) => {
                          return (
                            <TableRow
                              key={index}
                            >
                              <TableCell onClick={() => (window.location = `${PATHS.victimProfile}/${vic.associated_victim.id}`)}>
                                {vic.associated_victim.first_name} {vic.associated_victim.last_name} {`(${vic.associated_victim.case.case_number || ''})`}
                              </TableCell>
                              <TableCell>{readableEnum(vic.relationship)}</TableCell>
                              <TableCell>{readableEnum(vic.reverse_relation)}</TableCell>
                              <TableCell>
                                {vic.id && (
                                  <Button size="sm" onClick={() => handleRemoveAssociatedVictim(vic.id)} disabled={view === "connection" ? true : false}>
                                    Remove Connection
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>{t("victim.additional.none")}</>
                )}
              </CardBody>
            </Card>
          </Box>
          <Button size="sm" onClick={() => setShowArchiveConfirmModal(true)} style={{ background: color.red }}>
                ARCHIVE
              </Button>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={modal}
            transition={Transition}
            keepMounted
            onClose={() => setEditTaskModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setEditTaskModal(false)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>New Task</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              {modal && (
                <FormTask task={task} setTask={setTaskInfo} />
              )}
            </DialogContent>
          </Dialog>
        </React.Fragment>
      )
      }

      {
        victim && view === "social" && (
          <Social socials={victim?.case?.socials} case_id={victim?.case?.id} setRefresh={setRefresh} />
        )
      }

      {victim && view === "edit" && <Edit victim={victim} setVictim={setVictim} />}

      {
        victim && view === "connection" && (
          <AddConnections
            type="victim"
            choice={false}
            object={victim}
            host={{ type: "victim", id: victim.id, exclude: { survivor: exclude_survivors, victim: exclude_victims } }} />
        )
      }

      {victim && view === "documents" && <Documents case={victim.case} />}

      {
        victim && view === "photo" && (
          <PhotoUpload id={id} callBack={handleNewPhoto} title={displayPhotoTitle} existingImage={displayPhoto} />
        )
      }


      {/** Delete Confirm Dialog */}
      <DeleteConfirmDialog
        key="victimAssociationDeleteModal"
        showModal={showDeleteVictimAssociationModal}
        result={handleConfirmRemoveAssociatedVictim} />

      <DeleteConfirmDialog
        key="archiveVictimConfirm"
        showModal={showArchiveVictimModal}
        result={handleArchiveVictim} />

    </React.Fragment >
  );
}

function Edit (props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestBody, setRequestBody] = React.useState();
  const { response } = usePostVictims(token, requestBody, "PATCH", props.victim.id);

  const formik = useFormik({
    initialValues: {
      // Victim details
      first_name: props.victim.first_name,
      last_name: props.victim.last_name,
      full_name: props.victim.full_name,
      preferred_name: props.victim.preferred_name,
      date_of_birth: props.victim.date_of_birth,
      date_of_death: props.victim.date_of_death,
      cause_of_death: props.victim.cause_of_death,
      gender: props.victim.gender,
      age: props.victim.age,
      race: props.victim.race,
      city_of_death: props.victim.city_of_death,
      state_of_death: props.victim.state_of_death,
      special_categories: props.victim.special_categories || [],

      // Case details
      case_id: props.victim.case.id,
      case_number: props.victim.case.case_number,
      investigative_case_number: props.victim.case.investigative_case_number,
      status: props.victim.case.status,
      law_enforcement_agency: props.victim.case.law_enforcement_agency,
      reported_date: props.victim.case.reported_date,
      submitted_date: props.victim.case.submitted_date,
      how_submitted: props.victim.case.how_submitted,
      submitted_by_law_enforcement: props.victim.case.submitted_by_law_enforcement,
      met_media: props.victim.case.met_media,
      social_media_post_date: props.victim.case.social_media_post_date,
      written_spotlight_date: props.victim.case.written_spotlight_date,
      fb_live_feature_date: props.victim.case.fb_live_feature_date,
      on_tv_date: props.victim.case.on_tv_date,
      news_outlet: props.victim.case.news_outlet,
      user_id: State?.user?.value?.id,

      // incident details
      incident_id: props.victim.case.incident_id,
      suspect: props.victim.case.incident.suspect,
      address1: props.victim.case.incident?.address1,
      address2: props.victim.case.incident?.address2,
      city: props.victim.case.incident?.city,
      state: props.victim.case.incident?.state,
      postal_code: props.victim.case.incident?.postal_code,
      country: props.victim.case.incident?.country,
      county: props.victim.case.incident?.county,
      offense: props.victim.case.incident?.offense,
      description: props.victim.case.incident?.description,
    },
    validate: (values) => {
      const errors = {};

      if (!values.first_name) {
        errors.first_name = "Required";
      }
      if (!values.last_name) {
        errors.last_name = "Required";
      }
      if (!values.cause_of_death) {
        errors.cause_of_death = "Required";
      }
      if (values.date_of_birth && moment(values.date_of_birth).isSameOrAfter(moment())) {
        errors.date_of_birth = "DOB can not be in the future.";
      }

      if (values.date_of_death && moment(values.date_of_death).isSameOrAfter(moment())) {
        errors.date_of_death = "Date of Death can not be in the future.";
      }

      if (values.age != "" && values.age < 0) {
        errors.age = "Age must not be negative."
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      setRequestBody({
        victim: {
          case: {
            id: values.case_id,
            case_number: values.case_number,
            investigative_case_number: values.investigative_case_number,
            status: values.status,
            law_enforcement_agency: values.law_enforcement_agency,
            reported_date: values.reported_date,
            submitted_date: values.submitted_date,
            how_submitted: values.how_submitted,
            submitted_by_law_enforcement: values.submitted_by_law_enforcement,
            met_media: values.met_media,
            social_media_post_date: values.social_media_post_date,
            written_spotlight_date: values.written_spotlight_date,
            fb_live_feature_date: values.fb_live_feature_date,
            on_tv_date: values.on_tv_date,
            news_outlet: values.news_outlet,            
          },
          incident: {
            id: values.incident_id,
            suspect: values.suspect ?? "",
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
            country: values.country,
            county: values.county,
            offense: values.county,
            description: values.description,
          },

          first_name: values.first_name,
          last_name: values.last_name,
          full_name: values.full_name,
          preferred_name: values.preferred_name,
          cause_of_death: values.cause_of_death,
          date_of_birth: values.date_of_birth,
          date_of_death: values.date_of_death,
          age: values.age,
          race: values.race,
          gender: values.gender,
          city_of_death: values.city_of_death,
          state_of_death: values.state_of_death,
          special_categories: values.special_categories,
        },
      });
    },
  });

  React.useEffect(() => {
    if (response) {
      const newState = { ...props.victim, ...response };
      props.setVictim(newState);
      window.scrollTo(0, 0);
    }
  }, [response]);

  //if (response) return <></>;

  return <FormVictim formik={formik} submitText="Update" />;
}

function PhotoUpload (props) {
  const { t, i18n } = useTranslation();
  const FILE_SIZE = 3 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

  const { id, callBack } = props;
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const [view, setView] = React.useState("form");
  const { upload, uploadError } = usePostVictimPhoto(token, id, requestBody);

  const formik = useFormik({
    initialValues: {
      file: null,
      title: props.title,
    },
    validate: (values) => {
      const errors = {};
      //if (!values.file) errors.file = "Required";
      if (values.file !== undefined && values.file !== null) {
        const size = Math.round(values.file.size / 1024);
        if (!SUPPORTED_FORMATS.includes(values.file.type)) {
          errors.file = "Unsupported format " + values.file.type;
        }
        if (size > FILE_SIZE) {
          errors.file = "File should not be more than 3MB.";
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setView("loading");
      setRequestBody({
        file: values.file,
        title: values.title,
      });
    },
  });

  React.useEffect(() => {
    if (upload) callBack(upload);
  }, [upload]);

  return (
    <div>
      {view === "loading" && (
        <Box textAlign="center" pb={5}>
          <p>{t("wait")}</p>
          <CircularProgress />
        </Box>
      )}

      {view === "form" && (
        <>
          <h3>{t("victim.photo.uploadheading")}</h3>

          <form autoComplete="off">
            {uploadError && (
              <Box
                textAlign="center"
                pb={5}
                style={{ background: color.red, padding: "1rem", borderRadius: "4px" }}
                color="white"
                m={0}
                mb={5}
                p="1rem"
              >
                {t("error.victim.documentupload")}
              </Box>
            )}
            {props.existingImage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <p>
                    <img src={props.existingImage} alt="" style={{ width: "100%", height: "auto" }} />
                  </p>
                </GridItem>
              </GridContainer>
            )}
            <Box textAlign="left" pt={10}>
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
              {formik.submitCount > 0 && formik.errors.file && <Danger>{formik.errors.file}</Danger>}
            </Box>

            <p>
              <Button color="info" onClick={formik.handleSubmit} fullWidth={false}>
                {t("victim.photo.upload")}
              </Button>
            </p>
          </form>
        </>
      )}
    </div>
  );
}

export default function PageVictimProfile () {
  return <AdminLayout component={<Page />} />;
}
