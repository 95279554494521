import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostDocuments(token, case_id, requestBody) {
  const [upload, setUpload] = React.useState();
  const [uploadError, setUploadError] = React.useState();
  const [body, setBody] = React.useState();

  const handleResponse = (data) => {
    if (data) setUpload(data);
    else setUploadError("error");
  };

  React.useEffect(() => {
    const bodyString = JSON.stringify(body);
    const requestString = JSON.stringify(requestBody);

    if (token && case_id && requestBody && bodyString !== requestString) {
      setBody(requestBody);
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);

      const formData = new FormData();

      if(requestBody.update) {
        for (const [key, value] of Object.entries(requestBody.body)) {
          formData.append(key, value);
        }
      } else {
        formData.append("file", requestBody.body.file);
        formData.append("document_type", requestBody.body.document_type);
        formData.append("title", requestBody.body.title);
        formData.append("description", requestBody.body.description);
        formData.append("note", requestBody.body.note);
      }

      const path = requestBody.update ? `${PATHS.apiDocuments}/${requestBody.body.id}` : `${PATHS.apiCase}/${case_id}/documents`

      const options = {
        method: requestBody.update ? "PUT" : "POST",
        headers: headers,
        redirect: "follow",
        body: formData,
      };

      // Make request
      fetch(path, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => setUploadError(error));
    }
  }, [token, requestBody]);

  return { upload, uploadError };
}
