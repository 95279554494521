import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostCsvImport(token, requestBody) {
  const [upload, setUpload] = React.useState();
  const [uploadError, setUploadError] = React.useState();
  const [body, setBody] = React.useState();

  const handleResponse = (data) => {
    if (data) {
      if(data.is_error) {
        setUploadError(data.detail);
      }else {
        setUpload(data);
      }
    }
  };

  React.useEffect(() => {
    const bodyString = JSON.stringify(body);
    const requestString = JSON.stringify(requestBody);

    if (token && requestBody && bodyString !== requestString) {
      setBody(requestBody);
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);

      const formData = new FormData();
      if(requestBody.file) formData.append("file", requestBody.file);
      const options = {
        method: "POST",
        headers: headers,
        redirect: "follow",
        body: formData,
      };

      // Make request
      fetch(`${PATHS.apiCase}/import/csv`, options)
        .then((response) => response.text())
        .then((result) => {
          handleResponse(JSON.parse(result))
        })
        .catch((error) => {
          console.log("error", error);
          setUploadError(error);
        }
        );
    }
  }, [token, requestBody]);

  return { upload, uploadError };
}
