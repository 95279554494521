import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { usePostSocial } from "hooks";
import { Danger, InputDate, InputText } from "components";
import { Select, MenuItem } from "@material-ui/core";
import { post_types } from "config/enums";
import { readableEnum } from "utilities";
import DateFnsUtils from '@date-io/date-fns';

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import TextField from '@material-ui/core/TextField';

// Material UI
import Box from "@material-ui/core/Box";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export function FormSocial(props) {
    const State = React.useContext(StateContext);
    const [token] = React.useState(State?.user?.value?.token);
    const [requestBody, setRequestBody] = React.useState();
    const { response, error, isLoading } = usePostSocial(token, requestBody, props.post?.id);
    const case_id = props.case_id;
    const [initValue, setInitValue] = React.useState(props.post);
    React.useEffect(() => {
      setInitValue(props.post);
    }, [props.post]);

    const boxSettings = { mb: 6 };

    const now = () =>  new Date();

    const formik = useFormik({
      initialValues: initValue,
        validate: (values) => {
          const errors = {};
          if (!values.post_type) errors.post_type = "Required";
          if (!values.link) errors.link = "Required";
    
          return errors;
        },
        onSubmit: (values) => {
          let body = {
            id: values.id,
            post_type: values.post_type,
            link: values.link,
            date_of_post: values.date_of_post,
            description: values.description,
          };
          if (case_id != null) body.case_id = case_id;

          setRequestBody(body)
          formik.resetForm();
        },
        enableReinitialize: true,
      });

    const onDateChange = (value) => {
      formik.setFieldValue("date_of_post", value)
    }

    const HelperText = {
      link: formik.errors.link && formik.submitCount ? <Danger>{formik.errors.link}</Danger> : null,
      post_type: formik.errors.post_type && formik.submitCount ? <Danger>{formik.errors.post_type}</Danger> : null,
    }

    React.useEffect(() => {
      if (response) {
        props.setSocial(response);
        setRequestBody(undefined);
      }
    }, [response]);

    return (
        <React.Fragment>
            <>
              <form autoComplete="off">
                <Card>
                  <CardBody>
                    <h3>Social</h3>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <Select
                          onChange={(e) => formik.handleChange(e)}
                          inputProps={{
                            value: formik.values.post_type,
                            onChange: (e) => formik.handleChange(e),
                            name: "post_type",
                            id: "post_type",
                          }}
                        >
                          <MenuItem disabled>Choose one</MenuItem>
                          {post_types.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {readableEnum(item)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["link"]}
                            label="Link"
                            name="link"
                            value={formik.values.link}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                        
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-of-post"
                          label="Post Date"
                          value={formik.values.date_of_post}
                          onChange={(e) => onDateChange(e)}

                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["description"]}
                            label="Description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                      </GridContainer>
                    </Box>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button color="info" fullWidth onClick={formik.handleSubmit}>
                            Submit
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                    </Box>
                    </MuiPickersUtilsProvider>
                  </CardBody>
                </Card>
              </form>
            </>
        </React.Fragment>
    );
}
