import React from "react";
import moment from "moment";
import AdminLayout from "layouts/Admin.js";
import { useHistory } from "react-router-dom";
import { StateContext } from "state.js";
import {useGetNotifications, usePostNotificationRead, usePostNotificationAllRead} from "hooks"

import { useTranslation } from "react-i18next";
import { DynamicDataTable } from "components";

import { makeStyles } from "@material-ui/core/styles";
// Bread crumbs
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

// Grid
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import DataTable from "react-data-table-component";
import { Tooltip } from "@material-ui/core";

// material-ui icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import {Sort, Clear} from "@material-ui/icons";

// core components
import Button from "components-material-ui/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
// Modal
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function Page() {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [perPage, setPerPage] = React.useState(10);
  const [sort, setSort] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [token] = React.useState(State?.user?.value?.token);
  const notifications = State.notifications.value;
  // Global editing
  const [search, setSearch] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const [markReadId, setMarkReadId ] = React.useState();
  const [shouldMarkAllRead, setShouldMarkAllRead] = React.useState();
  const [total, setTotal] = React.useState();



  const { postNotificationReadSuccess, postNotificationMarkedId } = usePostNotificationRead(token, markReadId);
  const { postNotificationAllReadSuccess } = usePostNotificationAllRead(token, shouldMarkAllRead);

  React.useEffect(() => {
    if (postNotificationReadSuccess && postNotificationMarkedId){
      setMarkReadId(undefined);
    }
  }, [postNotificationReadSuccess, postNotificationMarkedId])

  React.useEffect(() => {
    if(postNotificationAllReadSuccess){
      setShouldMarkAllRead(false);
    }
  }, [postNotificationAllReadSuccess])

  const handleClickMarkRead = (id) => {
    console.log("handleClickMarkRead"+id)
    setMarkReadId(id);
  }

  const handleClickMarkAllRead = () => {
    setShouldMarkAllRead(true);
  } 

  const classes = useStyles();

  React.useEffect(() => {
    if (notifications) {
      const data = notifications.map((k, v) => {
        return {
          type: k.type.replace("Notification", ""),
          title: k.params.title,
          message: k.params.message,
          updated_at: moment.utc(k.updated_at).local().format("LLLL"),
          actions: <>
            <Tooltip title="Mark Read">
              <Button
                color="success"
                className={classes.actionButton}
                key={2}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleClickMarkRead(k.id);
                }}
              >
                <ClearIcon className={classes.icon} />
              </Button>
            </Tooltip>
          </>,
            };
      });
      setTableData(data); 
      setTotal(data.length);  
    } else {
      setTableData([]);
      setTotal(0);
    }
  }, [notifications])  

  return (
    <div>
      <GridContainer>
        <GridItem xs={6}>
          <h1>Notifications</h1>
        </GridItem>
      </GridContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Dashboard
        </Link>
        <Typography color="textPrimary">Notifications</Typography>
      </Breadcrumbs>

      <DataTable
          title={"Notifications"}
          data={tableData}
          columns = {[
            {
              name: 'Type',
              selector: 'type',
              sortable: true,
              filterable: true,
            },
            {
              name: 'Title',
              selector: 'title',
              sortable: true,
              filterable: true,
            },
            {
              name: 'Message',
              selector: 'message',
              sortable: true,
            },
            {
              name: 'Last Updated',
              selector: 'updated_at',
              sortable: true                
            },
            {
              name: "",
              selector: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          //progressPending={isLoading}
          pagination
          paginationTotalRows={total}
          paginationPerPage = {perPage}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    
          striped
          persistTableHead
          subHeader
          subHeaderWrap
          //subHeaderComponent={subHeaderComponentMemo}

          defaultSortField={"updated_at"}
          defaultSortAsc={false}
          sortIcon={<Sort />}
        />
           
    </div>
  );
}

export default function PageNotificationsList() {
  return <AdminLayout component={<Page />} />;
}
