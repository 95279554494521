import React from "react";
import { StateContext } from "state.js";
import moment from "moment";
import { formatDate } from "utilities";
import { useSnackbar } from 'notistack';

import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import { useFormik } from "formik";

import { TextField, Select, MenuItem, InputLabel, Button } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

import { ActiveUserDropdown } from "components";
import { usePostCaseSubmissionApproval } from "hooks";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '& .MuiAlert-root': {
      marginBottom: theme.spacing(2),
    }
  },
}));

export function CaseApproval (props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [request, setRequest] = React.useState();
  const [isError, setIsError] = React.useState(false);
  const {usePostCaseSubmissionApprovalResponse, usePostCaseSubmissionApprovalError} = usePostCaseSubmissionApproval(token, props.caseSubmissionId, request);

  const [isAdmin] = React.useState(State?.user?.value?.role === "admin");
  React.useEffect(() => {
    if (usePostCaseSubmissionApprovalResponse) {
      props.refresh(usePostCaseSubmissionApprovalResponse);
      enqueueSnackbar('Approval saved', { 
        variant: 'success',
      });
    }
  }, [usePostCaseSubmissionApprovalResponse])

  React.useEffect(() => {
    if (usePostCaseSubmissionApprovalError){
      enqueueSnackbar('Approval error',
      { 
        variant: 'error',
      });
    }
  }, [usePostCaseSubmissionApprovalError]);


  const formik = useFormik({
    initialValues: {
      status: props.caseSubmissionApproval?.status || "",
      followup_date: props.caseSubmissionApproval?.followup_date || "",
      approved_by_id: props.caseSubmissionApproval?.approved_by_id || "",
      notes: props.caseSubmissionApproval?.notes,
      case_number: props.caseSubmissionApproval?.case_number || "",
      caseSubmissionCriterium: props.caseSubmissionCriterium
    },
    validate: (values) => {
      const errors = {};
      if (!values.approved_by_id) errors.approved_by_id = "Please set Approved By";
      if (!values.status) errors.status = "Please select a status";
      if (values.status == "FOLLOWUP_LATER" && values.followup_date == "") errors.status = "Must set follow up date";
      if (values.status == "APPROVED" && !values.caseSubmissionCriterium.is_minimum_criteria_met) errors.status = "Minimum Criteria not met."
      //if (values.status == "APPROVED" && !values.caseSubmissionCriterium.is_initial_contact_complete) errors.status = "No Initial Contact."
      if (values.status == "APPROVED" && !values.caseSubmissionCriterium.is_feature_photo_approved) errors.status = "No Featured Photo."
      if (values.status == "APPROVED" && !values.caseSubmissionCriterium.is_imported_images_approved) errors.status = "Images not approved."
      if (values.status == "APPROVED" && !values.caseSubmissionCriterium.is_imported_documents_approved) errors.status = "Documents not approved."
      if (values.status == "APPROVED" && values.case_number.length == 0) errors.status = "Must have case number before approving."
      if (values.status == "APPROVED" && !isAdmin) errors.status = "Must be admin role to approve case."
      return errors;
    },
    onSubmit: (values) => {
      const body = {          
        case_submission_approval:  values
      };
      setRequest(body);
    },
  });

  const handleApproval = () => {
    formik.handleSubmit()
  }

  const handleClose = () => {
    setIsError(false);
  }

  const handleContactedDateChange = (e) => {
    formik.setFieldValue('followup_date', e.target.value)
  }

  React.useEffect(() => {
    if(usePostCaseSubmissionApprovalError) setIsError(true);
  }, [usePostCaseSubmissionApprovalError])

  return (
    <>
    <Card className={classes.root}>
    <CardContent>
      <GridContainer className={classes.root} >
        <GridItem xs={12} sm={12} >
          <h6>Approval</h6>
          {!isAdmin && (
            <Alert severity="error" >Admin role is required to approve case.</Alert>
          )}
          {Object.keys(formik.errors).map((k) => {
            return <Alert severity="error" key={k}>{formik.errors[k]}</Alert>
          })}          
        </GridItem>
        <GridItem xs={12} sm={6} >
          <label>
            Status
            <Select
              onChange={(e) => formik.handleChange(e)}
              inputProps={{
                value: formik.values.status,
                onChange: (e) => formik.handleChange(e),
                name: "status",
                id: "status",
              }}
              disabled={props.disabled}
            >
              <MenuItem>Choose one</MenuItem>
              <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
              <MenuItem value={"PENDING"}>Pending</MenuItem>
              <MenuItem value={"REJECTED"}>Rejected</MenuItem>
              <MenuItem value={"APPROVED"}>Approved</MenuItem>
              <MenuItem value={"FOLLOWUP_LATER"}>Followup Later</MenuItem>
              <MenuItem value={"HOLD"}>Hold Later</MenuItem>

            </Select>
          </label>
        </GridItem>
        <GridItem xs={12} sm={6} >
          <ActiveUserDropdown
            label="Approved By"
            onChange={(val) => {
              formik.setFieldValue('approved_by_id', val);
            }}
            value={formik.values.approved_by_id}
            disabled={props.disabled}
          />
          </GridItem>
        <GridItem xs={12} sm={6} >
          {formik.values.status == "FOLLOWUP_LATER" && (
          <TextField
            id="followup-date"
            label="Followup Date"
            type="date"
            defaultValue={formik.values.followup_date}
            onChange={(val) => handleContactedDateChange(val)}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.disabled}
          />
          )}
        </GridItem>
        <GridItem xs={12}>
        <TextField
                id="case_number"
                label="Case Number"
                defaultValue={formik.values.case_number}
                onBlur={
                  (e) => formik.setFieldValue('case_number', e.target.value)
                }
                disabled={props.disabled}
                v-if="formik.values.status == 'APPROVED'"
              />
        </GridItem>
        <GridItem xs={12}>
        <TextField
                id="notes"
                label="Notes"
                multiline
                rows={4}
                defaultValue={formik.values.notes}
                onBlur={
                  (e) => formik.setFieldValue('notes', e.target.value)
                }
                disabled={props.disabled}
              />
        </GridItem>
        </GridContainer>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApproval}
            className={classes.button}
            fullWidth
            disabled={props.disabled}
          >
            Update Approval
          </Button>
        </CardActions>
      
      </Card>

      <Dialog
        open={isError}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Approval Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Approval Failed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
       
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
