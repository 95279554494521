import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  pccColorBlackCardHeader,
  pccColorGrayCardHeader,
  pccColorWhiteCardHeader,
  pccColorBlueDarkCardHeader,
  pccColorGreenCardHeader,
  pccColorBlueLightCardHeader,
} from "assets/jss/material-dashboard-pro-react.js";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$pccColorBlackCardHeader,&$pccColorGrayCardHeader,&$pccColorWhiteCardHeader,&$pccColorBlueDarkCardHeader,&$pccColorGreenCardHeader,&$pccColorBlueLightCardHeader": {
      borderRadius: "3px",
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left",
    },
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  pccColorBlackCardHeader,
  pccColorGrayCardHeader,
  pccColorWhiteCardHeader,
  pccColorBlueDarkCardHeader,
  pccColorGreenCardHeader,
  pccColorBlueLightCardHeader,
};

export default cardIconStyle;
