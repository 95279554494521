import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useDeleteContactEmail(token, contact_id, requestEmail,  isConfirmed) {
  const [email, setEmail] = React.useState();
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Compare requestBody to email so we don't infinite loop
   */
  React.useEffect(() => {
    if (!lodash.isEqual(requestEmail, email) &&  isConfirmed) {
      setError(undefined);
      setResponse(undefined);
      setEmail(requestEmail);
      setIsLoading(true);
    }
  }, [requestEmail, setEmail,  isConfirmed]);

  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && email ) {
      const options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };

      const path = email.id ? `${PATHS.apiContacts}/${contact_id}/email/${email.id}` : `${PATHS.apiContacts}/${contact_id}/email`;

      async function postRequest() {
        const request = await fetch(path, options);
        return true;
      }

      // Delete returns 204 No Content so it does not have a body, setResponse to true
      postRequest()
        .then(async (response) => {      
           setResponse(response)
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }, [token, email, setResponse]);

  return { emailDeleteSuccess: response, emailDeleteError: error, emailIsLoading: isLoading };
}
