import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetCsvExport (token, isDownloading) {
  const [file, setFile] = React.useState();
  const [exportError, setExportError] = React.useState();

  React.useEffect(() => {
    setFile(false);
    if (token && isDownloading) {
      const headers = new Headers();
      headers.append("Accept", "application/csv");
      headers.append("Authorization", `Bearer ${token}`);

      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow"
      };

      // Make request
      fetch(`${PATHS.apiCase}/export/csv`, options)
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const date = new Date();
          const fileName = `CaseExport-${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.csv`;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            fileName,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          setExportError(error);          
        })
        .finally(() => {
          setFile(true);
        });
    }
  }, [token, isDownloading]);

  return { file, exportError };
}
