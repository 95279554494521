import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetNewVictims(token) {
  const [newVictims, setNewVictims] = React.useState();

  React.useEffect(() => {
    if (!newVictims) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(PATHS.apiNewVictims, options)
        .then((response) => response.json())
        .then((result) => setNewVictims(result))
        .catch(() => setNewVictims([]));
    }
  }, [token]);

  return { newVictims };
}
