import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";
import { StateContext } from "state.js";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostNotificationAllRead(token, shouldMarkAllRead = undefined) {
  const State = React.useContext(StateContext);
  const [isSuccess, setIsSuccess] = React.useState();
  const setRefresh = State.notificationsRefresh.set;


  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && shouldMarkAllRead) {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };

      const path = `${PATHS.apiNotificationsMarkAllRead}`;

      async function patchRequest() {
        const request = await fetch(path, options)
          .then((res) => res.text())
          .then((text) => {
            text.length ? JSON.parse(text) : {};
            setIsSuccess(true);
            setRefresh(true);
          })
          .catch((error) => {
            setIsSuccess(false);
            setRefresh(false);
          });
      }

      patchRequest();
    }
  }, [token, shouldMarkAllRead]);

  return { postNotificationAllReadSuccess: isSuccess };
}
