import React from "react";
import { useTranslation } from "react-i18next";

import { yes_no, state, alert_status, how_submitted, special_categories, race, gender, countries } from "config/enums";
import { Connections, Danger, InputText } from "components";
import { formatDate, readableEnum } from "utilities";
import { relationship } from "config/enums";
import MuiPhoneNumber from 'material-ui-phone-number';

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";

// Material UI
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

/* eslint-disable react-hooks/exhaustive-deps */

export function FormSurvivors(props) {
  const { t, i18n } = useTranslation();

  const [victim, setVictim] = React.useState(props?.victim);
  const [victimModalOpen, setVictimModalOpen] = React.useState(false);
  const formik = props.formik;
  const boxSettings = { mb: 6 };

  const HelperText = {
    case_id:
      formik.errors.case_id && formik.submitCount ? (
        <Danger>Please search for and select a victim above.</Danger>
      ) : null,
    first_name: formik.errors.first_name && formik.submitCount ? <Danger>{formik.errors.first_name}</Danger> : null,
    last_name: formik.errors.last_name && formik.submitCount ? <Danger>{formik.errors.last_name}</Danger> : null,
    phone: formik.errors.phone && formik.submitCount ? <Danger>{formik.errors.phone}</Danger> : null,
    email: formik.errors.email && formik.submitCount ? <Danger>{formik.errors.email}</Danger> : null,
    address1: formik.errors.address1 && formik.submitCount ? <Danger>{formik.errors.address1}</Danger> : null,
    address2: formik.errors.address2 && formik.submitCount ? <Danger>{formik.errors.address2}</Danger> : null,
    city: formik.errors.city && formik.submitCount ? <Danger>{formik.errors.city}</Danger> : null,
    state: formik.errors.state && formik.submitCount ? <Danger>{formik.errors.state}</Danger> : null,
    gender: formik.errors.gender && formik.submitCount ? <Danger>{formik.errors.gender}</Danger> : null,
    race: formik.errors.race && formik.submitCount ? <Danger>{formik.errors.race}</Danger> : null,
    postal_code: formik.errors.postal_code && formik.submitCount ? <Danger>{formik.errors.postal_code}</Danger> : null,
    country: formik.errors.country && formik.submitCount ? <Danger>{formik.errors.country}</Danger> : null,
    county: formik.errors.county && formik.submitCount ? <Danger>{formik.errors.county}</Danger> : null,
  };

  React.useEffect(() => {
    if (victim?.case?.id) formik.setFieldValue("case_id", victim.case.id);
  }, [victim]);

  React.useEffect(() => {
    if (props?.preSelectVictim) setVictim(props?.preSelectVictim);
  }, [props.preSelectVictim]);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        open={victimModalOpen}
        keepMounted
        onClose={() => setVictimModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <h4>Search Victim</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Connections
            type="victim"
            typeChoice={false}
            setObject={setVictim}
            setClose={() => setVictimModalOpen(false)}
          />
          <Box textAlign="center" pb={2}>
            <Button onClick={() => setVictimModalOpen(false)}>Cancel</Button>
          </Box>
        </DialogContent>
      </Dialog>

      <form autoComplete="off">
        <h3>Victim Details</h3>
        <Button onClick={() => setVictimModalOpen(true)}>Select Victim</Button>
        {HelperText["case_id"] && <>{HelperText["case_id"]}</>}

        {victim && (
          <Box py={4}>
            <h4>{t("victim.selected")}</h4>
            Name: {victim?.first_name} {victim?.last_name} {`(${victim?.case.case_number || ""})`}
            <br />
            Location: {victim.state_of_death} <br />
            Cause of Death: {readableEnum(victim.cause_of_death)} <br />
            DOB: {formatDate(victim.date_of_birth)} <br />
            DOD: {formatDate(victim.date_of_death)} <br />
          </Box>
        )}

        <h3>Survivor Details</h3>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["first_name"]}
                label={t("firstname")}
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["last_name"]}
                label={t("lastname")}
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label={t("fullname")}
                name="full_name"
                value={formik.values.full_name}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label={t("preferredname")}
                name="preferred_name"
                value={formik.values.preferred_name}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                

                <MuiPhoneNumber 
                disableAreaCodes
                  helperText={HelperText["phone"]}
                  label={t("phone")}
                  name="phone" defaultCountry={'us'} 
                  value={formik.values.phone} 
                  onChange={e => formik.setFieldValue("phone", e)}
                  />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <InputText
                helperText={HelperText["email"]}
                label={t("email")}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <label>
                {t("gender")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.gender,
                    onChange: (e) => formik.handleChange(e),
                    name: "gender",
                    id: "gender",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {gender.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["gender"] && <>{HelperText["gender"]}</>}
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <label>
                {t("race")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.race,
                    onChange: (e) => formik.handleChange(e),
                    name: "race",
                    id: "race",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {race.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["race"] && <>{HelperText["race"]}</>}
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                helperText={HelperText["address1"]}
                label={t("address1")}
                name="address1"
                value={formik.values.address1}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                helperText={HelperText["address2"]}
                label={t("address2")}
                name="address2"
                value={formik.values.address2}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InputText
                helperText={HelperText["city"]}
                label={t("city")}
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("state")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.state,
                    onChange: (e) => formik.handleChange(e),
                    name: "state",
                    id: "state",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {state.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["state"] && <>{HelperText["state"]}</>}
              </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <InputText
                helperText={HelperText["postal_code"]}
                label={t("zip")}
                name="postal_code"
                value={formik.values.postal_code}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
          <GridItem xs={12} sm={12} md={4}>             
              <label>
                {t("country")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.country ?? "",
                    onChange: (e) => formik.handleChange(e),
                    name: "country",
                    id: "country",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {countries.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.v}>
                        {item.k}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["county"]}
                label={t("county")}
                name="county"
                value={formik.values.county}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("optnews")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.opt_in_news,
                    onChange: (e) => formik.handleChange(e),
                    name: "opt_in_news",
                    id: "opt_in_news",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {yes_no.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>{" "}
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("eventinterest")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.events_interest,
                    onChange: (e) => formik.handleChange(e),
                    name: "events_interest",
                    id: "events_interest",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {yes_no.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("lecon")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.le_connection,
                    onChange: (e) => formik.handleChange(e),
                    name: "le_connection",
                    id: "le_connection",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {yes_no.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>{" "}
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("howlearned")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.how_learned_pcc,
                    onChange: (e) => formik.handleChange(e),
                    name: "how_learned_pcc",
                    id: "how_learned_pcc",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {how_submitted.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("othernp")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.other_non_profit,
                    onChange: (e) => formik.handleChange(e),
                    name: "other_non_profit",
                    id: "other_non_profit",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {yes_no.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("otherservices")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.other_victim_services,
                    onChange: (e) => formik.handleChange(e),
                    name: "other_victim_services",
                    id: "other_victim_services",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {yes_no.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              Relationship to {victim?.first_name} {victim?.last_name}
              <Select
                onChange={(e) => formik.handleChange(e)}
                inputProps={{
                  value: formik.values.relation_to_victim,
                  onChange: (e) => formik.handleChange(e),
                  name: "relation_to_victim",
                  id: "relation_to_victim",
                }}
              >
                <MenuItem disabled>Choose one</MenuItem>
                {relationship.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {readableEnum(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
            {victim?.first_name} {victim?.last_name}&apos;s (Victim) Relationship to {formik.values.first_name} {formik.values.last_name} (Survivor)
              <Select
                onChange={(e) => formik.handleChange(e)}
                inputProps={{
                  value: formik.values.victim_relation_to_survivor,
                  onChange: (e) => formik.handleChange(e),
                  name: "victim_relation_to_survivor",
                  id: "victim_relation_to_survivor",
                }}
              >
                <MenuItem disabled>Choose one</MenuItem>
                {relationship.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {readableEnum(item)}
                    </MenuItem>
                  );
                })}
              </Select>
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <label>{t("alertstatus")}</label>
              <br />

              {alert_status.map((item, index) => {
                return (
                  <label className="checkbox-label" key={`checkbox-${index}`}>
                    <Checkbox
                      name="alerts"
                      value={item}
                      onChange={(e) => formik.handleChange(e)}
                      checked={Boolean(formik.values.alerts.indexOf(item) + 1)}
                    />{" "}
                    {readableEnum(item)}
                  </label>
                );
              })}
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <label>{t("specialcat")}</label>
              <br />

              {special_categories.map((item, index) => {
                return (
                  <label className="checkbox-label" key={`checkbox-${index}`}>
                    <Checkbox
                      name="special_categories"
                      value={item}
                      onChange={(e) => formik.handleChange(e)}
                      checked={Boolean(formik.values.special_categories.indexOf(item) + 1)}
                    />{" "}
                    {readableEnum(item)}
                  </label>
                );
              })}
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                className="text-area"
                style={{ width: "100%" }}
                name="note"
                label={t("notes")}
                multiline
                maxRows={4}
                value={formik.values.note}
                onChange={(e) => formik.handleChange(e)}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}></GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Button color="info" fullWidth onClick={formik.handleSubmit}>
                {props?.submitText ? <>{props?.submitText}</> : <>Add</>}
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}></GridItem>
          </GridContainer>
        </Box>
      </form>
    </React.Fragment>
  );
}
