import React from "react";
import AdminLayout from "layouts/Admin.js";
import { StateContext } from "state.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/pages/PageStyles.js";
import { useFormik } from "formik";
import { usePostCsvImport, useGetCsvExport } from "hooks";

import Box from "@material-ui/core/Box";
import Button from "components-material-ui/CustomButtons/Button.js";
import Danger from "components-material-ui/Typography/Danger.js";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import { color } from "styles/styled";


const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function Page () {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const { upload, uploadError } = usePostCsvImport(token, requestBody);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const { file, exportError } = useGetCsvExport(token, isDownloading);
  const [isLoading, setIsLoading] = React.useState();

  const classes = useStyles();
  const SUPPORTED_FORMATS = ["text/csv"];
  const FILE_SIZE = 3 * 1024;

  const onDownload = () => {
    setIsDownloading(true);
  }
  React.useEffect(() => {
    if (file) {
      setIsDownloading(false);
    }
  }, [file]);

  React.useEffect(() => {
    if (upload || uploadError) {
      setIsLoading(false);
    }
  }, [upload, uploadError]);

  React.useEffect(() => {
    if (file) {
      setIsDownloading(false);
    }
  }, [file]);

  const formik = useFormik({
    initialValues: {
      file: null
    },
    validate: (values) => {
      const errors = {};
      //if (!values.file) errors.file = "Required";
      if (values.file !== undefined && values.file !== null) {
        const size = Math.round(values.file.size / 1024);
        if (!SUPPORTED_FORMATS.includes(values.file.type)) {
          errors.file = "Unsupported format " + values.file.type;
        }
        if (size > FILE_SIZE) {
          errors.file = "File should not be more than 3MB.";
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      setRequestBody({
        file: values.file
      });
    }
  });

  return (
    <div>
      <h3>Victim Import/Export</h3>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          '& > :not(style)': {
            m: 1,
            padding: 20,
            width: 400,
          },
          justifyContent: 'space-around' 
        }}
      >

        <Paper elevation={1} sx={{ margin: 10 }}>

          <h4>Export Victims</h4>
          <p>
            <Button color="info" onClick={onDownload} fullWidth={false}>
              Export All Victims to CSV
            </Button>
          </p>

          {isDownloading && (
            <Box textAlign="center" pb={5}>
              <p>Exporting Victims</p>
              <CircularProgress />
            </Box>
          )}
          {exportError && (
            <Box textAlign="center" pb={5}>
              <Danger>{exportError.message}</Danger>
            </Box>
          )}
        </Paper>

        <Paper elevation={1} sx={{ margin: 10 }} >
          <form autoComplete="off">
            <h4>Victim Import</h4>
            <p>Imports new cases and victims into the database.</p>
            <Box textAlign="left" pt={10}>
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
              {formik.submitCount > 0 && formik.errors.file &&
                <Box
                  textAlign="center"
                  pb={5}
                  style={{ background: color.red, padding: "1rem", borderRadius: "4px" }}
                  color="white"
                  m={0}
                  mb={5}
                  p="1rem"
                >
                  <Danger>{formik.errors.file}</Danger>
                </Box>
              }
            </Box>
            {isLoading && (
              <Box textAlign="center" pb={5}>
                <p>Processing Import</p>
                <CircularProgress />
              </Box>
            )}
            {upload && (
              <Box textAlign="left" p={5}>
                <p>Import Done</p>                
              </Box>
            )}
            {uploadError && (
              <Box textAlign="center" pb={5}>       
                <Alert severity="error">{uploadError}</Alert>
              </Box>
            )}
            <p>
              <Button color="info" onClick={formik.handleSubmit} fullWidth={false}>
                Import CSV
              </Button>
            </p>
          </form>
        </Paper>
      </Box>
    </div>
  );
}

export default function PageAdminImport () {
  return <AdminLayout component={<Page />} />;
}
