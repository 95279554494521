import React from "react";
import AdminLayout from "layouts/Admin.js";
import PATHS from "config/paths";
import { StateContext } from "state.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { formatDate, readableEnum } from "utilities";

import { useGetCaseSubmissions } from "hooks";
import { DynamicDataTable } from "components";
import InputLabel from "@material-ui/core/InputLabel";

// material-ui icons
import { FormControl, Select, MenuItem  } from "@material-ui/core";

// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);


function Page () {
  const State = React.useContext(StateContext);
  const history = useHistory();

  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [sort, setSort] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [token] = React.useState(State?.user?.value?.token);
  const [search, setSearch] = React.useState();
  const [version, setVersion] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);

  const refresh = React.useCallback(() => {
    setVersion((s) => s + 1);
  }, []);

  const { caseSubmissions, caseSubmissionsError, caseSubmissionsPageCount, total, isLoading } = useGetCaseSubmissions(
    token,
    version,
    page,
    search,
    perPage,
    sort,
    true
  );


  const classes = useStyles();
  

  React.useEffect(() => setError(true), [caseSubmissionsError]);

  const [open, setOpen] = React.useState();
  React.useEffect(() => {
    if (open) {
      if (open.a == "edit") {
        history.push(`${PATHS.caseSubmissionView}/${open.id}`);
      }
      if (open.a == "tab") {
        window.open(`${PATHS.caseSubmissionView}/${open.id}`);
      }
      if (open.a == "delete") {
        handleDeleteClick(open.id);
      }
      // todo get chrome to open new window
      if (open.a == "window") {
        const target = `_win${open.id}`;
        window.open(`${PATHS.caseSubmissionView}/${open.id}`, target);
      }

    }
  }, [open]);

  React.useEffect(() => {
    if (caseSubmissions) {
      const data = caseSubmissions.map((k, v) => {
        return {
          created_at: formatDate(k.created_at),
          victim_first_name: k.victim_first_name,
          victim_last_name: k.victim_last_name,
          incident_state: k.incident_state,
          survivor_first_name: k.survivor_first_name,
          survivor_last_name: k.survivor_last_name,
          survivor_email: k.survivor_email,
          survivor_phone: k.survivor_phone,
          survivor_state: k.survivor_state,
          status: readableEnum(k.case_submission_approval?.status),
          actions: 
            
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Edit</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={""}
                label="Age"
                onChange={(e) => { setOpen(e.target.value) }}
              >
                <MenuItem value={{ a: "edit", id: k.id }}>Edit</MenuItem>
                <MenuItem value={{ a: "tab", id: k.id }}>Edit in New Tab</MenuItem>
                
              </Select>
            </FormControl>,
        };
      });
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [caseSubmissions])

  const handlePageChange = (page, totalRows) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    const newSort = {
      sortDirection: sortDirection,
      field: column.selector
    };
    setSort(newSort);
  };

  const handleFilter = (filter) => {
    console.log(filter);
    const filterString = `by_search=${filter}`
    setSearch(filterString);
  };

  const handleStatusFilter = (filter) => {
    if (filter) {
      const filterString = `by_status=${filter}`
      setSearch(filterString);
    } else {
      setSearch(undefined)
    }
  };


  return (
    <div>
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          Case Submissions
        </Typography>
      </Box>
      <DynamicDataTable
        title={"Case Submissions"}
        data={tableData}
        columns={[
          {
            name: "",
            selector: "actions",
            sortable: false,
            filterable: false
          },
          {
            name: 'Submitted',
            selector: 'created_at',
            sortable: true,
            filterable: true,
          },
          {
            name: 'Status',
            selector: 'status',
            sortable: true,
            filterable: true,
          },
          {
            name: 'Victim First Name',
            selector: 'victim_first_name',
            sortable: true,
            filterable: true,
          },
          {
            name: 'Victim Last Name',
            selector: 'victim_last_name',
            sortable: true,
            filterable: true,
          },
          {
            name: 'Incident State',
            selector: 'incident_state',
            sortable: true,
            filterable: true,
          },
          {
            name: 'Survivor First Name',
            selector: 'survivor_first_name',
            sortable: true,
            filterable: true,
          },
          {
            name: "Survivor Last Name",
            selector: "survivor_last_name",
            sortable: true,
            filterable: true,
          },
          
          {
            name: "Survivor State",
            selector: "survivor_state",
            sortable: true,
            filterable: true,
          }
        ]}
        rows={total}
        defaultSortField="name"
        perRowsChangeCallback={handlePerRowsChange}
        pageChangeCallback={handlePageChange}
        sortCallback={handleSort}
        filterCallback={handleFilter}
        initialPerPage={perPage}
        isLoading={isLoading}
        filterComponents={[
          <Box p={10} flexGrow={1} key={2}  >
            <FormControl className={classes.formControl} size={"medium"}>

              <label>
                Status
                <Select

                  inputProps={{
                    value: "",
                    onChange: (e) => handleStatusFilter(e.target.value),
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem>All</MenuItem>
                  <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
                  <MenuItem value={"PENDING"}>Pending</MenuItem>
                  <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                  <MenuItem value={"APPROVED"}>Approved</MenuItem>
                  <MenuItem value={"FOLLOWUP_LATER"}>Followup Later</MenuItem>
                  <MenuItem value={"HOLD"}>Hold Later</MenuItem>
                </Select>
              </label>
            </FormControl>
          </Box>

        ]}
      />
    </div>
  );
}

export default function PageCaseSubmissions () {
  return <AdminLayout component={<Page />} />;
}
