/**
 * TODO: Survivors Browse Page
 * - API needs Victim fields (First Name, last name, relationship to survivor)
 * - APi needs touchpoint fields (date of last touchpoint, days since touchpoint)
 */

import React from "react";
import PATHS from "config/paths";
import AdminLayout from "layouts/Admin.js";
import { useTranslation } from 'react-i18next';
import { lighten, makeStyles } from '@material-ui/core/styles';

import moment from "moment";
import { useHistory } from "react-router-dom";
import { StateContext } from "state.js";
import { useGetSurvivors } from "hooks";
import { formatDate } from "utilities";

// Creative Tim
import Button from "components-material-ui/CustomButtons/Button.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Material UI
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));


function Page () {
  const { t, i18n } = useTranslation();
  const classes = useToolbarStyles();

  const State = React.useContext(StateContext);
  const [page, setPage] = React.useState(1);
  const [orderDir, setOrderDir] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [sort, setSort] = React.useState();

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSort(`sorted_by=${property}_${orderDir}`);
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [error, setError] = React.useState(false);
  const [token] = React.useState(State?.user?.value?.token);
  const { survivors, survivorsError, survivorsPageCount, survivorsIsLoading } = useGetSurvivors(
    token,
    page,
    false,
    true,
    false,
    sort);
  const history = useHistory();

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: t('name') },
    { id: 'victim', numeric: false, disablePadding: false, label: t('survivor.victim') },
    { id: 'victim_relation_to_survivor', numeric: false, disablePadding: false, label: t('survivor.victimrelation') },
    { id: 'city', numeric: false, disablePadding: false, label: t('city') },
    { id: 'state', numeric: false, disablePadding: false, label: t('state') },
    { id: 'last_touchpoint', numeric: false, disablePadding: false, label: t('survivor.touchpoint.last') },
  ];

  // Handle errors
  React.useEffect(() => setError(true), [survivorsError]);

  const [open, setOpen] = React.useState();
  React.useEffect(() => {
    if (open) {
      if (open.a == "open") {
        history.push(`${PATHS.survivorsProfile}/${open.id}`);
      }
      if (open.a == "tab") {
        window.open(`${PATHS.survivorsProfile}/${open.id}`);
      }
      // todo get chrome to open new window
      if (open.a == "window") {
        const target = `_win${open.id}`;
        window.open(`${PATHS.survivorsProfile}/${open.id}`, target);
      }

    }
  }, [open]);

  return (
    <div>
      {survivorsError && (
        <SnackbarContent
          color="info"
          message={t('error.general')}
          open={error}
          onClick={() => {
            setError(false);
          }}
          close
        />
      )}
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          {t('browse.survivors')}
        </Typography>
      </Box>
      <Box textAlign="center" mb={5}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button size="sm" component="a" href="/survivors/search">
            {t('advancesearch')}
          </Button>
          <Button size="sm" color="info" component="a" href="/survivors/new">
            {t('addnew')}
          </Button>
        </ButtonGroup>
      </Box>
      {survivorsIsLoading && (
        <Box textAlign="center" pb={5}>
          <p>{t('wait')}</p>
          <CircularProgress />
        </Box>
      )}
      {survivors && survivors?.length === 0 && !survivorsIsLoading && (
        <Box textAlign="center" pb={5}>
          {t('survivor.none')}
        </Box>
      )}

      {survivors && survivors?.length > 0 && !survivorsIsLoading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className="hover">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? orderDir : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {orderDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {survivors.map((survivor, index) => {
                const dateObject =
                  survivor.touchpoints.length > 0 ? formatDate(survivor.touchpoints[0].date, true) : null;
                const touchpointDate = dateObject ? dateObject.format("M-D-YYYY") : null;
                const touchpointDaysSince = dateObject ? moment().diff(dateObject, "days") : null;
                const firstName = survivor?.case?.victim?.first_name || "";
                const lastName = survivor?.case?.victim?.last_name || "";

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Open</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={""}
                          label="Age"
                          onChange={(e) => { setOpen(e.target.value) }}
                        >
                          <MenuItem value={{ a: "open", id: survivor.id }}>Open</MenuItem>
                          <MenuItem value={{ a: "tab", id: survivor.id }}>Open in New Tab</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      {survivor.first_name} {survivor.last_name}
                    </TableCell>
                    <TableCell>{`${firstName} ${lastName}`}</TableCell>
                    <TableCell>{survivor.relation_to_victim}</TableCell>
                    <TableCell>{survivor.city}</TableCell>
                    <TableCell>{survivor.state}</TableCell>
                    <TableCell>
                      {touchpointDate && <>{touchpointDate} - </>}
                      {touchpointDaysSince && <>{touchpointDaysSince} days ago</>}
                    </TableCell>
                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {survivors && survivorsPageCount && (
        <Box textAlign="center" mt={5}>
          <Pagination
            disabled={survivorsIsLoading}
            page={page}
            count={survivorsPageCount}
            onChange={(e, p) => setPage(p)}
          />
        </Box>
      )}
    </div>
  );
}

export default function PageSurvivors () {
  return <AdminLayout component={<Page />} />;
}
