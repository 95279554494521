import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

const cache = new Map();

export function useGetUsers(token, page, search, noCache = false) {
  const [users, setUsers] = React.useState();
  const [error, setError] = React.useState();
  const [pageCount, setPageCount] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleResponse = (data) => {
    if (data?.length > 0) {
      setUsers(data);
      setIsLoading(false);
      setError(undefined);
    } else {
      setUsers(undefined);
      setIsLoading(false);
      setError("no-data");
    }
  };

  React.useEffect(() => {
     if (token && page) {
      setIsLoading(true);
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      /**
       * Filters
       */
      let filters = "";
      if (page !== 1) filters = `page=${page}`;
      if (filters.length > 0 && search?.length > 0) {
        filters = `${filters}&${search}`;
      } else if (search?.length > 0) {
        filters = search;
      }

      /**
       * Make request
       */
      fetch(`${PATHS.apiRegistration}?${filters}`, options)
        .then((response) => {
          const total = Number.parseInt(response.headers.get("total"));
          const perPage = Number.parseInt(response.headers.get("per-page"));
          const count = Math.ceil(total / perPage);
          setPageCount(count);
          return response.json();
        })
        .then((result) => handleResponse(result))
        .catch((error) => {
          setIsLoading(false);
          setError(error);
        });
    }
  }, [token, page, search]);

  return { getUsers: users, getUsersErrors: error, getUsersPageCount: pageCount, getUsersIsLoading: isLoading };
}
