import React from "react";
import { Danger, InputText } from "components";
import { readableEnum } from "utilities";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import CustomInput from "components-material-ui/CustomInput/CustomInput.js";

// Material UI
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export function FormUsers(props) {
  const formik = props.formik;
  const boxSettings = { mb: 6 };

  const HelperText = {
    first_name: formik.errors.first_name && formik.submitCount ? <Danger>{formik.errors.first_name}</Danger> : null,
    last_name: formik.errors.last_name && formik.submitCount ? <Danger>{formik.errors.last_name}</Danger> : null,
    email: formik.errors.email && formik.submitCount ? <Danger>{formik.errors.email}</Danger> : null,
    password_digest: formik.errors.email && formik.submitCount ? <Danger>{formik.errors.email}</Danger> : null,
    status: formik.errors.status && formik.submitCount ? <Danger>{formik.errors.status}</Danger> : null,
    role: formik.errors.role && formik.submitCount ? <Danger>{formik.errors.role}</Danger> : null,
  };

  return (
    <React.Fragment>
      <form autoComplete="off">
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["first_name"]}
                label="First Name"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["last_name"]}
                label="Last Name"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["email"]}
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                helperText={HelperText["password_digest"]}
                labelText="Password"
                id="password_digest"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (e) => formik.handleChange(e),
                  name: "password_digest",
                  type: "password",
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                status
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.status,
                    onChange: (e) => formik.handleChange(e),
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {["Active", "Inactive"].map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.toLowerCase()}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["status"]}
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                Role
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.role,
                    onChange: (e) => formik.handleChange(e),
                    name: "role",
                    id: "role",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {["admin", "user"].map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item === "admin" ? <>Admin</> : <>User</>}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["role"]}
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText label="Company" name="company" value={formik.values.company} onChange={formik.handleChange} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label="Job Title"
                name="job_title"
                value={formik.values.job_title}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}></GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Button color="info" fullWidth onClick={formik.handleSubmit}>
                {props?.submitText ? <>{props?.submitText}</> : <>Add</>}
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}></GridItem>
          </GridContainer>
        </Box>
      </form>
    </React.Fragment>
  );
}
