import React from "react";
import styled from "styled-components";

const StyledCalendarMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0.25rem;
    padding: 0.25rem 0.5rem;
    width: auto;

    &:hover {
    }
  }

  span {
    display: inline-block;
    height: 10px;
    width: 10px;
  }

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    color: black;
    padding: 0;
    font-weight: bold;
  }
`;

export function CalendarMenu(props) {
  return (
    <StyledCalendarMenu>
      {props.menu.map((item, index) => {
        return (
          <li key={`cal-menu-item${index}`}>
            <span className={item.color}></span>
            &nbsp;&nbsp;
            {item.title}
          </li>
        );
      })}
    </StyledCalendarMenu>
  );
}
