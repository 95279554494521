import React from "react";
import PATHS from "config/paths";

export function useGetContact(token, id) {
  const [contact, setContact] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (data) => {
    if (data) {
      setContact(data);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if (token && id && !contact) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiContacts}/${id}`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => console.warn("get contact profile error", error));
    }
  }, [token, id, contact]);

  return { contactGet: contact, contactError: error };
}
