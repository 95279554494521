import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { usePostContactEmail } from "hooks";
import { Danger, InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import Box from "@material-ui/core/Box";


export function FormContactEmail(props) {
    const State = React.useContext(StateContext);
    const [token] = React.useState(State?.user?.value?.token);
    const [requestBody, setRequestBody] = React.useState();
    const { response, error, isLoading } = usePostContactEmail(token, requestBody, props.contact_id);

    const boxSettings = { mb: 6 };

    const formik = useFormik({
        initialValues: {
          contact_id: props.contact_id,
          id: props.email?.id,
          email_type: props.email?.email_type ? props.email.email_type : "",
          email: props.email?.email ? props.email.email : "",
        },
        validate: (values) => {
          const errors = {};
          if (!values.email) errors.email = "Required";
    
          return errors;
        },
        onSubmit: (values) => {
          const body = {
            id: values.id,
            email_type: values.email_type,
            email: values.email,
          };
          setRequestBody(body)
        },
        enableReinitialize: true,
      });

    const HelperText = {
      name: formik.errors.email && formik.submitCount ? <Danger>{formik.errors.email}</Danger> : null,
      role: formik.errors.email_type && formik.submitCount ? <Danger>{formik.errors.email_type}</Danger> : null,
    }

    React.useEffect(() => {
      if (response) {
        props.setContact(response);
      }
    }, [response]);

    return (
        <React.Fragment>
            <>
              <form autoComplete="off">
                <Card>
                  <CardBody>
                    <h3>{props?.name}</h3>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["email_type"]}
                            label="Email Type"
                            name="email_type"
                            value={formik.values.email_type}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["email"]}
                            label="Email Address"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                      </GridContainer>
                    </Box>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button color="info" fullWidth onClick={formik.handleSubmit}>
                            Submit
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                    </Box>
                  </CardBody>
                </Card>
              </form>
            </>
        </React.Fragment>
    );
}
