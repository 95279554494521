import styled from "styled-components";

export const StyledLoginPage = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  form {
    width: 400px;
  }
`;
