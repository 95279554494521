import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useDeleteContactNote(token, contact_id, requestNote, isConfirmed) {
  const [note, setNote] = React.useState();
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Compare requestBody to note so we don't infinite loop
   */
  React.useEffect(() => {
    if (!lodash.isEqual(requestNote, note) && isConfirmed) {
      setError(undefined);
      setResponse(undefined);
      setNote(requestNote);
      setIsLoading(true);
    }
  }, [requestNote, setNote, isConfirmed]);

  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && note ) {
      const options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };

      const path = note.id ? `${PATHS.apiContacts}/${contact_id}/note/${note.id}` : `${PATHS.apiContacts}/${contact_id}/note`;

      async function postRequest() {
        const request = await fetch(path, options);
        return true;
      }

      // Delete returns 204 No Content so it does not have a body, setResponse to true
      postRequest()
        .then(async (response) => {      
           setResponse(response)
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }, [token, note, setResponse]);

  return { noteDeleteSuccess: response, noteDeleteError: error, noteIsLoading: isLoading };
}
