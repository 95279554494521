import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostConnection(token, type, id, associateType, associateId, relationship, reverseRelationship) {
  const [connection, setConnection] = React.useState();
  const [error, setError] = React.useState();

  React.useEffect(() => {
    if (!connection && token && type && id && associateType && associateId && relationship && reverseRelationship) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "POST",
        headers: headers,
        redirect: "follow"
      };

      /**
       * Setup connection
       */
      const apiPath = type === "survivor" ? PATHS.apiSurvivors : PATHS.apiVictims;
      const associatePath = `${apiPath}/${id}/associate_${associateType}/${associateId}/${relationship}?reverse_relation=${reverseRelationship}`;

      /**
       * Make request
       */
      fetch(associatePath, options)
        .then((response) => {
          if (response.status === 204 || response.status === 201) {
            setConnection(true);
          } else {
            setError("failed");
          }
        })
        .catch((error) => setError(error));
    }
  }, [token, type, id, associateType, associateId, relationship, reverseRelationship]);

  return { connectionResponse: connection, connectionError: error };
}
