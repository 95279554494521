import React from "react";
import PATHS from "config/paths";
import AdminLayout from "layouts/Admin.js";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { usePostVictims } from "hooks";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { FormVictim } from "components";

// Creative Tim
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

// Icons
import Add from "@material-ui/icons/Add";

/* eslint-disable react-hooks/exhaustive-deps */

function Page() {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [requestBody, setRequestBody] = React.useState();
  const { response, victimsError } = usePostVictims(token, requestBody);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      // Victim details
      first_name: "",
      last_name: "",
      full_name: "",
      preferred_name: "",
      date_of_birth: "",
      date_of_death: "",
      cause_of_death: "",
      gender: "",
      age: "",
      race: "",
      city_of_death: "",
      state_of_death: "",
      special_categories: [],

      // Case details
      status: "Active",
      case_number: "",
      law_enforcement_agency: "",
      reported_date: "",
      submitted_date: "",
      how_submitted: "",
      submitted_by_law_enforcement: false,
      met_media: "",
      social_media_post_date: "",
      written_spotlight_date: "",
      fb_live_feature_date: "",
      on_tv_date: "",
      news_outlet: "",
      user_id: State?.user?.value?.id,

      // incident details
      address1: "",
      address2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      county: "",
      offense: "",
      description: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.first_name) {
        errors.first_name = "Required";
      }
      if (!values.last_name) {
        errors.last_name = "Required";
      }
      if (!values.cause_of_death) {
        errors.cause_of_death = "Required";
      }

      if (values.date_of_birth && moment(values.date_of_birth).isSameOrAfter(moment())) {
        errors.date_of_birth = "DOB can not be in the future.";
      }

      if (values.date_of_death && moment(values.date_of_death).isSameOrAfter(moment())) {
        errors.date_of_death = "Date of Death can not be in the future.";
      }

      if(values.age != "" && values.age < 0 ) {
        errors.age = "Age must not be negative."
      }

      return errors;
    },
    onSubmit: (values) => {
      setRequestBody({
        victim: {
          case: {
            case_number: values.case_number,
            status: values.status,
            law_enforcement_agency: values.law_enforcement_agency,
            reported_date: values.reported_date,
            submitted_date: values.submitted_date,
            how_submitted: values.how_submitted,
            submitted_by_law_enforcement: values.submitted_by_law_enforcement,
            met_media: values.met_media,
            social_media_post_date: values.social_media_post_date,
            written_spotlight_date: values.written_spotlight_date,
            fb_live_feature_date: values.fb_live_feature_date,
            on_tv_date: values.on_tv_date,
            news_outlet: values.news_outlet,            
          },
          incident: {
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
            country: values.country,
            county: values.county,
            offense: values.county,
            description: values.description,
          },
          first_name: values.first_name,
          last_name: values.last_name,
          full_name: values.full_name,
          preferred_name: values.preferred_name,
          cause_of_death: values.cause_of_death,
          date_of_birth: values.date_of_birth,
          date_of_death: values.date_of_death,
          age: values.age,
          race: values.race,
          gender: values.gender,
          city_of_death: values.city_of_death,
          state_of_death: values.state_of_death,
        },
      });
      setIsLoading(true);
    },
  });

  /**
   * Handle error
   */
  React.useEffect(() => {
    if (victimsError) {
      setIsLoading(false);
      setError(true);
    }
  }, [victimsError]);

  /**
   * Handle new vicitim success
   */
  React.useEffect(() => {
    if (response && response.id) {
      history.push(`${PATHS.victimProfile}/${response.id}`);
    }
  }, [response]);

  return (
    <div id="page-vic-new">
      {victimsError && (
        <SnackbarContent
          color="info"
          message={t("error.general")}
          open={error}
          onClick={() => {
            setError(false);
          }}
          close
        />
      )}

      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          {t("victim.new")}
        </Typography>
      </Box>

      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Add />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {isLoading && (
            <Box textAlign="center" pb={5}>
              <p>{t("wait")}</p>
              <CircularProgress />
            </Box>
          )}

          {!isLoading && (
            <React.Fragment>
              <FormVictim formik={formik} />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default function PageVictimsNew() {
  return <AdminLayout component={<Page />} />;
}
