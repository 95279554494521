import React from "react";
import AdminLayout from "layouts/Admin.js";
import { StateContext } from "state.js";
import { useGetContact, useDeleteContactEmail, useDeleteContactPhone, useDeleteContactNote } from "hooks";
import { FormContact, FormContactEmail, FormContactPhone, FormContactNote } from "components";
import { useParams } from "react-router-dom";
import { formatDateTime } from "utilities";


// Layout
import Grid from '@material-ui/core/Grid';
// Grid
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
// Box
import Box from "@material-ui/core/Box";

// Card
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";

// Lists
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

// Icons
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import NoteIcon from '@material-ui/icons/Note';

// Bread crumbs
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
// Progress
import CircularProgress from "@material-ui/core/CircularProgress";

// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Button from "components-material-ui/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function ContactViewPage() {
  let { id } = useParams();

  const State = React.useContext(StateContext);
  const refWrapper = React.useRef();
  const [token] = React.useState(State?.user?.value?.token);
  const [contact, setContact] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [view, setView] = React.useState("loading");
  const [modal, setEditContactModal] = React.useState(false);
  
  // Global editing
  const [isDeleteConfirmed, setIsDeleteConfirmed] = React.useState(false);
  // Phone Editing
  const [phoneModal, setEditPhoneModal] = React.useState(false);
  const [selectedPhone, setSelectedPhone] = React.useState(false);
  const [deletePhone, setDeletePhone] = React.useState(false);
  const { phoneDeleteSuccess, phoneDeleteError, phoneIsLoading } = useDeleteContactPhone(token, id, deletePhone, isDeleteConfirmed);

  // Note Editing
  const [noteModal, setEditNoteModal] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(false);
  const [deleteNote, setDeleteNote] = React.useState(false);
  const { noteDeleteSuccess, noteDeleteError, noteIsLoading } = useDeleteContactNote(token, id, deleteNote, isDeleteConfirmed);
  
  // Email Editing
  const [emailModal, setEditEmailModal] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState(false);
  const [deleteEmail, setDeleteEmail] = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const { emailDeleteSuccess, emailDeleteError, emailIsLoading } = useDeleteContactEmail(token, id, deleteEmail, isDeleteConfirmed);

  const { contactGet, contactError } = useGetContact(token, id);

  React.useEffect(() => {
    if (contactGet) {
      setView("profile");
      setContact(contactGet);
    }
  }, [contactGet]);

  React.useEffect(() => {
    if (contact) setView("profile");
  }, [contact]);

  var styles = {
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  // Contact Editing
  const handleEditContactClick = () => {
    setEditContactModal(true);
  }

  const setContactInfo = (response) => {
    contact.name = response.name;
    contact.role = response.role;
    contact.organization = response.organization;
    setContact(contact);
    setEditContactModal(false);
  }

  // Email Editing Methods
  const handleEditEmailClick = (email) => {
    console.log(email);
    setSelectedEmail(email);
    setEditEmailModal(true);
  }

  const handleAddEmailClick = () => {
    setSelectedEmail(null);
    setEditEmailModal(true);
  }

  const handleDeleteEmailClick = (email) => {
    console.log(email);
    setDeleteEmail(email);
    setDeleteConfirmModal(true);
  }

  const handleDeletedEmail = () => {
    console.log("Handle DeleteEmail");

    const objIndex = contact.emails.findIndex((obj => obj.id == deleteEmail.id));
    if (objIndex !== -1){
      contact.emails.splice(objIndex, 1);
    }
    setContact(contact);
    setEditEmailModal(false);
    setDeleteConfirmModal(false);
    setDeleteEmail(false);
    setIsDeleteConfirmed(false);
  }

  const setEmails = (response) => {
    console.log(response);
    const objIndex = contact.emails.findIndex((obj => obj.id == response.id));
    if (objIndex === -1){
      contact.emails.push(response);
    }else{
      contact.emails[objIndex] = response;
    }
    setContact(contact);
    setEditEmailModal(false);
  }

  React.useEffect(() => {
    if (deleteEmail && isDeleteConfirmed && emailDeleteSuccess) handleDeletedEmail();
  }, [deleteEmail, isDeleteConfirmed, emailDeleteSuccess]);

  // Phone Editing Methods
  const handleEditPhoneClick = (phone) => {
    console.log(phone);
    setSelectedPhone(phone);
    setEditPhoneModal(true);
  }

  const handleAddPhoneClick = () => {
    setSelectedPhone(null);
    setEditPhoneModal(true);
  }

  const handleDeletePhoneClick = (phone) => {
    console.log(phone);
    setDeletePhone(phone);
    setDeleteConfirmModal(true);
  }

  const handleDeletedPhone = () => {
    console.log("Handle DeletePhone");

    const objIndex = contact.phone_numbers.findIndex((obj => obj.id == deletePhone.id));
    if (objIndex !== -1){
      contact.phone_numbers.splice(objIndex, 1);
    }
    setContact(contact);
    setEditPhoneModal(false);
    setDeleteConfirmModal(false);
    setDeletePhone(false);
    setIsDeleteConfirmed(false);
  } 

  const setPhones = (response) => {
    console.log(response);
    const objIndex = contact.phone_numbers.findIndex((obj => obj.id == response.id));
    if (objIndex === -1){
      contact.phone_numbers.push(response);
    }else{
      contact.phone_numbers[objIndex] = response;
    }
    setContact(contact);
    setEditPhoneModal(false);
  }

  React.useEffect(() => {
    if (deletePhone && isDeleteConfirmed && phoneDeleteSuccess) handleDeletedPhone();
  }, [deletePhone, isDeleteConfirmed, phoneDeleteSuccess]);


  // Note Editing Methods
  const handleEditNoteClick = (note) => {
    console.log(note);
    setSelectedNote(note);
    setEditNoteModal(true);
  }

  const handleAddNoteClick = () => {
    setSelectedNote(null);
    setEditNoteModal(true);
  }

  const handleDeleteNoteClick = (note) => {
    console.log(note);
    setDeleteNote(note);
    setDeleteConfirmModal(true);
  }

  const handleDeletedNote = () => {
    console.log("Handle DeleteNote");

    const objIndex = contact.contact_notes.findIndex((obj => obj.id == deleteNote.id));
    if (objIndex !== -1){
      contact.contact_notes.splice(objIndex, 1);
    }
    setContact(contact);
    setEditNoteModal(false);
    setDeleteConfirmModal(false);
    setDeleteNote(false);
    setIsDeleteConfirmed(false);
  } 

  const setNotes = (response) => {
    console.log(contact.contact_notes);
    console.log(response);
    const objIndex = contact.contact_notes.findIndex((obj => obj.id == response.id));
    if (objIndex === -1){
      contact.contact_notes.push(response);
    }else{
      contact.contact_notes[objIndex] = response;
    }
    setContact(contact);
    setEditNoteModal(false);
    setSelectedNote(false);
  }

  React.useEffect(() => {
    if (deleteNote && isDeleteConfirmed && noteDeleteSuccess) handleDeletedNote();
  }, [deleteNote, isDeleteConfirmed, noteDeleteSuccess]);

  // Handle errors
  //React.useEffect(() => setError(true), [contactsError]);

  return (
    <div ref={refWrapper}>
      <h1>Contact Profile</h1>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/" >
          Dashboard
        </Link>
        <Link color="inherit" href="/contacts/browse">
          Contacts
        </Link>
        <Typography color="textPrimary">Contact Profile</Typography>
      </Breadcrumbs>
      {view === "loading" && (
        <Box textAlign="center" pb={5}>
          <p>Please wait</p>
          <CircularProgress />
        </Box>
      )}

      {contactError && (
        <Box textAlign="center" py={5}>
          <p>Could not find this contact</p>
        </Box>
      )}

      {contact && view === "profile" && (
        <>
        <Box py={5}>
           <GridContainer>
            <GridItem xs={12} >
              <Card>
                <CardHeader color="primary">
                <GridContainer spacing={5}>
                <Grid item xs >
                    <Typography gutterBottom variant="subtitle1">
                      {contact.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {contact.role}
                    </Typography>
                    <Typography variant="body2">
                      {contact.organization}
                    </Typography>
                </Grid>
                <Grid item px={5}>
                  <IconButton edge="end" aria-label="edit" onClick={handleEditContactClick}>
                    <Edit />
                  </IconButton>
                </Grid>
                </GridContainer> 
                    
                </CardHeader>
                <CardBody>
                <GridContainer>
                  <GridItem xs={6} >
                    <h4>Email <IconButton edge="end" aria-label="add" onClick={handleAddEmailClick}>
                            <Add />
                          </IconButton></h4>
                    <List className={classes.root}>
                      {contact.emails.map(email => (
                        <ListItem key={email.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <EmailIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={email.email_type} secondary={email.email} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEditEmailClick(email);
                            }
                          }>
                            <Edit />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteEmailClick(email);
                            }
                          }>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      ))}  
                    </List> 
                  </GridItem>
                  <GridItem xs={6} >
                    <h4>Phone <IconButton edge="end" aria-label="add" onClick={handleAddPhoneClick}>
                            <Add />
                          </IconButton></h4>
                    <List className={classes.root}>
                      {contact.phone_numbers.map(phone => (
                        <ListItem key={phone.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <PhoneIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={phone.phone_type} secondary={phone.phone_number} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEditPhoneClick(phone);
                            }
                          }>
                            <Edit />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeletePhoneClick(phone);
                            }
                          }>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      ))}  
                    </List>   
                  </GridItem>
                  <GridItem xs={12} >
                    <h4>Notes <IconButton edge="end" aria-label="add" onClick={handleAddNoteClick}>
                            <Add />
                          </IconButton></h4>
                    <List className={classes.root}>
                      {contact.contact_notes.map(note => (
                        <ListItem key={note.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <NoteIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText 
                          primary={formatDateTime(note.updated_at) + ' - ' + note.user.first_name + ' ' + note.user.last_name} 
                          secondary={note.note} style={{"white-space": "pre-wrap"}}
                          
                          />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEditNoteClick(note);
                            }
                          }>
                            <Edit />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteNoteClick(note);
                            }
                          }>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      ))}  
                    </List> 
                  </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          
        </Box>

        {/** Contact Edit Modal */}
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modal}
          transition={Transition}
          keepMounted
          onClose={() => setEditContactModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditContactModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Edit Contact</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <FormContact id={contact.id} name={contact.name} role={contact.role} organization={contact.organization} setContact={setContactInfo} />
          </DialogContent>
        </Dialog>
        {/** Email Edit Modal */}
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={emailModal}
          transition={Transition}
          keepMounted
          onClose={() => setEditEmailModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditEmailModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{selectedEmail ? "Edit" : "New" } Email</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <FormContactEmail contact_id={contact.id} email={selectedEmail}   setContact={setEmails} />
          </DialogContent>
        </Dialog>

        {/** Phone Edit Modal */}
        <Dialog 
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={phoneModal}
          transition={Transition}
          keepMounted
          onClose={() => setEditPhoneModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditPhoneModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{selectedPhone ? "Edit" : "New" } Phone</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <FormContactPhone contact_id={contact.id} phone={selectedPhone}   setContact={setPhones} />
          </DialogContent>
        </Dialog>

        {/** Note Edit Modal */}
        <Dialog 
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={noteModal}
          transition={Transition}
          keepMounted
          onClose={() => setEditNoteModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setEditNoteModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{selectedNote ? "Edit" : "New" } Note</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <FormContactNote contact_id={contact.id} note={selectedNote}   setContact={setNotes} />
          </DialogContent>
        </Dialog>

        {/** Delete Confirm Dialog */}
        <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={deleteConfirmModal}
        transition={Transition}
        keepMounted
        onClose={() => setDeleteConfirmModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={ () => setDeleteConfirmModal(false) }
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Confirm Delete</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <h5>Are you sure you want to do this?</h5>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setDeleteConfirmModal(false)}>Never Mind</Button>
          <Button onClick={() => setIsDeleteConfirmed(true) } color="success">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </>
      )}

    </div>
  );
}

export default function PageContactsView() {
    return <AdminLayout component={<ContactViewPage />} />;
  }
