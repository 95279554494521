import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetVictimPhoto(token, id, refresh = false) {
  const [photo, setPhoto] = React.useState();

  const handleResponse = (data) => {
    if (data) setPhoto(data);
  };

  React.useEffect(() => {
    if (token && id && (!photo || refresh)) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);

      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      // Make request
      fetch(`${PATHS.apiVictims}/${id}/photo`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => {});
    }
  }, [token, id, refresh]);

  return { photo };
}
