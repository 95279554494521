import React from "react";
import { StateContext } from "state.js";
import moment from "moment";
import { formatDate } from "utilities";
import { usePostMinimumCriterium } from "hooks";
import { useSnackbar } from 'notistack';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import { ActiveUserDropdown } from "components";

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export function MinimumCriteriaForm (props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();


  const [minimumCriteria, setMinimumCriteria] = React.useState(props.minimumCriteria);

  const [requestBody, setRequestBody] = React.useState();

  const { minimumCriteriaResponse, minimumCriteriaError } = usePostMinimumCriterium(token, requestBody, props.caseSubmissionId);

  React.useEffect(() => {
    if (minimumCriteriaResponse){
      console.log("minimumCriteriaResponse", minimumCriteriaResponse);
      props.refresh(minimumCriteriaResponse);
      enqueueSnackbar('Initial criterium saved', { 
        variant: 'success',
      });
    }
  }, [minimumCriteriaResponse]);

  React.useEffect(() => {
    if (minimumCriteriaError){
      enqueueSnackbar('Initial criterium error',
      { 
        variant: 'error',
      });
    }
  }, [minimumCriteriaError]);


  React.useEffect(() => {
    if (props.incidentDate) {
      const targetDate = moment(props.incidentDate);
      const days = moment().diff(targetDate, 'days');
      setMinimumCriteria({ ...minimumCriteria, ["is_year_since_incident"]: (days > 365) });
    }
  }, [props.incidentDate])

  const handleChange = (event) => {
    setMinimumCriteria({ ...minimumCriteria, [event.target.name]: event.target.checked });
  };

  const handleRelationVerifiedByChange = (id) => {
    console.log(id);
    setMinimumCriteria({ ...minimumCriteria, ["relation_verified_date"]: moment().toString(), ["relation_verified_by"]: id });

  }

  const handleRelationNoteChange = (evt) => {
    const val = evt.target.value;
    console.log(val, evt)
    setMinimumCriteria({ ...minimumCriteria, ["relation_verified_note"]: val });
  }

  const handleUpdate = () => {
    if(props.disabled) {
      return
    } else {
      setRequestBody({
        is_case_open: minimumCriteria.is_case_open,
        is_case_type_valid: minimumCriteria.is_case_type_valid,
        is_relation_verified: minimumCriteria.is_relation_verified,
        is_year_since_incident: minimumCriteria.is_year_since_incident,
        relation_verified_by: minimumCriteria.relation_verified_by,
        relation_verified_date: minimumCriteria.relation_verified_date,
        relation_verified_note: minimumCriteria.relation_verified_note,
      });
    }
  }

  return (
    <>
    {minimumCriteria && (
      <GridContainer className={classes.root} >
        <GridItem xs={12} sm={8}>
          <h5>Initial Criteria</h5>
        </GridItem>        
        <GridItem xs={12} sm={4}>
          <Button variant="contained" color="primary" onClick={handleUpdate} fullWidth={false} disabled={props.disabled}>
            Update Initial Criteria
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} >
          <FormControlLabel
            control={
              <Checkbox
                checked={minimumCriteria?.is_year_since_incident || false}
                //onChange={handleChange}
                name="is_year_since_incident"
                color="primary"
              />
            }
            label={`Year Since Incident? (${formatDate(props?.incidentDate)})`}
          />
        </GridItem>
        <GridItem xs={12} sm={12} >
          <div>Must be 1 year since incident (Automatically verified)</div>
        </GridItem>
        <GridItem xs={12} sm={12} >
          <FormControlLabel
            control={
              <Checkbox
                checked={minimumCriteria?.is_case_type_valid || false}
                onChange={handleChange}
                name="is_case_type_valid"
                color="primary"
              />
            }
            label="Validated Case Type"
          />
        </GridItem>
        <GridItem xs={12} sm={12} >
          <div>Case must be homicide/missing person with foul play suspected</div>
        </GridItem>
        <GridItem xs={12} sm={12} >
          <FormControlLabel
            control={
              <Checkbox
                checked={minimumCriteria?.is_case_open || false}
                onChange={handleChange}
                name="is_case_open"
                color="primary"
              />
            }
            label="Case Not Closed"
          />
        </GridItem>
        <GridItem xs={12} sm={12} >
          <div>
            <p>Make sure case isn't closed and family just didn't like ruling</p>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} >
          <FormControlLabel
            control={
              <Checkbox
                checked={minimumCriteria?.is_relation_verified}
                onChange={handleChange}
                name="is_relation_verified"
                color="primary"
              />
            }
            label="Relation Verifed"
          />
          {minimumCriteria?.is_relation_verified && (
            <>
              <ActiveUserDropdown
                label="Validated By"
                onChange={(val) => {
                  handleRelationVerifiedByChange(val);
                }}
                value={minimumCriteria?.relation_verified_by || ''}
              />
              <div>Verified On: {minimumCriteria.relation_verified_date}</div>
              <TextField
                id="relation_verified_note"
                label="Notes"
                multiline
                rows={4}
                defaultValue={minimumCriteria.relation_verified_note}
                onChange={(val) => handleRelationNoteChange(val)}
              />
            </>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} >
          <div>
            Must be submitted by family member
          </div>
        </GridItem>
      </GridContainer>
              )}
    </>
  )
}
