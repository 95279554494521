import React from "react";
import PATHS from "config/paths";

export function useGetTask(token, id) {
  const [task, setTask] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (data) => {
    if (data) {
      setTask(data);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if (token && id && !task) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiTasks}/${id}`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => console.warn("get task profile error", error));
    }
  }, [token, id, task]);

  return { taskGet: task, taskError: error };
}
