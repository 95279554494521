import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { usePostContactPhone } from "hooks";
import { Danger, InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import Box from "@material-ui/core/Box";


export function FormContactPhone(props) {
    const State = React.useContext(StateContext);
    const [token] = React.useState(State?.user?.value?.token);
    const [requestBody, setRequestBody] = React.useState();
    const { response, error, isLoading } = usePostContactPhone(token, requestBody, props.contact_id);

    const boxSettings = { mb: 6 };

    const formik = useFormik({
        initialValues: {
          contact_id: props.contact_id,
          id: props.phone?.id ,
          phone_type: props.phone?.phone_type ? props.phone.phone_type : "",
          phone_number: props.phone?.phone_number ? props.phone.phone_number : "",
        },
        validate: (values) => {
          const errors = {};
          if (!values.phone_number) errors.phone_number = "Required";
    
          return errors;
        },
        onSubmit: (values) => {
          const body = {
            id: values.id,
            phone_type: values.phone_type,
            phone_number: values.phone_number,
          };
          setRequestBody(body)
        },
        enableReinitialize: true,
      });

    const HelperText = {
      name: formik.errors.phone_number && formik.submitCount ? <Danger>{formik.errors.phone_number}</Danger> : null,
      role: formik.errors.phone_type && formik.submitCount ? <Danger>{formik.errors.phone_type}</Danger> : null,
    }

    React.useEffect(() => {
      if (response) {
        props.setContact(response);
      }
    }, [response]);

    return (
        <React.Fragment>
            <>
              <form autoComplete="off">
                <Card>
                  <CardBody>
                    <h3>{props?.name}</h3>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["phone_type"]}
                            label="Phone Type"
                            name="phone_type"
                            value={formik.values.phone_type}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <InputText
                            helperText={HelperText["phone"]}
                            label="Phone Number"
                            name="phone_number"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                          />
                        </GridItem>
                      </GridContainer>
                    </Box>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button color="info" fullWidth onClick={formik.handleSubmit}>
                            Submit
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                    </Box>
                  </CardBody>
                </Card>
              </form>
            </>
        </React.Fragment>
    );
}
