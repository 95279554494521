import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetCalendarEvents(token, month) {
  const [events, setEvents] = React.useState();
  const [error, setError] = React.useState();
  const [usedMonths, setUsedMonths] = React.useState([]);

  const handleResponse = (data) => {
    if (data) {
      setEvents(data);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if (token && month && !usedMonths.includes(month)) {
      // Cache Months
      const newMonths = [...usedMonths];
      newMonths.push(month);
      setUsedMonths(newMonths);

      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiCalendarEvents}/${month}`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => setError(error));
    } else if (!month) {
      setError("no-month-provided");
    }
  }, [token, month]);

  return { getCalendarEvents: events, getCalendarEventsError: error };
}
