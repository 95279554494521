import React from "react";
import PATHS from "config/paths.js";
import { StateContext } from "state.js";

// Creative Tim
import Button from "components-material-ui/CustomButtons/Button.js";

/* eslint-disable react-hooks/exhaustive-deps */

export function DownloadDocument(props) {
  const State = React.useContext(StateContext);
  const { id } = props;
  const [token] = React.useState(State?.user?.value?.token);
  const [isLoading, setIsLoading] = React.useState(false);
  const [label, setLabel] = React.useState("Get Document");
  const [document, setDocument] = React.useState();

  const getDoc = () => {
    setIsLoading(true);
    setLabel("Please Wait");

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Origin", "{location.protocol + '//' + location.host}");
    const options = {
      method: "GET",
      headers: headers,
      redirect: "follow",
      cache: "no-cache",
    };

    fetch(`${PATHS.apiDocuments}/${id}`, options)
      .then((response) => response.text())
      .then((result) => setDocument(JSON.parse(result)))
      .catch(() => setLabel("Error"));
  };

  React.useEffect(() => {
    setLabel("Get Document");
    setIsLoading(false);
    if (document) window.open(document.url, "_blank");
  }, [document]);

  if (!id) return <></>;

  return (
    <Button size="sm" color={isLoading ? undefined : "info"} onClick={getDoc}>
      {label}
    </Button>
  );
}
