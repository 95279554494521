import React from "react";
import AdminLayout from "layouts/Admin.js";
import PATHS from "config/paths";
import moment from "moment";
import lodash from "lodash";

import { useTranslation } from "react-i18next";
import { lighten, makeStyles } from '@material-ui/core/styles';

import { setSurvivorSearchCache, getSurvivorSearchCache } from "utilities/survivor-search-cache";

import { formatDate, readableEnum } from "utilities";
import { gender, race } from "config/enums";

import { useGetSurvivors, useGetSurvivorsCsv } from "hooks";
import { useHistory } from "react-router-dom";
import { yes_no, state, alert_status, how_submitted, special_categories } from "config/enums";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";

// Icons
import Add from "@material-ui/icons/Add";
import { get, search } from "superagent";

/* eslint-disable react-hooks/exhaustive-deps */

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

function Page () {
  const { t, i18n } = useTranslation();
  const classes = useToolbarStyles();

  const history = useHistory();
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const boxSettings = {};
  const [view, setView] = React.useState("search");
  const [page, setPage] = React.useState(1);
  const [orderDir, setOrderDir] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [sort, setSort] = React.useState();

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSort(`sorted_by=${property}_${orderDir}`);
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  const [error, setError] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState();
  const { survivors, survivorsError, survivorsPageCount, survivorsIsLoading } = useGetSurvivors(
    token,
    page,
    searchParams,
    true,
    true,
    sort
  );
  const [shouldDownload, setShouldDownload] = React.useState(false);
  const { isComplete, downloadError, isDownloading } = useGetSurvivorsCsv(token, searchParams, shouldDownload);

  /*
    <TableCell>{t("firstname")}</TableCell>
    <TableCell>{t("lastname")}</TableCell>
    <TableCell>{t("survivor.victim")}</TableCell>
    <TableCell>{t("survivor.victimrelation")}</TableCell>
    <TableCell>{t("city")}</TableCell>
    <TableCell>{t("state")}</TableCell>
    <TableCell>{t("survivor.touchpoint.last")}</TableCell>
    <TableCell>{t("survivor.touchpoint.dayssince")}</TableCell>
  **/

  const headCells = [
    { id: 'first_name', numeric: false, disablePadding: true, label: t("firstname") },
    { id: 'last_name', numeric: false, disablePadding: true, label: t("lastname") },
    { id: 'victim', numeric: false, disablePadding: false, label: t('survivor.victim') },
    { id: 'relation_to_victim', numeric: false, disablePadding: false, label: 'Relationship' },
    { id: 'city', numeric: false, disablePadding: false, label: t('city') },
    { id: 'state', numeric: false, disablePadding: false, label: t('state') },
    { id: 'last_touchpoint', numeric: false, disablePadding: false, label: t('survivor.touchpoint.last') },
    { id: 'days_since', numeric: false, disablePadding: false, label: t("survivor.touchpoint.dayssince") },
  ];

  const MONTHS = [
    ["January", 1],
    ["February", 2],
    ["March", 3],
    ["April", 4],
    ["May", 5],
    ["June", 6],
    ["July", 7],
    ["August", 8],
    ["September", 9],
    ["October", 10],
    ["November", 11],
    ["December", 12],
  ];

  const defaultSearch = {
    by_first_name: "",
    by_last_name: "",
    by_any_name: "",
    by_phone: "",
    by_email: "",
    by_alert_status: "",
    by_special_category: "",
    by_address1: "",
    by_address2: "",
    by_state: "",
    by_country: "",
    by_county: "",
    by_postal_code: "",
    by_opt_in_news: "",
    by_events_interest: "",
    by_le_connection: "",
    by_how_learned_pcc: "",
    by_other_non_profit: "",
    by_other_victim_services: "",
    by_created_before: "",
    by_created_after: "",
    by_created_month: "",
    by_gender: "",
    by_race: "",
  };
  const [searchCache, setSearchCache] = React.useState(defaultSearch);
  React.useEffect(() => {
    if (view == "search") {
      let cache = getSurvivorSearchCache();
      cache = cache.data[State.user.value.id]?.val
      if (!lodash.isEmpty(cache) && !lodash.isEqual(cache, searchCache)) {
        setSearchCache({ ...defaultSearch, ...cache });
      }
    }
  }, [view]);

  const formik = useFormik({
    initialValues: { ...searchCache },
    enableReinitialize: true,
    onSubmit: (values) => {
      setSearchParams(undefined);
      setSurvivorSearchCache(State.user.value.id, { ...values });
      const keys = Object.keys(values);
      let count = 0;
      const params = keys
        .map((item) => {
          const seperator = count === 0 ? "" : "&";
          if (values[item]) {
            count++;
            return `${seperator}${item}=${values[item]}`;
          }
          return "";
        })
        .join("")
        .replaceAll(" ", "%20");

      if (params.length === 0) {
        setError("no-empty-searches");
        setDisable(false);
      } else {
        setError(undefined);
        setView("loading");
        setSearchParams(params);
      }
    },
  });

  const handleDownload = () => {
    setShouldDownload(true);
  }

  const hanldeReset = () => {
    setDisable(false);
    setSearchParams(undefined);
    setPage(1);
    formik.handleReset();
    setView("search");
  };

  const hanldeSubmit = () => {
    if (disable) return;
    setDisable(true);
    formik.handleSubmit();
  };

  // Show Error message
  React.useEffect(() => {
    if (survivorsError) {
      setView("search");
      setDisable(false);
    }
  }, [survivorsError]);

  // Show Error message
  React.useEffect(() => {
    if (survivors && survivors.length > 0) setView("results");
  }, [survivors]);

  React.useEffect(() => {
    if (isComplete) setShouldDownload(false);
  }, [isComplete]);

  const [open, setOpen] = React.useState();
  React.useEffect(() => {
    if (open) {
      if (open.a == "open") {
        history.push(`${PATHS.victimProfile}/${open.id}`);
      }
      if (open.a == "tab") {
        window.open(`${PATHS.victimProfile}/${open.id}`);
      }
      // todo get chrome to open new window
      if (open.a == "window") {
        const target = `_win${open.id}`;
        window.open(`${PATHS.victimProfile}/${open.id}`, target);
      }

    }
  }, [open]);

  return (
    <div id="page-survivors-search">
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          {t("survivor.searchtitle")}
        </Typography>
      </Box>

      {view === "loading" && (
        <Box textAlign="center" py={5}>
          <p>{t("wait")}</p>
          <CircularProgress />
        </Box>
      )}

      {view === "search" && (
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Add />
            </CardIcon>
          </CardHeader>
          <CardBody>
            {survivorsError && (
              <Box textAlign="center" py={5}>
                <p>{t("survivor.none")}</p>
              </Box>
            )}

            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                hanldeSubmit();
              }}
            >
              <input type="submit" style={{ display: "none" }} />
              <h3>{t("survivor.details")}</h3>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={t("firstname")}
                      name="by_first_name"
                      value={formik.values.by_first_name}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={t("lastname")}
                      name="by_last_name"
                      value={formik.values.by_last_name}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputText
                      label={"Any Name"}
                      name="by_any_name"
                      value={formik.values.by_any_name}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputText
                      label={t("phone")}
                      name="by_phone"
                      value={formik.values.by_phone}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputText
                      label={t("email")}
                      name="by_email"
                      value={formik.values.by_email}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      {t("gender")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_gender,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_gender",
                          id: "by_gender",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {gender.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      {t("race")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_race,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_race",
                          id: "by_race",
                        }}
                      >
                        <MenuItem disabled>Choose one</MenuItem>
                        {race.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      {t("alertstatus")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_alert_status,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_alert_status",
                          id: "by_alert_status",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {alert_status.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <label>
                      {t("specialcat")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_special_category,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_special_category",
                          id: "by_special_category",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {special_categories.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>

              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputText
                      label={t("address1")}
                      name="by_address1"
                      value={formik.values.by_address1}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputText
                      label={t("address2")}
                      name="by_address2"
                      value={formik.values.by_address2}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </Box>

              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputText
                      label={t("city")}
                      name="by_city"
                      value={formik.values.by_city}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label>
                      {t("state")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_state,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_state",
                          id: "by_state",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {state.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <InputText
                      label={t("zip")}
                      name="by_postal_code"
                      value={formik.values.by_postal_code}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputText
                      label={t("county")}
                      name="by_county"
                      value={formik.values.by_county}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </Box>

              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputText
                      label={t("country")}
                      name="by_country"
                      value={formik.values.by_country}
                      onChange={formik.handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </Box>

              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("optnews")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_opt_in_news,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_opt_in_news",
                          id: "by_opt_in_news",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {yes_no.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("eventinterest")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_events_interest,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_events_interest",
                          id: "by_events_interest",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {yes_no.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>

              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("lecon")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_le_connection,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_le_connection",
                          id: "by_le_connection",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {yes_no.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label>
                      {t("howlearned")}
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_how_learned_pcc,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_how_learned_pcc",
                          id: "by_how_learned_pcc",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {how_submitted.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {readableEnum(item)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>
              <Box {...boxSettings} mb={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <InputDate
                      value={formik.values.by_created_after}
                      label={"Created After"}
                      name="by_created_after"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <InputDate
                      value={formik.values.by_created_before}
                      label={"Created Before"}
                      name="by_created_before"
                      onChange={formik.setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <label>
                      Month Created
                      <Select
                        onChange={(e) => formik.handleChange(e)}
                        inputProps={{
                          value: formik.values.by_created_month,
                          onChange: (e) => formik.handleChange(e),
                          name: "by_created_month",
                          id: "by_created_month",
                        }}
                      >
                        <MenuItem>Choose one</MenuItem>
                        {MONTHS.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item[1]}>
                              {item[0]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </label>
                  </GridItem>
                </GridContainer>
              </Box>
              {error === "no-empty-searches" && (
                <Box textAlign="center" pb={5}>
                  {t("error.search.noempty")}
                </Box>
              )}

              <Box {...boxSettings}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <Button color={disable ? undefined : "info"} onClick={hanldeSubmit}>
                      {t("search")}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Box textAlign="right">
                      <Button onClick={() => setSearchCache(defaultSearch)}>{t("reset")}</Button>
                    </Box>
                  </GridItem>
                </GridContainer>
              </Box>
            </form>
          </CardBody>
        </Card>
      )}

      {view === "results" && (
        <React.Fragment>
          {survivors && survivors?.length > 0 && !survivorsIsLoading && (
            <React.Fragment>
              <Box textAlign="center" paddingBottom={5}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button size="sm" onClick={hanldeReset}>
                    {t("backtosearch")}
                  </Button>
                  <Button size="sm" onClick={handleDownload}>
                    {"Download CSV"}
                  </Button>
                </ButtonGroup>
              </Box>

              <TableContainer component={Paper}>
                <Table aria-label="simple table" className="hover">
                  <TableHead>
                    <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? 'right' : 'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={orderBy === headCell.id ? orderDir : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? orderDir : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>
                                {orderDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}            
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {survivors.map((survivor, index) => {
                      const dateObject =
                        survivor.touchpoints.length > 0 ? formatDate(survivor.touchpoints[0].date, true) : null;
                      const touchpointDate = dateObject ? dateObject.format("M-D-YYYY") : null;
                      const touchpointDaysSince = dateObject ? moment().diff(dateObject, "days") : null;
                      const firstName = survivor?.case?.victim?.first_name || "";
                      const lastName = survivor?.case?.victim?.last_name || "";

                      return (
                        <TableRow key={index} onClick={() => history.push(`${PATHS.survivorsProfile}/${survivor.id}`)}>
                          <TableCell>{survivor.first_name}</TableCell>
                          <TableCell>{survivor.last_name}</TableCell>
                          <TableCell>{`${firstName} ${lastName}`}</TableCell>
                          <TableCell>{readableEnum(survivor.relation_to_victim)}</TableCell>
                          <TableCell>{survivor.city}</TableCell>
                          <TableCell>{survivor.state}</TableCell>
                          <TableCell>{touchpointDate}</TableCell>
                          <TableCell>{touchpointDaysSince}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Open</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                label="Age"
                                onChange={(e) => { setOpen(e.target.value) }}
                              >
                                <MenuItem value={{ a: "open", id: survivor.id }}>Open</MenuItem>
                                <MenuItem value={{ a: "tab", id: survivor.id }}>Open in New Tab</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}

          {survivors && survivorsPageCount > 0 && (
            <Box textAlign="center" mt={5}>
              <Pagination
                disabled={survivorsIsLoading}
                page={page}
                count={survivorsPageCount}
                onChange={(e, p) => setPage(p)}
              />
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default function PageSurvivorSearch () {
  return <AdminLayout component={<Page />} />;
}
