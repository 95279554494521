export const cause_of_death = [
  "BLUNT_FORCE",
  "CHILD_ABUSE",
  "EXPLOSION",
  "FIRE_ARSON",
  "GUN_FIREARM",
  "KNIFE_STABBING",
  "LIGATURE_STRANGULATION",
  "POISON",
  "SUFFOCATION",
  "UNDETERMINED",
  "UNDISCLOSED",
  "UNKNOWN",
  "VEHICLE",
  "OTHER",
];
export const gender = ["MALE", "FEMALE", "OTHER", "TRANSGENDER", "UNDISCLOSED", "UNKNOWN"];

export const race = [
  "CAUCASIAN_WHITE",
  "AFRICAN_AMERICAN_BLACK",
  "AMERICAN_INDIAN_ALASKA_NATIVE",
  "ASIAN",
  "HISPANIC_LATINO",
  "PACIFIC_ISLANDER",
  "OTHER",
  "UNDISCLOSED",
  "UNKNOWN",
];

export const service_provided = [
  "INFORMATION AND REFERRAL",
  "PERSONAL ADVOCACY/ACOMPANIMENT",
  "EMOTIONAL SUPPORT",
  "CRIMINAL/CIVIL JUSTICE SYSTEM ASSISTANCE",
];

export const post_types = [
  "FB_POST",
  "LINKEDIN_POST",
  "TWITTER",
  "NEWS_ARTICLE",
  "MISC",
];

export const special_categories = ["DEAF", "VETERAN", "LGBTQ", "DISABLED"];

export const state = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const countries = [
  { k:"ASCENSION ISLAND", v:"AC" },
  { k:"AFGHANISTAN", v:"AF" },
  { k:"ALAND", v:"AX" },
  { k:"ALBANIA", v:"AL" },
  { k:"ALGERIA", v:"DZ" },
  { k:"ANDORRA", v:"AD" },
  { k:"ANGOLA", v:"AO" },
  { k:"ANGUILLA", v:"AI" },
  { k:"ANTARCTICA", v:"AQ" },
  { k:"ANTIGUA AND BARBUDA", v:"AG" },
  { k:"ARGENTINA REPUBLIC", v:"AR" },
  { k:"ARMENIA", v:"AM" },
  { k:"ARUBA", v:"AW" },
  { k:"AUSTRALIA", v:"AU" },
  { k:"AUSTRIA", v:"AT" },
  { k:"AZERBAIJAN", v:"AZ" },
  { k:"BAHAMAS", v:"BS" },
  { k:"BAHRAIN", v:"BH" },
  { k:"BANGLADESH", v:"BD" },
  { k:"BARBADOS", v:"BB" },
  { k:"BELARUS", v:"BY" },
  { k:"BELGIUM", v:"BE" },
  { k:"BELIZE", v:"BZ" },
  { k:"BENIN", v:"BJ" },
  { k:"BERMUDA", v:"BM" },
  { k:"BHUTAN", v:"BT" },
  { k:"BOLIVIA", v:"BO" },
  { k:"BOSNIA AND HERZEGOVINA", v:"BA" },
  { k:"BOTSWANA", v:"BW" },
  { k:"BOUVET ISLAND", v:"BV" },
  { k:"BRAZIL", v:"BR" },
  { k:"BRITISH INDIAN OCEAN TERR", v:"IO" },
  { k:"BRITISH VIRGIN ISLANDS", v:"VG" },
  { k:"BRUNEI DARUSSALAM", v:"BN" },
  { k:"BULGARIA", v:"BG" },
  { k:"BURKINA FASO", v:"BF" },
  { k:"BURUNDI", v:"BI" },
  { k:"CAMBODIA", v:"KH" },
  { k:"CAMEROON", v:"CM" },
  { k:"CANADA", v:"CA" },
  { k:"CAPE VERDE", v:"CV" },
  { k:"CAYMAN ISLANDS", v:"KY" },
  { k:"CENTRAL AFRICAN REPUBLIC", v:"CF" },
  { k:"CHAD", v:"TD" },
  { k:"CHILE", v:"CL" },
  { k:"PEOPLE’S REPUBLIC OF CHINA", v:"CN" },
  { k:"CHRISTMAS ISLANDS", v:"CX" },
  { k:"COCOS ISLANDS", v:"CC" },
  { k:"COLOMBIA", v:"CO" },
  { k:"COMORAS", v:"KM" },
  { k:"CONGO", v:"CG" },
  { k:"CONGO (DEMOCRATIC REPUBLIC)", v:"CD" },
  { k:"COOK ISLANDS", v:"CK" },
  { k:"COSTA RICA", v:"CR" },
  { k:"COTE D IVOIRE", v:"CI" },
  { k:"CROATIA", v:"HR" },
  { k:"CUBA", v:"CU" },
  { k:"CYPRUS", v:"CY" },
  { k:"CZECH REPUBLIC", v:"CZ" },
  { k:"DENMARK", v:"DK" },
  { k:"DJIBOUTI", v:"DJ" },
  { k:"DOMINICA", v:"DM" },
  { k:"DOMINICAN REPUBLIC", v:"DO" },
  { k:"EAST TIMOR", v:"TP" },
  { k:"ECUADOR", v:"EC" },
  { k:"EGYPT", v:"EG" },
  { k:"EL SALVADOR", v:"SV" },
  { k:"EQUATORIAL GUINEA", v:"GQ" },
  { k:"ESTONIA", v:"EE" },
  { k:"ETHIOPIA", v:"ET" },
  { k:"FALKLAND ISLANDS", v:"FK" },
  { k:"FAROE ISLANDS", v:"FO" },
  { k:"FIJI", v:"FJ" },
  { k:"FINLAND", v:"FI" },
  { k:"FRANCE", v:"FR" },
  { k:"FRANCE METROPOLITAN", v:"FX" },
  { k:"FRENCH GUIANA", v:"GF" },
  { k:"FRENCH POLYNESIA", v:"PF" },
  { k:"FRENCH SOUTHERN TERRITORIES", v:"TF" },
  { k:"GABON", v:"GA" },
  { k:"GAMBIA", v:"GM" },
  { k:"GEORGIA", v:"GE" },
  { k:"GERMANY", v:"DE" },
  { k:"GHANA", v:"GH" },
  { k:"GIBRALTAR", v:"GI" },
  { k:"GREECE", v:"GR" },
  { k:"GREENLAND", v:"GL" },
  { k:"GRENADA", v:"GD" },
  { k:"GUADELOUPE", v:"GP" },
  { k:"GUAM", v:"GU" },
  { k:"GUATEMALA", v:"GT" },
  { k:"GUINEA", v:"GN" },
  { k:"GUINEA-BISSAU", v:"GW" },
  { k:"GUYANA", v:"GY" },
  { k:"HAITI", v:"HT" },
  { k:"HEARD & MCDONALD ISLAND", v:"HM" },
  { k:"HONDURAS", v:"HN" },
  { k:"HONG KONG", v:"HK" },
  { k:"HUNGARY", v:"HU" },
  { k:"ICELAND", v:"IS" },
  { k:"INDIA", v:"IN" },
  { k:"INDONESIA", v:"ID" },
  { k:"IRAN, ISLAMIC REPUBLIC OF", v:"IR" },
  { k:"IRAQ", v:"IQ" },
  { k:"IRELAND", v:"IE" },
  { k:"ISLE OF MAN", v:"IM" },
  { k:"ISRAEL", v:"IL" },
  { k:"ITALY", v:"IT" },
  { k:"JAMAICA", v:"JM" },
  { k:"JAPAN", v:"JP" },
  { k:"JORDAN", v:"JO" },
  { k:"KAZAKHSTAN", v:"KZ" },
  { k:"KENYA", v:"KE" },
  { k:"KIRIBATI", v:"KI" },
  { k:"KOREA, DEM. PEOPLES REP OF", v:"KP" },
  { k:"KOREA, REPUBLIC OF", v:"KR" },
  { k:"KUWAIT", v:"KW" },
  { k:"KYRGYZSTAN", v:"KG" },
  { k:"LAO PEOPLE’S DEM. REPUBLIC", v:"LA" },
  { k:"LATVIA", v:"LV" },
  { k:"LEBANON", v:"LB" },
  { k:"LESOTHO", v:"LS" },
  { k:"LIBERIA", v:"LR" },
  { k:"LIBYAN ARAB JAMAHIRIYA", v:"LY" },
  { k:"LIECHTENSTEIN", v:"LI" },
  { k:"LITHUANIA", v:"LT" },
  { k:"LUXEMBOURG", v:"LU" },
  { k:"MACAO", v:"MO" },
  { k:"MACEDONIA", v:"MK" },
  { k:"MADAGASCAR", v:"MG" },
  { k:"MALAWI", v:"MW" },
  { k:"MALAYSIA", v:"MY" },
  { k:"MALDIVES", v:"MV" },
  { k:"MALI", v:"ML" },
  { k:"MALTA", v:"MT" },
  { k:"MARSHALL ISLANDS", v:"MH" },
  { k:"MARTINIQUE", v:"MQ" },
  { k:"MAURITANIA", v:"MR" },
  { k:"MAURITIUS", v:"MU" },
  { k:"MAYOTTE", v:"YT" },
  { k:"MEXICO", v:"MX" },
  { k:"MICRONESIA", v:"FM" },
  { k:"MOLDAVA REPUBLIC OF", v:"MD" },
  { k:"MONACO", v:"MC" },
  { k:"MONGOLIA", v:"MN" },
  { k:"MONTENEGRO", v:"ME" },
  { k:"MONTSERRAT", v:"MS" },
  { k:"MOROCCO", v:"MA" },
  { k:"MOZAMBIQUE", v:"MZ" },
  { k:"MYANMAR", v:"MM" },
  { k:"NAMIBIA", v:"NA" },
  { k:"NAURU", v:"NR" },
  { k:"NEPAL", v:"NP" },
  { k:"NETHERLANDS ANTILLES", v:"AN" },
  { k:"NETHERLANDS, THE", v:"NL" },
  { k:"NEW CALEDONIA", v:"NC" },
  { k:"NEW ZEALAND", v:"NZ" },
  { k:"NICARAGUA", v:"NI" },
  { k:"NIGER", v:"NE" },
  { k:"NIGERIA", v:"NG" },
  { k:"NIUE", v:"NU" },
  { k:"NORFOLK ISLAND", v:"NF" },
  { k:"NORTHERN MARIANA ISLANDS", v:"MP" },
  { k:"NORWAY", v:"NO" },
  { k:"OMAN", v:"OM" },
  { k:"PAKISTAN", v:"PK" },
  { k:"PALAU", v:"PW" },
  { k:"PALESTINE", v:"PS" },
  { k:"PANAMA", v:"PA" },
  { k:"PAPUA NEW GUINEA", v:"PG" },
  { k:"PARAGUAY", v:"PY" },
  { k:"PERU", v:"PE" },
  { k:"PHILIPPINES (REPUBLIC OF THE)", v:"PH" },
  { k:"PITCAIRN", v:"PN" },
  { k:"POLAND", v:"PL" },
  { k:"PORTUGAL", v:"PT" },
  { k:"PUERTO RICO", v:"PR" },
  { k:"QATAR", v:"QA" },
  { k:"REUNION", v:"RE" },
  { k:"ROMANIA", v:"RO" },
  { k:"RUSSIAN FEDERATION", v:"RU" },
  { k:"RWANDA", v:"RW" },
  { k:"SAMOA", v:"WS" },
  { k:"SAN MARINO", v:"SM" },
  { k:"SAO TOME/PRINCIPE", v:"ST" },
  { k:"SAUDI ARABIA", v:"SA" },
  { k:"SCOTLAND", v:"UK" },
  { k:"SENEGAL", v:"SN" },
  { k:"SERBIA", v:"RS" },
  { k:"SEYCHELLES", v:"SC" },
  { k:"SIERRA LEONE", v:"SL" },
  { k:"SINGAPORE", v:"SG" },
  { k:"SLOVAKIA", v:"SK" },
  { k:"SLOVENIA", v:"SI" },
  { k:"SOLOMON ISLANDS", v:"SB" },
  { k:"SOMALIA", v:"SO" },
  { k:"SOMOA,GILBERT,ELLICE ISLANDS", v:"AS" },
  { k:"SOUTH AFRICA", v:"ZA" },
  { k:"SOUTH GEORGIA, SOUTH SANDWICH ISLANDS", v:"GS" },
  { k:"SOVIET UNION", v:"SU" },
  { k:"SPAIN", v:"ES" },
  { k:"SRI LANKA", v:"LK" },
  { k:"ST. HELENA", v:"SH" },
  { k:"ST. KITTS AND NEVIS", v:"KN" },
  { k:"ST. LUCIA", v:"LC" },
  { k:"ST. PIERRE AND MIQUELON", v:"PM" },
  { k:"ST. VINCENT & THE GRENADINES", v:"VC" },
  { k:"SUDAN", v:"SD" },
  { k:"SURINAME", v:"SR" },
  { k:"SVALBARD AND JAN MAYEN", v:"SJ" },
  { k:"SWAZILAND", v:"SZ" },
  { k:"SWEDEN", v:"SE" },
  { k:"SWITZERLAND", v:"CH" },
  { k:"SYRIAN ARAB REPUBLIC", v:"SY" },
  { k:"TAIWAN", v:"TW" },
  { k:"TAJIKISTAN", v:"TJ" },
  { k:"TANZANIA, UNITED REPUBLIC OF", v:"TZ" },
  { k:"THAILAND", v:"TH" },
  { k:"TOGO", v:"TG" },
  { k:"TOKELAU", v:"TK" },
  { k:"TONGA", v:"TO" },
  { k:"TRINIDAD AND TOBAGO", v:"TT" },
  { k:"TUNISIA", v:"TN" },
  { k:"TURKEY", v:"TR" },
  { k:"TURKMENISTAN", v:"TM" },
  { k:"TURKS AND CAICOS ISLANDS", v:"TC" },
  { k:"TUVALU", v:"TV" },
  { k:"UGANDA", v:"UG" },
  { k:"UKRAINE", v:"UA" },
  { k:"UNITED ARAB EMIRATES", v:"AE" },
  { k:"UNITED KINGDOM", v:"UK" },
  { k:"UNITED STATES", v:"US" },
  { k:"UNITED STATES MINOR OUTL.IS.", v:"UM" },
  { k:"URUGUAY", v:"UY" },
  { k:"UZBEKISTAN", v:"UZ" },
  { k:"VANUATU", v:"VU" },
  { k:"VATICAN CITY STATE", v:"VA" },
  { k:"VENEZUELA", v:"VE" },
  { k:"VIETNAM", v:"VN" },
  { k:"VIRGIN ISLANDS (USA)", v:"VI" },
  { k:"WALLIS AND FUTUNA ISLANDS", v:"WF" },
  { k:"WESTERN SAHARA", v:"EH" },
  { k:"YEMEN", v:"YE" },
  { k:"ZAMBIA", v:"ZM" },
  { k:"ZIMBABWE", v:"ZW" },
]

export const touchpoint_category = [
  "CALL",
  "IN-PERSON",
  "EMAIL",
  "SOCIAL MEDIA",
  "COURT HEARING",
  "MEETUP",
  "SUPPORT MEETING",
  "LAW ENFORCEMENT",
  "VIRTUAL",
  "SOCIAL POST (VICTIM)",
  "DISCUSSED ON PODCAST (VICTIM)",
  "FEATURED IN NEWSLETTER (VICTIM)",
  "DISCUSSED ON TV / INTERVIEW (VICTIM)",
  "NEWS ARTICLE",
  "BLOG POST",
  "OTHER",
];

export const victim_special_categories = ["UNSOLVED", "PENDING ANNIVERSARY"];

export const how_submitted = [
  "REFERRAL",
  "SOCIAL_MEDIA",
  "SEARCH_GOOGLE",
  "TV",
  "RADIO",
  "BOOK",
  "BLOG",
  "PODCAST",
  "CONFERENCE",
  "IN_PERSON",
  "OTHER",
  "LAW_ENFORCEMENT",
  "ANOTHER_SURVIVOR",
];

export const how_case_submitted = [
  "WEBSITE_SUBMISSION_FORM",
  "LAW_ENFORCEMENT_SUBMITTED",
  "IN_PERSON",
  "ADVOCATED_ASSISTED",
];

export const alert_status = [
  "DECEASED",
  "DO_NOT_CALL",
  "POSSIBLE_SUSPECT",
  "DISABLED_DEAF",
  "DISABLED_HANDICAPPED",
  "SENSITIVE_FAMILY_POLITICS",
  "DIFFICULT",
];

export const case_status = [
  "ARREST_MADE",
  "SOLVED",
  "HOMICIDE",
  "MISSING",
]

export const yes_no = ["YES", "NO", "UNKNOWN"];

export const touchpoint_categories = [
  "CALL",
  "IN-PERSON",
  "EMAIL",
  "SOCIAL MEDIA",
  "COURT HEARING",
  "MEETUP",
  "SUPPORT MEETING",
  "LAW ENFORCEMENT",
  "VIRTUAL",
  "SOCIAL POST (VICTIM)",
  "DISCUSSED ON PODCAST (VICTIM)",
  "FEATURED IN NEWSLETTER (VICTIM)",
  "DISCUSSED ON TV / INTERVIEW (VICTIM)",
  "NEWS ARTICLE",
  "BLOG POST",
  "OTHER",
];

export const relationship = [
  "GRANDPARENT",
  "GRANDFATHER",
  "GRANDMOTHER",
  "PARENT",
  "FATHER",
  "MOTHER",
  "SPOUSE",
  "HUSBAND",
  "WIFE",
  "SIGNIFICANT_OTHER",
  "SIBLING",
  "BROTHER",
  "SISTER",
  "CHILD",
  "SON",
  "DAUGHTER",
  "AUNT",
  "UNCLE",
  "NIECE",
  "NEPHEW",
  "COUSIN",
  "GRANDCHILD",
  "GRANDSON",
  "GRANDAUGHTER",
  "IN_LAW",
  "FATHER_IN_LAW",
  "MOTHER_IN_LAW",
  "OTHER",
];

export const case_submission_statuses = [
  "SUBMITTED",
  "PENDING",
  "REJECTED",
  "APPROVED",
  "FOLLOWUP_LATER",
  "HOLD"
]
