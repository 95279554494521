import React from "react";
import { useTranslation } from 'react-i18next';

import { useFormik } from "formik";
import { StateContext } from "state.js";
import { usePostConnection } from "hooks";
import { Connections, Danger } from "components";
import { formatDate, readableEnum } from "utilities";
import { relationship } from "config/enums";
import LoadingScreen from "../pages/LoadingScreen";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";

// Material UI
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/* eslint-disable react-hooks/exhaustive-deps */

export function AddConnections(props) {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const { type, object, choice } = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [connection, setConnection] = React.useState(false);
  const [associateType, setAssociateType] = React.useState();
  const [associateId, setAssociateId] = React.useState();
  const [associateRelationship, setAssociateRelationship] = React.useState();
  const [reverseRelationship, setReverseRelationship] = React.useState();
  const [isLoading, setIsLoading] = React.useState();

  const { connectionResponse, connectionError } = usePostConnection(
    token,
    type,
    object.id,
    associateType,
    associateId,
    associateRelationship,
    reverseRelationship
  );

  const formik = useFormik({
    initialValues: {
      associate_id: "",
      relationship: "",
      reverseRelationship: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.associate_id) errors.associate_id = "Required";
      if (!values.relationship) errors.relationship = "Required";

      if (props.host.exclude[associateType]?.includes(values.associate_id)) {
        errors.associate_id = `Can't connect a ${type} to themself or an existing relation.`;
      }

      return errors;
    },
    onSubmit: (values) => {
      setAssociateId(values.associate_id);
      setAssociateRelationship(values.relationship);
      setReverseRelationship(values.reverseRelationship);
      setIsLoading(true);
    },
  });

  React.useEffect(() => {
    if (connection?.id) formik.setFieldValue("associate_id", connection.id);
  }, [connection]);

  React.useEffect(() => {
    if (connectionResponse) {
      /**
       * Putting this in here because idk what the response from the API will be
       * If its the survivor object we can just set up a callback
       * Otherwise we need to reload the page or setup some kind of refresh function to get the survivor again
       */
      window.location.reload();
    }
  }, [connectionResponse]);

  React.useEffect(() => {
    if (connectionError) setIsLoading(false);
  }, [connectionError]);

  const HelperText = {
    associate_id:
      formik.errors.associate_id && formik.submitCount ? <Danger>{formik.errors.associate_id}</Danger> : null,
    relationship:
      formik.errors.relationship && formik.submitCount ? <Danger>{formik.errors.relationship}</Danger> : null,
  };

  if (!object || !object?.id || !type) return null;
  if (isLoading) return <LoadingScreen />;

  return (
    <>
      {modalOpen && (
        <Dialog
          maxWidth="lg"
          open={modalOpen}
          keepMounted
          onClose={() => setModalOpen(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <h4>{t('search')} {associateType === "survivor" ? t("survivor.label") : t("victim.label")}</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description">
            <Connections
              choice={choice}
              type={associateType}
              setType={setAssociateType}
              setObject={setConnection}
              setClose={() => setModalOpen(false)}
            />
            <Box textAlign="center" pb={2}>
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {connectionError && (
        <Box textAlign="center">
          <Danger>{t('error.general')}</Danger>
        </Box>
      )}

      <Box mb={6}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <label>Select Connection</label>

            {connection && associateType === "victim" && (
              <>
                <p>
                  Victim Name: {connection.first_name} {connection.last_name}
                  <br />
                  {t('causeofdeath')}: {readableEnum(connection.cause_of_death)}
                  <br />
                  {t('deathdate')}: {formatDate(connection.date_of_death)}
                  <br />
                </p>
                <Button onClick={() => setModalOpen(true)}>Change</Button>
              </>
            )}

            {connection && associateType === "survivor" && (
              <>
                <p>
                  Survivor Name: {connection.first_name} {connection.last_name}
                  <br />
                  Relation to Victim ({connection.case.victim.first_name} {connection.case.victim.last_name} {`(${connection.case.case_number || ''})`}:{" "}
                  {readableEnum(connection.relation_to_victim)})
                </p>
                <Button onClick={() => setModalOpen(true)}>Change</Button>
              </>
            )}

            {!connection && (
              <>
                <br />
                <Button
                  size="sm"
                  onClick={() => {
                    let setType = "survivor";
                    if (type === "victim") setType = "victim";
                    setAssociateType(setType);
                    setModalOpen(true);
                  }}
                >
                  {choice && <>Find Survivor or Victim</>}
                  {!choice && type === "survivor" && <>Find Survivor</>}
                  {!choice && type === "victim" && <>Find Victim</>}
                </Button>
              </>
            )}
            {HelperText["associate_id"] && (
              <>
                <br />
                {HelperText["associate_id"]}
              </>
            )}
          </GridItem>
        </GridContainer>
      </Box>

      <Box mb={6}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <label>
              Relationship to {type === "survivor" ? "Survivor" : "Victim"}
              <Select
                onChange={(e) => formik.handleChange(e)}
                inputProps={{
                  value: formik.values.relationship,
                  onChange: (e) => formik.handleChange(e),
                  name: "relationship",
                  id: "relationship",
                }}
              >
                <MenuItem disabled>Choose one</MenuItem>
                {relationship.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {readableEnum(item)}
                    </MenuItem>
                  );
                })}
              </Select>
              {HelperText["relationship"] && <>{HelperText["relationship"]}</>}
            </label>
          </GridItem>
        </GridContainer>
      </Box>
      <Box mb={6}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <label>
              Reverse Relationship
              <Select
                onChange={(e) => formik.handleChange(e)}
                inputProps={{
                  value: formik.values.reverseRelationship,
                  onChange: (e) => formik.handleChange(e),
                  name: "reverseRelationship",
                  id: "reverseRelationship",
                }}
              >
                <MenuItem disabled>Choose one</MenuItem>
                {relationship.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {readableEnum(item)}
                    </MenuItem>
                  );
                })}
              </Select>
              {HelperText["relationship"] && <>{HelperText["relationship"]}</>}
            </label>
          </GridItem>
        </GridContainer>
      </Box>

      <Box mb={6}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}></GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Button color="info" onClick={formik.handleSubmit} fullWidth={true}>
              Save
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={5}></GridItem>
        </GridContainer>
      </Box>
    </>
  );
}
