import React from "react";
import PATHS from "config/paths";

export function useGetCaseSubmission(token, id, refresh) {
  const [caseSubmission, setCaseSubmission] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (data) => {
    if (data) {
      setCaseSubmission(data);
    } else {
      setError(new Error("data-data"));
    }
  };

  React.useEffect(() => {
    if ((token && id && !caseSubmission) || (token && id && refresh)) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiCaseSubmissions}/${id}`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => console.warn("get caseSubmission profile error", error));
    }
  }, [token, id, caseSubmission, refresh]);

  return { caseSubmissionGet: caseSubmission, caseSubmissionError: error };
}
