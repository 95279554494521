import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

const cache = new Map();

export function useGetCaseSubmissions(token, version, page, search, per_page=20, sort=false, noCache = false, forceSearch = false) {
  const [caseSubmissions, setCaseSubmissions] = React.useState();
  const [error, setError] = React.useState();
  const [pageCount, setPageCount] = React.useState();
  const [total, settotal] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleResponse = (data) => {
    if (data?.length > 0) {
      setCaseSubmissions(data);
      cache.set(`${page}${search}`, data);
      setIsLoading(false);
      setError(undefined);
    } else {
      setCaseSubmissions(undefined);
      setIsLoading(false);
      setError("error.contact.nodata");
    }
  };

  React.useEffect(() => {
      const cachedData = page ? cache.get(`${page}${search}`) : null;
      const cachedPageCount = cache.get("page-count");

      if (cachedData && cachedPageCount && !noCache) {
        setCaseSubmissions(cachedData);
        setPageCount(cachedPageCount);
        setIsLoading(false);
        setError(undefined);
      } else if (token && page) {
        if (forceSearch && !search) return;

        setIsLoading(true);
        setError(undefined);

        // Add page to pages
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Origin", "{location.protocol + '//' + location.host}");
        const options = {
          method: "GET",
          headers: headers,
          redirect: "follow",
          cache: "no-cache",
        };

        /**
         * Filters
         */
        let filters = "";
        if (page > 0) filters = `page=${page}&per_page=${per_page}`;
        if (sort) {
          let directionPrefix = sort.sortDirection === "asc" ? "" : "-";
          if(sort.field === "status") sort.field = "case_submission_approvals.status";

          let field = `${directionPrefix}${sort.field}`;
          filters = `${filters}&sort=${field}`;
        }
        if (filters.length > 0 && search?.length > 0) {
          filters = `${filters}&${search}`;
        } else if (search?.length > 0) {
          filters = search;
        }

        /**
         * Make request
         */
        fetch(`${PATHS.apiCaseSubmissions}?${filters}`, options)
          .then((response) => {
            const total = Number.parseInt(response.headers.get("total"));
            const perPage = Number.parseInt(response.headers.get("per-page"));
            const count = Math.ceil(total / perPage);
            settotal(total)
            setPageCount(count);
            cache.set("page-count", count);
            return response.json();
          })
          .then((result) => handleResponse(result))
          .catch((error) => setError(error));
        }
    
  }, [token, page, per_page, sort, version, search]);

  return { caseSubmissions, caseSubmissionsError: error, caseSubmissionsPageCount: pageCount, total: total,isLoading };
}
