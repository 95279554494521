import React from "react";
import { useFormik } from "formik";
import { StateContext } from "state.js";

import { usePostContactNote } from "hooks";
import { Danger, InputDate, InputText } from "components";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import Box from "@material-ui/core/Box";
import TextField from '@material-ui/core/TextField';


import { makeStyles } from "@material-ui/core/styles";

export function FormContactNote(props) {
    const State = React.useContext(StateContext);
    const [user_id] = React.useState(State?.user?.value?.id);
    const [token] = React.useState(State?.user?.value?.token);
    const [requestBody, setRequestBody] = React.useState();
    const { response, error, isLoading } = usePostContactNote(token, requestBody, props.contact_id);

    const boxSettings = { mb: 6 };

    const formik = useFormik({
        initialValues: {
          contact_id: props.contact_id,
          id: props.note?.id,
          contact_note: props.note?.note ? props.note.note : "",
          user_id: user_id,
        },
        validate: (values) => {
          const errors = {};
          if (!values.contact_note) errors.contact_note = "Required";
    
          return errors;
        },
        onSubmit: (values) => {
          const body = {
            id: values.id,
            note: values.contact_note,
            user_id: values.user_id
          };
          setRequestBody(body)
        },
        enableReinitialize: true,
      });

    const HelperText = {
      note: formik.errors.contact_note && formik.submitCount ? <Danger>{formik.errors.contact_note}</Danger> : null,
    }

    React.useEffect(() => {
      if (response) {
        props.setContact(response);
      }
    }, [response]);

    var styles = {
      textarea: {
        width: '400px',
      }
    };
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return (
        <React.Fragment>
            <>
              <form autoComplete="off">
                <Card>
                  <CardBody>
                    <h3>{props?.name}</h3>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                              id="standard-multiline-static"
                              label="Note"
                              name="contact_note"
                              maxRows={20}
                              aria-label="Note"
                              multiline
                              minRows={20}
                              value={formik.values.contact_note}
                              className={classes.textarea}
                              onChange={formik.handleChange}
                              InputLabelProps={{ shrink: true }}
                            />
                        </GridItem>
                      </GridContainer>
                    </Box>
                    <Box {...boxSettings}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button color="info" fullWidth onClick={formik.handleSubmit}>
                            Submit
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                    </Box>
                  </CardBody>
                </Card>
              </form>
            </>
        </React.Fragment>
    );
}
