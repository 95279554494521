import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

const cache = new Map();

export function useGetVictimsCsv(token, search, shouldDownload) {
  const [error, setError] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);

  React.useEffect(() => {

    if(token && search && shouldDownload) {
      setIsLoading(true);
      setError(undefined);

      // Add page to pages
      const headers = new Headers();
      headers.append("Accept", "text/csv");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      /**
       * Filters
       */
      let filters = "";
      if (filters.length > 0 && search?.length > 0) {
        filters = `${filters}&${search}`;
      } else if (search?.length > 0) {
        filters = search;
      }

      /**
       * Make request
       */
      fetch(`${PATHS.apiVictims}/search_to_csv?${filters}`, options)               
        .then((response) => response.blob())
        .then((blob) => {        
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `victims-${(new Date().toJSON().slice(0,10))}.csv`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            setIsCompleted(true);        
          })
          .catch((error) => setError(error))
          .finally(() => {
            setIsLoading(false)
            
          });
    }
  }, [token, search, shouldDownload]);

  return { isCompleted, downloadError: error, isLoading };
}
