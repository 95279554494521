import React from 'react';
import { StateContext } from "state.js";

import DataTable from "react-data-table-component";
import {Card, CardContent, TextField, IconButton , InputAdornment  } from '@material-ui/core';

import {Sort, Clear} from "@material-ui/icons";
import { filter } from 'lodash-es';

export function DynamicDataTable({
  title, 
  data, 
  columns, 
  initialPerPage, 
  pageChangeCallback, 
  perRowsChangeCallback, 
  defaultSortField,
  defaultSortAsc,
  sortCallback,
  filterCallback,
  filterComponents,
  filterPlaceholder,
  isLoading,
  rows = 0
}) {
  const State = React.useContext(StateContext);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  
  const tableColumns = React.useMemo( () => columns, []);
  const tableSettings = React.useMemo( () => ({
      title: title || '',
      initialPerPage: initialPerPage,
      defaultSortField: defaultSortField,
      defaultSortAsc: defaultSortAsc,
      handlePerRowsChange: perRowsChangeCallback,
      handlePageChange: pageChangeCallback,
      handleSort: sortCallback,
      paginationServerOptions: {
        persistSelectedOnPageChange: false, 
        persistSelectedOnSort: false
      },
      filterComponents: filterComponents || [],
      filterPlaceholder: filterPlaceholder || "Filter"
  }), [])

  React.useEffect(() => {
    if(filterText.length >= 3 || filterText.length === 0) {
      filterCallback(filterText);
    }
  }, [filterText]) 

  const handleFilterOnChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFilterText(e.target.value)
  }


  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField id="search" type="text" placeholder={tableSettings.filterPlaceholder} aria-label="Search Input" 
        value={filterText} 
        autoFocus={filterText.length > 0}
        onChange={onFilter} 
        InputProps={{

        endAdornment: 
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              onClick={onClear}
              edge="end"
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        }}
      />
    </>
  );
    
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        // requiring 3 characters so call manually to reset data
        filterCallback('');
      
    };

    return <FilterComponent onFilter={handleFilterOnChange} onClear={handleClear} filterText={filterText} key={1}/>;
  }, [filterText, resetPaginationToggle]);
  
  return (
    <Card px={10}>
      <CardContent px={10}>
        <DataTable
          title={tableSettings.title}
          columns={tableColumns}
          data={data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={rows}
          paginationPerPage = {tableSettings.initialPerPage}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          paginationServerOptions= {tableSettings.paginationServerOptions }
                    
          striped
          persistTableHead
          subHeader
          subHeaderWrap
          subHeaderComponent={[...tableSettings.filterComponents,subHeaderComponentMemo]}
          onChangeRowsPerPage={tableSettings.handlePerRowsChange}
          onChangePage={tableSettings.handlePageChange}

          sortServer
          defaultSortField={tableSettings.defaultSortField}
          defaultSortAsc={false}
          sortIcon={<Sort />}
          onSort={tableSettings.handleSort}
        />
        </CardContent>
      </Card>
  )
  
};
