import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

const cache = new Map();

export function useDeleteVictimAssociatedSurvivor(token, victim_id, associated_id) {
  const [error, setError] = React.useState();
  const [isDeleteComplete, setIsDeleteComplete] = React.useState(false);

  React.useEffect(() => {
    if (token && victim_id && associated_id) {        
        setError(undefined);

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Origin", "{location.protocol + '//' + location.host}");
        const options = {
          method: "DELETE",
          headers: headers,
          redirect: "follow",
          cache: "no-cache",
        };

        /**
         * Make request
         */
        fetch(`${PATHS.apiVictims}/${victim_id}/associate_survivor/${associated_id}`, options)
          .then((response) => {
            return response.json();
          })
          .catch((error) => setError(error))
          .finally(() => {
            setIsDeleteComplete(true);
          });
        }
    
  }, [token, victim_id, associated_id]);

  return { isDeleteComplete, eventsError: error };
}
