import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

const cache = new Map();

export function useDeleteStaffDocument(token, case_submission_id, staff_document_id, shouldDelete = false) {
  const [error, setError] = React.useState();
  const [isDeleteStaffDocumentComplete, setIsDeleteStaffDocumentComplete] = React.useState(false);

  React.useEffect(() => {
    if (token && staff_document_id && shouldDelete) {        
        setError(undefined);

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Origin", "{location.protocol + '//' + location.host}");
        const options = {
          method: "DELETE",
          headers: headers,
          redirect: "follow",
          cache: "no-cache",
        };

        /**
         * Make request
         */
        fetch(`${PATHS.apiCaseSubmissions}/${case_submission_id}/staff_document/${staff_document_id}`, options)
          .then((response) => {
            setIsDeleteStaffDocumentComplete(true);
            return;
          })
          .catch((error) => setError(error))
          
        }
    
  }, [token, staff_document_id, shouldDelete]);

  return { isDeleteStaffDocumentComplete, deleteStaffDocumentError: error  };
}
