import React from "react";
import styled from "styled-components";

const StyledDanger = styled.span`
  color: red;
`;

export function Danger({ children }) {
  return <StyledDanger>{children}</StyledDanger>;
}
