import React from "react";
import moment from "moment";
import AdminLayout from "layouts/Admin.js";
import PATHS from "config/paths.js";
import { useTranslation } from "react-i18next";

import { color } from "styles/styled.js";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { useParams, useHistory } from "react-router-dom";
import {
  useGetSurvivorsTouchpoints,
  useGetSurvivor,
  usePostSurvivors,
  useGetVictimPhoto,
  useDeleteSurvivor,
  useDeleteSurvivorAssociatedSurvivor,
  useDeleteSurvivorAssociatedVictim
} from "hooks";
import {
  FormTask,
  FormSurvivors,
  FormTouchpoints,
  AddConnections,
  DeleteConfirmDialog
} from "components";
import { formatDisplayDate, readableEnum, getScrollParent } from "utilities";
import { touchpoint_categories } from "config/enums";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import { Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Timeline from "components-material-ui/Timeline/Timeline.js";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Paper from '@material-ui/core/Paper';

// Icons
import Assignment from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";

// Touchpoint icons
// call
import PhoneIcon from "@material-ui/icons/Phone";
// in-person
import PersonPinIcon from "@material-ui/icons/PersonPin";
// email
import EmailIcon from "@material-ui/icons/Email";
// social media
import SmsIcon from "@material-ui/icons/Sms";
// court
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// meetup
import GroupIcon from "@material-ui/icons/Group";
// support meeting
import GroupWorkIcon from "@material-ui/icons/GroupWork";
// law enforcment
import StarsIcon from "@material-ui/icons/Stars";
// virtual
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
// social post
import ChatIcon from "@material-ui/icons/Chat";
// discussed on podcast
import MicIcon from "@material-ui/icons/Mic";
// featured in newsletter
import DescriptionIcon from "@material-ui/icons/Description";
// discussed on tv/interview
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// news article
import ReceiptIcon from "@material-ui/icons/Receipt";
// blog post
import DvrIcon from "@material-ui/icons/Dvr";
// other
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

// Photos
import priceImage1 from "assets/img/default_photo.jpg";

// Styles
import styles from "assets/jss/pages/PageStyles.js";

/* eslint-disable react-hooks/exhaustive-deps */

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function getTouchpointIcon (category = "call") {
  let icon = SmsIcon;

  switch (category) {
    case touchpoint_categories[0]: // CALL
      icon = PhoneIcon;
      break;
    case touchpoint_categories[1]: // IN-PERSON
      icon = PersonPinIcon;
      break;
    case touchpoint_categories[2]: // EMAIL
      icon = EmailIcon;
      break;
    case touchpoint_categories[3]: // SOCIAL MEDIA
      icon = SmsIcon;
      break;
    case touchpoint_categories[4]: // COURT HEARING
      icon = AccountBalanceIcon;
      break;
    case touchpoint_categories[5]: // MEETUP
      icon = GroupIcon;
      break;
    case touchpoint_categories[6]: // SUPPORT MEETING
      icon = GroupWorkIcon;
      break;
    case touchpoint_categories[7]: // LAW ENFORCEMENT
      icon = StarsIcon;
      break;
    case touchpoint_categories[8]: // VIRTUAL
      icon = QuestionAnswerIcon;
      break;
    case touchpoint_categories[9]: // SOCIAL POST (VICTIM)
      icon = ChatIcon;
      break;
    case touchpoint_categories[10]: // DISCUSSED ON PODCAST (VICTIM)
      icon = MicIcon;
      break;
    case touchpoint_categories[11]: // FEATURED IN NEWSLETTER (VICTIM)
      icon = DescriptionIcon;
      break;
    case touchpoint_categories[12]: // DISCUSSED ON TV / INTERVIEW (VICTIM)
      icon = PlayCircleFilledIcon;
      break;
    case touchpoint_categories[13]: // NEWS ARTICLE
      icon = ReceiptIcon;
      break;
    case touchpoint_categories[14]: // BLOG POST
      icon = DvrIcon;
      break;
    case touchpoint_categories[15]: // OTHER
      icon = RadioButtonCheckedIcon;
      break;
    default:
    // code block
  }

  return icon;
}

const useStyles = makeStyles(styles);

export function Page () {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  let { id } = useParams();
  const State = React.useContext(StateContext);
  const refWrapper = React.useRef();
  const [token] = React.useState(State?.user?.value?.token);
  const [survivor, setSurvivor] = React.useState();
  const [touchpoints, setTouchpoints] = React.useState([]);
  const classes = useStyles();
  const [view, setView] = React.useState("loading");
  const [refresh, setRefresh] = React.useState(false);
  const { survivorGet, survivorError } = useGetSurvivor(token, id, refresh);
  const [touchpointsPage] = React.useState(1);
  const [version, setVersion] = React.useState(1);
  const [touchpointsPOS, setTouchpointsPOS] = React.useState();
  const [alertNotice, setAlertNotice] = React.useState(true);
  const { touchpointsGet } = useGetSurvivorsTouchpoints(token, id, touchpointsPage, version);
  const [taskModal, setTaskModal] = React.useState(false);
  const [taskType, setTaskType] = React.useState("photo");

  const [allVictims, setAllVictims] = React.useState([]);
  const [victimId, setVictimId] = React.useState();
  const [refreshPhoto, setRefreshPhoto] = React.useState(false);
  const { photo } = useGetVictimPhoto(token, victimId, refreshPhoto);

  const [showDeleteVictimAssociationModal, setShowDeleteVictimAssociationModal] = React.useState(false);
  const [isDeleteVictimAssociationConfirmed, setIsDeleteVictimAssociationConfirmed] = React.useState(false);
  const [showDeleteSurvivorAssociationModal, setShowDeleteSurvivorAssociationModal] = React.useState(false);
  const [isDeleteSurvivorAssociationConfirmed, setIsDeleteSurvivorAssociationConfirmed] = React.useState(false);

  const [selectedRemoveSurvivorConnection, setSelectedRemoveSurvivorConnection] = React.useState(undefined);
  const [selectedRemoveVictimConnection, setSelectedRemoveVictimConnection] = React.useState(undefined);
  const [selectedSurvivorConnection, setSelectedSurvivorConnection] = React.useState(undefined);
  const [selectedVictimConnection, setSelectedVictimConnection] = React.useState(undefined);
  const { isDeleteSurvivorAssociationComplete, deleteSurvivorAssociationError } = useDeleteSurvivorAssociatedSurvivor(token, id, selectedRemoveSurvivorConnection);
  const { isDeleteVictimAssociationComplete, deleteVictimAssociationError } = useDeleteSurvivorAssociatedVictim(token, id, selectedRemoveVictimConnection);
  const [showArchiveSurvivorModal, setShowArchiveConfirmModal] = React.useState(false);
  const [archive, setArchive] = React.useState();
  const { isDeleteSurvivorComplete, survivorDeleteError } = useDeleteSurvivor(token, id, archive);

  const [displayPhoto, setDisplayPhoto] = React.useState("");
  
  React.useEffect(() => {
    if (refreshPhoto) setRefreshPhoto(false);
    if (photo) setDisplayPhoto( photo.url);
    else setDisplayPhoto( priceImage1);
  }, [photo, refreshPhoto]);

  const [touchpointTab, setTouchpointTab] = React.useState(0);

  const handleTouchpointTabChange = (event, newValue) => {
    setTouchpointTab(newValue);
  };

  const handleTouchpointTabChangeIndex = (index) => {
    setTouchpointTab(index);
  };

  React.useEffect(() => {
    if (isDeleteSurvivorComplete) {
      history.push("/survivors/browse");
      window.location.reload(true);
    }
  }, [isDeleteSurvivorComplete])

  React.useEffect(() => {
    if (survivorGet) {
      setView("profile");
      setSurvivor(survivorGet);      
    }
  }, [survivorGet]);

  React.useEffect(() => {
    if (survivor) {
      let all = survivor.related_victims.map((k, v) => {
        return {
          id: k.associated_victim.id,
          name: `${k.associated_victim.first_name} ${k.associated_victim.last_name} (${k.associated_victim.case.case_number})`
        }
      });
      all.push(
        {
          id: survivor?.case?.victim?.id,
          name: `${survivor?.case?.victim?.first_name} ${survivor?.case?.victim?.last_name} (${survivor?.case?.case_number})`
        })
      setAllVictims(all);
      setVictimId(survivorGet.case.victim.id);
      setRefresh(false);
    }
  }, [survivor])

  React.useEffect(() => {
    if (touchpointsGet) {
      sortTouchpoints([...touchpointsGet]);
    }
  }, [touchpointsGet]);

  React.useEffect(() => {
    if (survivor) setView("profile");
  }, [survivor]);

  const sortTouchpoints = (list) => {
    list.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    setTouchpoints(list);
  };

  const touchpointPatchCallback = (newTouchpoint) => {
    if (newTouchpoint && newTouchpoint?.id) {
      // const newTouchpointList = [...touchpoints];
      //newTouchpointList[touchpointsPOS] = newTouchpoint;
      //sortTouchpoints([...newTouchpointList]);
      setVersion(version + 1);
      setView("touchpoints");
    }
  };

  const touchpointPostCallback = (newTouchpoint) => {
    if (newTouchpoint && newTouchpoint?.id) {
      //const newTouchpointList = [...touchpoints];
      //newTouchpointList.unshift(newTouchpoint);
      //sortTouchpoints([...newTouchpointList]);
      setVersion(version + 1);
      setView("touchpoints");
    }
  };

  React.useEffect(() => {
    if (refWrapper.current) {
      const target = getScrollParent(refWrapper.current);
      if (target) target.scroll(0, 0);
    }
  }, [view]);

  const getAssociatedVictim = (id) => {
    return allVictims.find(x => x.id === id);
  }

  const address = React.useMemo(() => {
    return (
      <>
        {survivor?.address1 && (
          <>
            <br />
            {survivor?.address1}
            <br />
          </>
        )}
        {survivor?.address2 && (
          <>
            {survivor?.address2}
            <br />
          </>
        )}
        {survivor?.city && <>{survivor?.city}, </>}
        {survivor?.state && <>{survivor?.state} </>}
        {survivor?.postal_code && <>{survivor?.postal_code} </>}
      </>
    );
  }, [survivor]);

  const exclude_survivors = React.useMemo(() => {
    if (survivor) {
      const ids = survivor.related_survivors.map((k, v) => {
        return k.associated_survivor.id;
      });
      return [survivor.id, ...ids];
    }
  }, [survivor]);

  const exclude_victims = React.useMemo(() => {
    if (survivor) {
      const ids = survivor.related_victims.map((k, v) => {
        return k.associated_victim.id;
      });
      return [survivor.case.victim.id, ...ids];
    }
  }, [survivor]);

  const phone = React.useMemo(() => {
    if (survivor?.phone && survivor?.phone.length === 10) {
      var match = survivor?.phone.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match)
        return (
          <>
            ({match[1]}) {match[2]}-{match[3]}
          </>
        );
    }
    if (survivor?.phone && survivor?.phone.length === 11) {
      var match = survivor?.phone.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
      if (match)
        return (
          <>
            {match[1]} ({match[2]}) {match[3]}-{match[4]}
          </>
        );
    }
    return survivor?.phone;
  }, [survivor]);

  const alertsRed = React.useMemo(() => {
    const list = [];

    if (survivor?.alerts?.length > 0) {
      survivor.alerts.forEach((item) => {
        if (item === "DECEASED" || item === "DO_NOT_CALL" || item === "POSSIBLE_SUSPECT") {
          list.push(item);
        }
      });
    }

    return list;
  }, [survivor?.alerts]);

  const alertsBlue = React.useMemo(() => {
    const list = [];

    if (survivor?.alerts?.length > 0) {
      survivor.alerts.forEach((item) => {
        if (item !== "DECEASED" && item !== "DO_NOT_CALL" && item !== "POSSIBLE_SUSPECT") {
          list.push(item);
        }
      });
    }

    return list;
  }, [survivor?.alerts]);

  const specialCategories = React.useMemo(() => {
    if (survivor?.special_categories.length > 0) return [...survivor.special_categories];
    return [];
  }, [survivor?.special_categories]);

  const latestTouchpoint = React.useMemo(() => {
    if (touchpoints.length > 0)
      return new Date(Math.max(...touchpoints.map(e => new Date(e.date))));
    return "";
  }, [touchpoints]);

  // DELETE effects and handlers


  React.useEffect(() => {
    if (isDeleteVictimAssociationComplete) {
      setSelectedRemoveVictimConnection(undefined);
      setRefresh(true);
    }

  }, [isDeleteVictimAssociationComplete])

  React.useEffect(() => {
    if (isDeleteSurvivorAssociationComplete) {
      setSelectedRemoveSurvivorConnection(undefined);
      setRefresh(true);
    }

  }, [isDeleteSurvivorAssociationComplete])


  React.useEffect(() => {
    if (selectedVictimConnection && isDeleteVictimAssociationConfirmed) {
      setSelectedRemoveVictimConnection(selectedVictimConnection);
      setSelectedVictimConnection(undefined);
      setShowDeleteVictimAssociationModal(false);
    }
    setIsDeleteVictimAssociationConfirmed(false);
  }, [selectedVictimConnection, isDeleteVictimAssociationConfirmed])

  React.useEffect(() => {
    if (selectedSurvivorConnection && isDeleteSurvivorAssociationConfirmed) {
      setSelectedRemoveSurvivorConnection(selectedSurvivorConnection);
      setSelectedSurvivorConnection(undefined);
      setShowDeleteSurvivorAssociationModal(false);
    }
    setIsDeleteSurvivorAssociationConfirmed(false);
  }, [selectedSurvivorConnection, isDeleteSurvivorAssociationConfirmed])


  const handleRemoveAssociatedVictim = (id) => {
    setSelectedVictimConnection(id)
    setShowDeleteVictimAssociationModal(true);
  }

  const handleRemoveAssociatedSurvivor = (id) => {
    setSelectedSurvivorConnection(id)
    setShowDeleteSurvivorAssociationModal(true);
  }

  const handleConfirmRemoveAssociatedVictim = (val) => {
    setIsDeleteVictimAssociationConfirmed(val);
    setShowDeleteVictimAssociationModal(false);
  }
  const handleConfirmRemoveAssociatedSurvivor = (val) => {
    setIsDeleteSurvivorAssociationConfirmed(val);
    setShowDeleteSurvivorAssociationModal(false);
  }

  const handleArchiveSurvivor = (confirmed) => {
    if (confirmed) setArchive(true);
  }

  return (
    <React.Fragment>
      <div ref={refWrapper}>
        {view === "loading" && (
          <Box textAlign="center" pb={5}>
            <p>{t("wait")}</p>
            <CircularProgress />
          </Box>
        )}

        {survivorError && (
          <Box textAlign="center" py={5}>
            <p>{t("error.survivor.notfound")}</p>
          </Box>
        )}

        {survivor && (
          <>
            <Box paddingBottom={5}>
              <Typography align="center" component="h1" variant="h2">
                {t("survivor.label")}: {survivor.first_name} {survivor.last_name}
                <Button size="sm" onClick={() => setShowArchiveConfirmModal(true)} style={{ background: color.red }}>
                ARCHIVE
              </Button>
              </Typography>
              <Typography align="center" component="h1" variant="h5">
                {readableEnum(survivor?.relation_to_victim)} to {survivor?.case?.victim?.first_name} {survivor?.case?.victim?.last_name}
              </Typography>
            </Box>
            <Box textAlign="center" paddingBottom={1}>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button size="sm" onClick={() => setView("profile")} disabled={view === "profile" ? true : false}>
                  Profile
                </Button>
                <Button size="sm" onClick={() => setView("edit")} disabled={view === "edit" ? true : false}>
                  Edit Profile
                </Button>
                <Button
                  size="sm"
                  onClick={() => setView("touchpoints")}
                  disabled={view === "touchpoints" ? true : false}
                >
                  Touchpoints
                </Button>
                <Button size="sm" onClick={() => setView("connection")} disabled={view === "connection" ? true : false}>
                  Add Connection
                </Button>
              </ButtonGroup>
            </Box>
          </>
        )}

        {survivor && view === "profile" && (
          <React.Fragment>
            <Box textAlign="center" paddingBottom={5}>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button
                  size="sm"
                  onClick={() => {
                    setTaskType("photo");
                    setTaskModal(true);
                  }}
                  color="info"
                >
                  Task: Add Picture
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    setTaskType("meeting");
                    setTaskModal(true);
                  }}
                  color="info"
                >
                  Task: Add Support Meeting
                </Button>
              </ButtonGroup>
            </Box>

            <Box mb={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <h4>Contact Info</h4>
                  <ul>
                    <li>
                      {t("fullname")}: {survivor.full_name}
                    </li>
                    <li>
                      {t("preferredname")}: {survivor.preferred_name}
                    </li>
                    <li>
                      Email:{" "}
                      {survivor?.email && (
                        <Box component="a" color={color.blueLight} href={`mailto:${survivor.email}`}>
                          {survivor.email}
                        </Box>
                      )}
                    </li>
                    <li>
                      {t("phone")}: {phone}
                    </li>
                    <li>
                      {t("address")}: {address}
                    </li>
                    <li>
                      {t("county")}: {survivor.county && <>{survivor.county}</>}
                    </li>
                    <li>
                      {t("country")}: {survivor.country && <>{survivor.country}</>}
                    </li>
                  </ul>
                  <h4>Demographics</h4>
                  <ul>
                    <li>
                      {t("race")}: {readableEnum(survivor.race)}
                    </li>
                    <li>
                      {t("gender")}: {readableEnum(survivor.gender)}
                    </li>
                  </ul>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h4>{t("specialcat")}</h4>
                  {[...alertsRed, ...alertsBlue, ...specialCategories].map((item, index) => {
                    return (
                      <Box
                        key={index}
                        style={{
                          backgroundColor: (() => {
                            if (item === "DO_NOT_CALL" || item === "POSSIBLE_SUSPECT" || item === "DECEASED") {
                              return color.red;
                            }

                            if (
                              item === "DISABLED_DEAF" ||
                              item === "DISABLED_HANDICAPPED" ||
                              item === "SENSITIVE_FAMILY_POLITICS" ||
                              item === "DIFFICULT"
                            ) {
                              return color.blueDark;
                            }

                            return color.green;
                          })(),
                          borderRadius: "8px",
                          color: "white",
                          padding: "0.5rem 0.25rem",
                        }}
                        mb="0.5rem"
                        textAlign="center"
                      >
                        {readableEnum(item)}
                      </Box>
                    );
                  })}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h4>{t("notes")}</h4>
                  <p style={{ "white-space": "pre-wrap" }}>{survivor?.note ? <>{survivor?.note}</> : <em>Empty</em>}</p>
                </GridItem>
              </GridContainer>
            </Box>

            <Box mb={10}>
              {" "}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <h4>Primary Victim</h4>
                  <ul>
                    <li>
                      {t("name")}: {survivor?.case?.victim?.first_name} {survivor?.case?.victim?.last_name}
                    </li>
                    <li>Survivor's Relationship: {readableEnum(survivor?.relation_to_victim)}</li>

                    <li>Victims's Relationship: {readableEnum(survivor?.victim_relation_to_survivor)}</li>
                    <li>
                      {t("causeofdeath")}:{" "}
                      {survivor?.case?.victim?.cause_of_death ? (
                        <>{readableEnum(survivor.case.victim.cause_of_death)}</>
                      ) : (
                        <>Unknown</>
                      )}
                    </li>
                    <li>
                      {t("incident.date")}:{" "}
                      {survivor?.case?.victim?.date_of_death ? (
                        <>{formatDisplayDate(survivor.case.victim.date_of_death)}</>
                      ) : (
                        <>Unknown</>
                      )}
                    </li>
                    <li>
                      {t("case.lea")}:{" "}
                      {survivor?.case?.law_enforcement_agency ? (
                        <>{survivor.case.law_enforcement_agency}</>
                      ) : (
                        <>unknown</>
                      )}
                    </li>
                    <li>
                      {t("case.number")}:{" "}
                      {survivor?.case?.case_number ? <>{survivor.case.case_number}</> : <>unknown</>}
                    </li>
                  </ul>

                  {survivor?.case?.victim?.id && (
                    <p>
                      <Button href={`${PATHS.victimProfile}/${survivor?.case?.victim?.id}`}>View victim</Button>
                    </p>
                  )}

                  {survivor?.related_victims?.length > 0 && <h4>Additional Victims</h4>}
                  {survivor?.related_victims?.length > 0 &&
                    survivor.related_victims.map((k, v) => {
                      return (
                        <div key={k.id}>
                          <ul>
                            <li>
                              {t("name")}: {k.associated_victim?.first_name} {k.associated_victim?.last_name}
                            </li>
                            <li>Relationship to Survivor: {readableEnum(k?.relationship)}</li>
                            <li>Survivor Relationship to Victim: {readableEnum(k?.reverse_relation)}</li>
                            <li>
                              {t("causeofdeath")}:{" "}
                              {k.associated_victim?.cause_of_death ? (
                                <>{readableEnum(k.associated_victim.cause_of_death)}</>
                              ) : (
                                <>Unknown</>
                              )}
                            </li>
                            <li>
                              {t("incident.date")}:{" "}
                              {k.associated_victim?.date_of_death ? (
                                <>{formatDisplayDate(k.associated_victim.date_of_death)}</>
                              ) : (
                                <>Unknown</>
                              )}
                            </li>
                            <li>
                              {t("case.lea")}:{" "}
                              {k.associated_victim.case?.law_enforcement_agency ? (
                                <>{k.associated_victim.case.law_enforcement_agency}</>
                              ) : (
                                <>unknown</>
                              )}
                            </li>
                            <li>
                              {t("case.number")}:{" "}
                              {k.associated_victim?.case?.case_number ? (
                                <>{k.associated_victim.case.case_number}</>
                              ) : (
                                <>unknown</>
                              )}
                            </li>
                          </ul>

                          {k.associated_victim?.id && (
                            <p>
                              <Button href={`${PATHS.victimProfile}/${k.associated_victim?.id}`}>View victim</Button>
                            </p>
                          )}
                        </div>
                      );
                    })}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <figure>
                    <img src={displayPhoto} alt="" style={{ width: "100%", height: "auto" }} />
                    <figcaption>{`${survivor?.case?.victim?.first_name} ${survivor?.case?.victim?.last_name}`}</figcaption>
                  </figure>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h4>{t("contactprefs")}</h4>
                  <ul>
                    <li>
                      {t("optnews")}:{" "}
                      {survivor?.opt_in_news && (
                        <Box component="span" color={survivor.opt_in_news === "YES" ? color.blueLight : "red"}>
                          {readableEnum(survivor.opt_in_news)}
                        </Box>
                      )}
                    </li>
                    <li>
                      {t("eventinterest")}:{" "}
                      {survivor?.events_interest && (
                        <Box component="span" color={survivor.events_interest === "YES" ? color.blueLight : "red"}>
                          {readableEnum(survivor.events_interest)}
                        </Box>
                      )}
                    </li>
                    <li>
                      {t("otherservices")}:{" "}
                      {survivor?.other_victim_services && (
                        <Box
                          component="span"
                          color={survivor.other_victim_services === "YES" ? color.blueLight : "red"}
                        >
                          {readableEnum(survivor.other_victim_services)}
                        </Box>
                      )}
                    </li>
                    <li>
                      {t("othernp")}:{" "}
                      {survivor?.other_non_profit && (
                        <Box component="span" color={survivor.other_non_profit === "YES" ? color.blueLight : "red"}>
                          {readableEnum(survivor.other_non_profit)}
                        </Box>
                      )}
                    </li>
                    <li>
                      {t("lecon")}:{" "}
                      {survivor?.le_connection && (
                        <Box component="span" color={survivor.le_connection === "YES" ? color.blueLight : "red"}>
                          {readableEnum(survivor.le_connection)}
                        </Box>
                      )}
                    </li>
                    <li>
                      {t("howlearned")}:{" "}
                      {survivor?.how_learned_pcc && (
                        <Box component="span" color={color.blueLight}>
                          {readableEnum(survivor.how_learned_pcc)}
                        </Box>
                      )}
                    </li>
                  </ul>

                  <h4>{t("survivor.touchpoint.last")}</h4>
                  <div>
                    {latestTouchpoint.toString()}
                  </div>
                </GridItem>
              </GridContainer>
            </Box>

            <Box my={10}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>{t("victim.additional.victims")}</h4>
                </CardHeader>
                <CardBody>
                  {survivor.related_victims?.length > 0 ? (
                    <TableContainer>
                      <Table className="hover">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("name")}</TableCell>
                            <TableCell>{t("relationship")}</TableCell>
                            <TableCell>{"Reverse Relation"}</TableCell>
                            <TableCell>{t("specialcat")}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {survivor.related_victims.map((vic, index) => {
                            return (
                              <TableRow
                                key={index}
                              >
                                <TableCell onClick={() => (window.location = `${PATHS.victimProfile}/${vic.associated_victim.id}`)}>
                                  {vic.associated_victim.first_name} {vic.associated_victim.last_name}{" "}
                                  ({vic.associated_victim?.case?.case_number})
                                </TableCell>
                                <TableCell>{readableEnum(vic.relationship)}</TableCell>
                                <TableCell>{readableEnum(vic.reverse_relation)}</TableCell>
                                <TableCell>{readableEnum(vic.associated_victim.special_category)}</TableCell>
                                <TableCell>
                                  {vic?.id && (
                                    <Button size="sm" onClick={() => handleRemoveAssociatedVictim(vic.id)} disabled={view === "connection" ? true : false}>
                                      Remove Connection
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <>{t("victim.additional.none")}</>
                  )}
                </CardBody>
              </Card>
            </Box>

            <Box my={10}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>{t("survivor.connections")}</h4>
                </CardHeader>
                <CardBody>
                  {survivor.related_survivors?.length > 0 ? (
                    <TableContainer>
                      <Table className="hover">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("name")}</TableCell>
                            <TableCell>{t("relationship")}</TableCell>
                            <TableCell>{"Reverse Relation"}</TableCell>
                            <TableCell>{t("specialcat")}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {survivor.related_survivors.map((surv, index) => {
                            return (
                              <TableRow
                                key={index}
                              >
                                <TableCell onClick={() =>
                                  (window.location = `${PATHS.survivorsProfile}/${surv.associated_survivor.id}`)
                                }>
                                  {surv.associated_survivor.first_name} {surv.associated_survivor.last_name}
                                </TableCell>
                                <TableCell>{readableEnum(surv.relationship)}</TableCell>
                                <TableCell>{readableEnum(surv.reverse_relation)}</TableCell>
                                <TableCell>
                                  {surv.associated_survivor.special_categories.length > 0 ? (
                                    <ul>
                                      {surv.associated_survivor.special_categories.map((item, index2) => {
                                        return <li key={index2}>{readableEnum(item)}</li>;
                                      })}
                                    </ul>
                                  ) : (
                                    <>None</>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {surv?.id && (
                                    <Button size="sm" onClick={() => handleRemoveAssociatedSurvivor(surv.id)} disabled={view === "connection" ? true : false}>
                                      Remove Connection
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <>{t("victim.additional.none")}</>
                  )}
                </CardBody>
              </Card>
            </Box>

            {taskModal && (
              <AddTask
                modal={taskModal}
                type={taskType}
                closeModal={() => setTaskModal(false)}
                name={`${survivor?.case?.victim?.first_name} ${survivor?.case?.victim?.last_name}`}
              />
            )}
          </React.Fragment>
        )}

        {survivor && view === "edit" && <Edit survivor={survivor} setSurvivor={setSurvivor} setRefresh={setRefresh} setRefreshPhoto={setRefreshPhoto}/>}

        {view === "touchpoints" && (
          <>
            <Box align="right">
              <Button
                size="sm"
                onClick={() => {
                  setTouchpointsPOS(-1);
                  setView("touchpoint-edit");
                }}
              >
                {t("survivor.touchpoint.add")}
              </Button>
            </Box>

            {touchpoints && touchpoints.length > 0 && (
              <>
                <Tabs
                  value={touchpointTab}
                  onChange={handleTouchpointTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="touchpoint tabs"
                >
                  <Tab label="Touchpoint" />
                  <Tab label="Logs" />
                </Tabs>
                <Timeline
                  simple
                  stories={touchpoints.map((touchpoint, index) => {
                    return {
                      inverted: true,
                      badgeColor: "primary",
                      badgeIcon: getTouchpointIcon(touchpoint.category),
                      title: moment(touchpoint.date, "YYYY-MM-DD").format("MMMM Do, YYYY"),
                      titleColor: "primary",
                      body: (
                        <>
                          {touchpointTab == 0 && (
                            <>
                              <button
                                className="touchpoint-edit"
                                onClick={() => {
                                  setTouchpointsPOS(index);
                                  setView("touchpoint-edit");
                                }}
                              >
                                <SettingsIcon />
                              </button>
                              <Typography component="h5" variant="h5">
                                {touchpoint.title}
                              </Typography>

                              <Box component="p" my={2}>
                                <strong>{t("survivor.touchpoint.category")}:</strong> {readableEnum(touchpoint.category)}{" "}
                                <br />
                                <strong>{t("survivor.touchpoint.service")}:</strong>{" "}
                                {readableEnum(touchpoint.services_provided)} <br />
                                <strong>{t("survivor.touchpoint.duration")}:</strong> {touchpoint.duration}
                                <br />
                                {touchpoint.victim &&
                                  <span><strong>Victim:</strong> {getAssociatedVictim(touchpoint.victim)?.name}</span>
                                }
                              </Box>

                              <p>
                                <strong>{t("survivor.touchpoint.note")}:</strong> <br />
                                <div style={{ "white-space": "pre-wrap" }}> {touchpoint.notes}</div>
                              </p>
                            </>
                          )
                          }
                          {touchpointTab == 1 && (
                            <>
                              <Typography component="h5" variant="h5">
                                {touchpoint.title}
                              </Typography>
                              {touchpoint.audits &&
                                <TableContainer component={Paper}>
                                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">Action</TableCell>
                                        <TableCell align="left">User</TableCell>
                                        <TableCell align="left">Date Time</TableCell>
                                        <TableCell align="left">Version</TableCell>
                                        <TableCell align="left">Changes</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {touchpoint.audits.map((row, idx) => (
                                        <TableRow
                                          key={idx}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {row.action}
                                          </TableCell>
                                          <TableCell align="left">{row.username}</TableCell>
                                          <TableCell align="left">{row.created_at}</TableCell>
                                          <TableCell align="left">{row.version}</TableCell>
                                          <TableCell align="left">{JSON.stringify(row.audited_changes)}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              }
                            </>
                          )
                          }
                        </>
                      ),
                    };
                  })}
                />
              </>
            )}
          </>
        )}

        {view === "touchpoint-edit" && (
          <FormTouchpoints
            title={t("survivor.touchpoint.edit")}
            touchpoint={touchpointsPOS >= 0 ? touchpoints[touchpointsPOS] : undefined}
            survivor_id={survivor?.id}
            callback={touchpointsPOS >= 0 ? touchpointPatchCallback : touchpointPostCallback}
            method={touchpointsPOS >= 0 ? "patch" : "post"}
            associatedSurvivors={allVictims}
          />
        )}

        {view === "connection" && (
          <AddConnections
            type="survivor"
            choice={true}
            object={survivor}
            host={{
              type: "survivor",
              id: survivor.id,
              exclude: { survivor: exclude_survivors, victim: exclude_victims },
            }}
          />
        )}
      </div>

      {alertsRed.length > 0 && (
        <React.Fragment>
          <Dialog open={alertNotice} keepMounted>
            <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
              <h4>
                <strong>Alert Notice</strong>
              </h4>
            </DialogTitle>
            <DialogContent>
              <p>This survivor has important alerts: </p>

              {[...alertsRed].map((item, index) => {
                return (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: color.red,
                      borderRadius: "8px",
                      color: "white",
                      padding: "0.5rem 0.25rem",
                    }}
                    mb="0.5rem"
                    textAlign="center"
                  >
                    {readableEnum(item)}
                  </Box>
                );
              })}

              <Box component="p" py="1rem">
                Please acknowledge: <Button onClick={() => setAlertNotice(false)}>I understand</Button>
              </Box>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      )}
      {/** Delete Confirm Dialog */}
      <DeleteConfirmDialog
        key="victimAssociationDeleteModal"
        showModal={showDeleteVictimAssociationModal}
        result={handleConfirmRemoveAssociatedVictim}
      />
      <DeleteConfirmDialog
        key="survivorAssociationDeleteModal"
        showModal={showDeleteSurvivorAssociationModal}
        result={handleConfirmRemoveAssociatedSurvivor}
      />
      <DeleteConfirmDialog
        key="archiveVictimConfirm"
        showModal={showArchiveSurvivorModal}
        result={handleArchiveSurvivor} />
    </React.Fragment>
  );
}

function Edit (props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const { response } = usePostSurvivors(token, requestBody, "PATCH", props.survivor.id);

  const formik = useFormik({
    initialValues: {
      case_id: props?.survivor?.case?.case_id,
      first_name: props?.survivor?.first_name,
      last_name: props?.survivor?.last_name,
      full_name: props?.survivor?.full_name,
      preferred_name: props?.survivor?.preferred_name,
      phone: props?.survivor?.phone,
      email: props?.survivor?.email,
      alerts: props?.survivor?.alerts || [],
      address1: props?.survivor?.address1,
      address2: props?.survivor?.address2,
      city: props?.survivor?.city,
      state: props?.survivor?.state,
      postal_code: props?.survivor?.postal_code,
      country: props?.survivor?.country,
      county: props?.survivor?.county,
      opt_in_news: props?.survivor?.opt_in_news,
      events_interest: props?.survivor?.events_interest,
      le_connection: props?.survivor?.le_connection,
      how_learned_pcc: props?.survivor?.how_learned_pcc,
      other_non_profit: props?.survivor?.other_non_profit,
      other_victim_services: props?.survivor?.other_victim_services,
      special_categories: props?.survivor?.special_categories || [],
      alert_status: props?.survivor?.alert_status || [],
      note: props?.survivor?.note,
      relation_to_victim: props?.survivor?.relation_to_victim,
      victim_relation_to_survivor: props?.survivor?.victim_relation_to_survivor,
      gender: props?.survivor?.gender || "",
      race: props?.survivor?.race || "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.case_id) errors.case_id = "Required";
      if (!values.first_name) errors.first_name = "Required";
      if (!values.last_name) errors.last_name = "Required";
      if (!values.email) errors.email = "Required";
      if (!values.phone) errors.phone = "Required";
      //if (values.phone && values.phone.replace(/[^0-9]/g, "").length !== 10) errors.phone = "Incorrect length";

      return errors;
    },
    onSubmit: (values) => {
      setRequestBody({
        case: values.case_id,
        survivor: {
          first_name: values.first_name,
          last_name: values.last_name,
          full_name: values.full_name,
          preferred_name: values.preferred_name,
          phone: values.phone.replace(/[^0-9]/g, ""),
          email: values.email,
          alerts: values.alerts,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code,
          country: values.country,
          county: values.county,
          opt_in_news: values.opt_in_news,
          events_interest: values.events_interest,
          le_connection: values.le_connection,
          how_learned_pcc: values.how_learned_pcc,
          other_non_profit: values.other_non_profit,
          other_victim_services: values.other_victim_services,
          special_categories: values.special_categories,
          note: values.note,
          relation_to_victim: values.relation_to_victim,
          victim_relation_to_survivor: values.victim_relation_to_survivor,
          gender: values.gender,
          race: values.race,
        },
      });
    },
  });

  const vic = { ...props?.survivor?.case?.victim };
  vic["case"] = { ...props?.survivor?.case };

  React.useEffect(() => {
    if (response) {
      const newState = { ...props.survivor, ...response };
      props.setSurvivor(newState);
      props.setRefresh(true);
      props.setRefreshPhoto(true);
    }
  }, [response]);

  if (response) return <></>;

  return <FormSurvivors formik={formik} victim={vic} submitText="Update" />;
}

function AddTask (props) {
  const State = React.useContext(StateContext);
  const [response, setResponse] = React.useState();
  const [task] = React.useState({
    id: "",
    title: props.type === "photo" ? `Need to Call About Picture` : `Need to Call About Support Meeting`,
    description:
      props.type === "photo" ? `Need photo for ${props.name}` : `Need to call ${props.name} to set up support meeting`,
    created_by: { id: `${State.user.value.id}` },
    due_date: moment(),
    completed: false,
  });

  React.useEffect(() => {
    console.log("response", response);
  }, [response]);

  return (
    <Dialog
      open={true}
      transition={Transition}
      keepMounted
      onClose={props.closeModal}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle id="classic-modal-slide-title" disableTypography>
        <Button justIcon key="close" aria-label="Close" color="transparent" onClick={() => props.closeModal(false)}>
          <Close />
        </Button>
        <h4>New Task</h4>
      </DialogTitle>
      <DialogContent id="modal-slide-description">
        {response?.id ? (
          <div style={{ width: "500px" }}>
            <Card>
              <CardBody>Task added!</CardBody>
            </Card>
          </div>
        ) : (
          <FormTask task={task} setTask={setResponse} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default function PageSurvivorProfile () {
  return <AdminLayout component={<Page />} />;
}
