import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetVictimsRemembrances(token) {
  const [remembrances, setRemembrances] = React.useState();

  React.useEffect(() => {
    if (token && !remembrances) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Origin", "{location.protocol + '//' + location.host}");
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(PATHS.apiVictimsRemembrances, options)
        .then((response) => response.json())
        .then((result) => setRemembrances(result))
        .catch(() => setRemembrances([]));
    }
  }, []);

  return { remembrances };
}
