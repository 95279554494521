import React from "react";
import PATHS from "config/paths";
import AdminLayout from "layouts/Admin.js";
import { useTranslation } from "react-i18next";

import { useGetVictim, usePostSurvivors } from "hooks";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { FormSurvivors } from "components";

// Creative Tim
import Card from "components-material-ui/Card/Card.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CardIcon from "components-material-ui/Card/CardIcon.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

// Icons
import Add from "@material-ui/icons/Add";

/* eslint-disable react-hooks/exhaustive-deps */

function Page () {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [autoLoadId, setAutoLoadId] = React.useState();
  const [requestBody, setRequestBody] = React.useState();
  const { response, survivorsError } = usePostSurvivors(token, requestBody);
  const { victimGet } = useGetVictim(token, autoLoadId);

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      case_id: "",
      first_name: "",
      last_name: "",
      full_name: "",
      preferred_name: "",
      phone: "",
      email: "",
      alerts: [],
      address1: "",
      address2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      county: "",
      opt_in_news: "",
      gender: "",
      race: "",
      events_interest: "",
      le_connection: "",
      how_learned_pcc: "",
      other_non_profit: "",
      other_victim_services: "",
      special_categories: [],
      note: "",
      relation_to_victim: "",
      victim_relation_to_survivor: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.case_id) errors.case_id = "Required";
      if (!values.first_name) errors.first_name = "Required";
      if (!values.last_name) errors.last_name = "Required";
      if (!values.email) errors.email = "Required";
      if (!values.phone) errors.phone = "Required";
      //if (values.phone && values.phone.replace(/[^0-9]/g, "").length !== 10) errors.phone = "Incorrect length";

      return errors;
    },
    onSubmit: (values) => {
      setRequestBody({
        case: values.case_id,
        survivor: {
          first_name: values.first_name,
          last_name: values.last_name,
          full_name: values.full_name,
          preferred_name: values.preferred_name,
          gender: values.gender,
          race: values.race,
          phone: values.phone.replace(/[^0-9]/g, ""),
          email: values.email,
          alerts: values.alerts,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code,
          country: values.country,
          county: values.county,
          opt_in_news: values.opt_in_news,
          events_interest: values.events_interest,
          le_connection: values.le_connection,
          how_learned_pcc: values.how_learned_pcc,
          other_non_profit: values.other_non_profit,
          other_victim_services: values.other_victim_services,
          special_categories: values.special_categories,
          note: values.note,
          relation_to_victim: values.relation_to_victim,
          victim_relation_to_survivor: values.victim_relation_to_survivor,
        },
      });
      setIsLoading(true);
    },
  });

  /**
   * Handle error
   */
  React.useEffect(() => {
    if (survivorsError) {
      setIsLoading(false);
      setError(true);
    }
  }, [survivorsError]);

  /**
   * Handle new vicitim success
   */
  React.useEffect(() => {
    if (response && response.id) {
      history.push(`${PATHS.survivorsProfile}/${response.id}`);
    }
  }, [response]);

  /**
   * Handle pre-selected-victim on page load
   */
  React.useEffect(() => {
    const victimId = localStorage.getItem("pccSetPreSelectVictim");
    if (victimId) {
      localStorage.removeItem("pccSetPreSelectVictim");
      setAutoLoadId(victimId);
    }
  }, []);

  return (
    <div id="page-vic-new">
      {survivorsError && (
        <SnackbarContent
          color="info"
          message={t("error.general")}
          open={error}
          onClick={() => {
            setError(false);
          }}
          close
        />
      )}

      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          {t("survivor.new")}
        </Typography>
      </Box>

      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Add />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {isLoading && (
            <Box textAlign="center" pb={5}>
              <p>{t("wait")}</p>
              <CircularProgress />
            </Box>
          )}

          {!isLoading && (
            <React.Fragment>
              <FormSurvivors formik={formik} preSelectVictim={victimGet} />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default function PageSurvivorsNew () {
  return <AdminLayout component={<Page />} />;
}
