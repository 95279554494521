import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostAddFollowup(token, requestBody, case_submission_id) {
  const [body, setBody] = React.useState();
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Compare requestBody to body so we don't infinite loop
   */
  React.useEffect(() => {
    if (!lodash.isEqual(requestBody, body)) {
      setError(undefined);
      setResponse(undefined);
      setBody(requestBody);
      setIsLoading(true);
    }
  }, [requestBody, setBody]);

  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && body ) {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      const path = `${PATHS.apiCaseSubmissions}/${case_submission_id}/followup`;

      async function postRequest() {
        const request = await fetch(path, options);
        return request.json();
      }

      postRequest()
        .then((data) => {
          if (data) setResponse(data);
          else setError("unknown-error");
          setIsLoading(false);
        })
        .catch((_error) => {
          setError(_error);
          setIsLoading(false);
        });
    }
  }, [token, body, setResponse]);

  return { addFollowupResponse: response, addFollowupError: error, isLoading };
}
