import React from "react";
import { StateContext } from "state.js";

import Button from "components-material-ui/CustomButtons/Button.js";

// Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/pages/PageStyles.js";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function DeleteConfirmDialog (props) {
  const State = React.useContext(StateContext);
  const classes = useStyles();
  const result = props.result;

  const [confirmModal, setConfirmModal ] = React.useState(false);

  React.useEffect(() => {
    setConfirmModal(props.showModal);
  }, [props.showModal])

  const confirm = (confirmed) => {
    setConfirmModal(false);
    result(confirmed); 
  }

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={confirmModal}
      transition={Transition}
      keepMounted
      onClose={() => result(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => result(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Confirm Delete</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <h5>Are you sure you want to do this?</h5>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button onClick={() => confirm(false)}>Never Mind</Button>
        <Button onClick={() => confirm(true)} color="success">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
