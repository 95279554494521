const bad_enum_translations = [
  "DISABLED_DEAF",
  "DISABLED_HANDICAPPED",
  "LGBTQ",
  "CAUCASIAN_WHITE",
  "AFRICAN_AMERICAN_BLACK",
  "AMERICAN_INDIAN_ALASKA_NATIVE",
  "HISPANIC_LATINO",
  "TV"
];

export function readableEnum(enumString) {
  if (!enumString) return "";
  if (bad_enum_translations.includes(enumString)) return fixEnumTranslations(enumString);

  //const start = enumString.charAt(0).toUpperCase();
  var str = enumString.replaceAll("_", " ").replaceAll("-", " ");
  str = titleCase(str);
  return str;
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}

function fixEnumTranslations(badEnum) {
  let fixedEnum = badEnum;

  switch (badEnum) {
    case "DISABLED_DEAF":
      fixedEnum = "Disabled/Deaf";
      break;
    case "DISABLED_HANDICAPPED":
      fixedEnum = "Disabled/Handicapped";
      break;
    case "LGBTQ":
      fixedEnum = "LGBTQ";
      break;
    case "CAUCASIAN_WHITE":
      fixedEnum = "Caucasian/White";
      break;
    case "AFRICAN_AMERICAN_BLACK":
      fixedEnum = "African American/Black";
      break;
    case "AMERICAN_INDIAN_ALASKA_NATIVE":
      fixedEnum = "American Indian/Alaskan Native";
      break;
    case "HISPANIC_LATINO":
      fixedEnum = "Hispanic/Latino";
      break;
    case "TV":
      fixedEnum = "TV";
      break;

      case "IN_PERSON":
        fixedEnum = "In-person";
        break;

    default:
      break;
  }

  return fixedEnum;
}
