import React from "react";
import { StateContext } from "state.js";
import { useTranslation } from "react-i18next";
import { usePostStaffDocument, useDeleteStaffDocument } from "hooks";
import { useFormik } from "formik";

import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Box from "@material-ui/core/Box";
import Button from "components-material-ui/CustomButtons/Button.js";
import Danger from "components-material-ui/Typography/Danger.js";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import { color } from "styles/styled";
import styles from "assets/jss/pages/PageStyles.js";
import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Visibility";


export function StaffDocuments (props) {
  const { t } = useTranslation();
  const useStyles = makeStyles(styles);

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();

  const { upload, uploadError } = usePostStaffDocument(token, props.caseSubmissionId, requestBody);
  const [isLoading, setIsLoading] = React.useState();


  const [deleteStaffDocumentId, setDeleteStaffDocumentId] = React.useState();
  const [shouldDelete, setShouldDelete] = React.useState();

  const {isDeleteStaffDocumentComplete, deleteStaffDocumentError} = useDeleteStaffDocument(token, props.caseSubmissionId, deleteStaffDocumentId, shouldDelete);

  const [openDialog, setOpenDialog] = React.useState();
  const handleClose = () => setOpenDialog(false);
  const handleOpen = () => setOpenDialog(true);

  const handleDelete = (id) => {
    setDeleteStaffDocumentId(id);
    setShouldDelete(true);    
  };

  React.useEffect(() => {
    if (upload) {
      setIsLoading(false);
      setRequestBody(false);
      props.refresh(true);
    }
  }, [upload]);

  React.useEffect(() => {
    if (isDeleteStaffDocumentComplete || deleteStaffDocumentError) {
      setShouldDelete(false);
      setDeleteStaffDocumentId();
      props.refresh(true);
    }
    if(deleteStaffDocumentError) {
      console.log("Error deleting staff document: ", deleteStaffDocumentError);
    }
  }, [isDeleteStaffDocumentComplete, deleteStaffDocumentError]);

  const onPreview = props.onPreview;

  const staffDocuments = props.staffDocuments;
  const classes = useStyles();
  
  const SUPPORTED_FORMATS = [
    "image/jpeg", 
    "image/png", 
    "image/jpg", 
    "application/pdf", 
    "application/rtf", 
    "text/plain",
    "application/msword", 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const FILE_SIZE = 5 * 1024;
  const formik = useFormik({
    initialValues: {
      file: null
    },
    validate: (values) => {
      const errors = {};
      //if (!values.file) errors.file = "Required";
      if (values.file !== undefined && values.file !== null) {
        const size = Math.round(values.file.size / 1024);
        if (!SUPPORTED_FORMATS.includes(values.file.type)) {
          errors.file = "Unsupported format " + values.file.type;
        }
        if (size > FILE_SIZE) {
          errors.file = "File should not be more than 3MB.";
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      setRequestBody({
        file: values.file
      });
    }
  });

  return (
    <>
      <h3>Staff Documents</h3>
      <Box sx={{mb: 2}}>
      <GridContainer className={classes.root} sx={{ mb: 2 }}>
        <GridItem xs={12}>
            <TableContainer className={classes.table}>
              <Table aria-label="simple table" className="hover">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Button color="info" onClick={() => handleOpen() } fullWidth={false}>
                        Add 
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffDocuments?.map((doc) => {
                    return (
                      <TableRow key={doc.id} >
                        <TableCell>{`${doc.id || ""}`}</TableCell>
                        <TableCell >{doc.url?.split('/').pop()}</TableCell>
                        <TableCell>{doc.notes}</TableCell>
                        <TableCell>
                          <IconButton aria-label="view" color="info"  onClick={() => onPreview(doc.url) }>
                            <ViewIcon />
                          </IconButton>
                          <IconButton aria-label="delete" color="error" onClick={() => handleDelete(doc.id) }>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Upload Document"}</DialogTitle>
          <DialogContent>
          <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                '& > :not(style)': {
                  m: 1,
                  padding: 20,
                  width: 400,
                },
                justifyContent: 'space-around' 
              }}
            >     
                <form autoComplete="off">            
                  <Box textAlign="left" pt={10}>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      onChange={(event) => {
                        formik.setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                    {formik.submitCount > 0 && formik.errors.file &&
                      <Box
                        textAlign="center"
                        pb={5}
                        style={{ background: color.red, padding: "1rem", borderRadius: "4px" }}
                        color="white"
                        m={0}
                        mb={5}
                        p="1rem"
                      >
                        <Danger>{formik.errors.file}</Danger>
                      </Box>
                    }
                  </Box>
                  {isLoading && (
                    <Box textAlign="center" pb={5}>
                      <p>Processing Import</p>
                      <CircularProgress />
                    </Box>
                  )}
                  {upload && (
                    <Box textAlign="left" p={5}>
                      <p>Import Done</p>                
                    </Box>
                  )}
                  {uploadError && (
                    <Box textAlign="center" pb={5}>       
                      <Alert severity="error">{uploadError}</Alert>
                    </Box>
                  )}
                  <p>
                    <Button color="info" onClick={formik.handleSubmit} fullWidth={false}>
                      Add New Staff Document
                    </Button>
                  </p>
                </form>
          
            </Box>
          </DialogContent>
          <DialogActions>
        
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        
    </>
  );
}
