import fetchIntercept from 'fetch-intercept';
import Cookies from "js-cookie";

export const AuthInterceptor = () => {
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here      
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      if (response.status == 401) {
        Cookies.remove("pcc-user");
        window.location = "/";
      }
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
};
