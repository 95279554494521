import React from "react";
import AdminLayout from "layouts/Admin.js";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { useGetUsers, usePostRegistrations } from "hooks";
import { FormUsers } from "components";

// Creative Tim
import Button from "components-material-ui/CustomButtons/Button.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";

function Page() {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [view, setView] = React.useState("list");
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [userIndex, setUserIndex] = React.useState();
  const [users, setUsers] = React.useState([]);
  const [newUser, setNewUser] = React.useState(false);
  const [editUser, setEditUser] = React.useState(false);
  const { getUsers, getUsersErrors, getUsersPageCount, getUsersIsLoading } = useGetUsers(token, page, search);

  React.useEffect(() => {
    if (getUsers && getUsers.length > 0) {
      const newUserList = [ ...getUsers];
      setUsers(newUserList);
    }
  }, [getUsers]);

  React.useEffect(() => {
    const pccNewUser = localStorage.getItem("pccNewUser");
    const pccEditUser = localStorage.getItem("pccEditUser");

    if (pccNewUser) setNewUser(true);
    if (pccEditUser) setEditUser(true);

    localStorage.removeItem("pccNewUser");
    localStorage.removeItem("pccEditUser");
  }, []);

  if (State?.user?.value?.role !== "admin") {
    return (
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          Admin only
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          Manage Users
        </Typography>
      </Box>

      <Box textAlign="center" paddingBottom={5}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button size="sm" onClick={() => setView("list")} disabled={view === "list" ? true : false}>
            Manage Users
          </Button>
          <Button size="sm" onClick={() => setView("add")} disabled={view === "add" ? true : false}>
            Add User
          </Button>
        </ButtonGroup>
      </Box>

      {view === "list" && (
        <>
          {getUsersIsLoading && (
            <Box textAlign="center" pb={5}>
              <p>Please wait</p>
              <CircularProgress />
            </Box>
          )}

          {((users && users?.length === 0) || getUsersErrors) && !getUsersIsLoading && (
            <Box textAlign="center" pb={5}>
              No users found
            </Box>
          )}

          {users && users?.length > 0 && !getUsersIsLoading && (
            <>
              {newUser && (
                <SnackbarContent
                  color="info"
                  message="New user was successfully added"
                  onClick={() => {
                    setNewUser(false);
                  }}
                  close
                />
              )}
              {editUser && (
                <SnackbarContent
                  color="info"
                  message="User was successfully edited"
                  onClick={() => {
                    setEditUser(false);
                  }}
                  close
                />
              )}

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {user.first_name} {user.last_name}
                          </TableCell>
                          <TableCell>{user.role}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>
                            <Box textAlign="right">
                              <Button
                                onClick={() => {
                                  setUserIndex(index);
                                  setView("edit");
                                }}
                              >
                                Edit
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {users && getUsersPageCount && (
                <Box textAlign="center" mt={5}>
                  <Pagination page={page} count={getUsersPageCount} onChange={(e, p) => setPage(p)} />
                </Box>
              )}
            </>
          )}
        </>
      )}

      {view === "add" && (
        <React.Fragment>
          <Box paddingBottom={5}>
            <Typography component="h4">New User</Typography>
          </Box>
          <AddUser />
        </React.Fragment>
      )}

      {view === "edit" && (
        <React.Fragment>
          <Box paddingBottom={5}>
            <Typography component="h4">Edit User</Typography>
          </Box>
          <EditUser user={users[userIndex]} />
        </React.Fragment>
      )}
    </div>
  );
}

function AddUser(props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const { postUser, postUserError, postUserIsLoading } = usePostRegistrations(token, requestBody);
  const [page, setPage] = React.useState(1);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password_digest: "",
      company: "",
      job_title: "",
      status: "",
      role: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.first_name) errors.first_name = "Required";
      if (!values.last_name) errors.last_name = "Required";
      if (!values.email) errors.email = "Required";
      if (!values.password_digest) errors.email = "Required";
      if (!values.role) errors.role = "Required";
      if (!values.status) errors.status = "Required";
      return errors;
    },
    onSubmit: (values) => {
      setRequestBody({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password_digest: values.password_digest,
        status: values.status,
        role: values.role,
        company: values.company,
        job_title: values.job_title,
      });
    },
  });

  React.useEffect(() => {
    if (postUser) {
      localStorage.setItem("pccNewUser", "true");
      window.location = "/manage-users";
    }
  }, [postUser]);

  return (
    <>
      {postUserIsLoading && (
        <Box textAlign="center" pb={5}>
          <p>Please wait</p>
          <CircularProgress />
        </Box>
      )}

      {postUserError && !postUserIsLoading && (
        <Box textAlign="center" pb={5}>
          An error occurred, please try again.
        </Box>
      )}

      {!postUser && !postUserError && !postUserIsLoading && <FormUsers formik={formik} />}
    </>
  );
}

function EditUser(props) {
  const { user } = props;
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const { postUser, postUserError, postUserIsLoading } = usePostRegistrations(token, requestBody, true, user?.id);

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      company: user?.company,
      job_title: user?.job_title,
      status: user?.status,
      role: user?.role,
    },
    validate: (values) => {
      const errors = {};
      if (!values.first_name) errors.first_name = "Required";
      if (!values.last_name) errors.last_name = "Required";
      if (!values.email) errors.email = "Required";
      if (!values.role) errors.role = "Required";
      if (!values.status) errors.role = "Required";
      return errors;
    },
    onSubmit: (values) => {
      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password_digest: values.password_digest,
        status: values.status,
        role: values.role,
        company: values.company,
        job_title: values.job_title,
      };

      if (values.password_digest && values.password_digest.length > 0) {
        body.password_digest = values.password_digest;
      }
      setRequestBody(body);
    },
  });

  React.useEffect(() => {
    if (postUser) {
      localStorage.setItem("pccEditUser", "true");
      window.location = "/manage-users";
    }
  }, [postUser]);

  return (
    <>
      {postUserIsLoading && (
        <Box textAlign="center" pb={5}>
          <p>Please wait</p>
          <CircularProgress />
        </Box>
      )}

      {postUserError && !postUserIsLoading && (
        <Box textAlign="center" pb={5}>
          An error occurred, please try again.
        </Box>
      )}

      {!postUser && !postUserError && !postUserIsLoading && <FormUsers formik={formik} submitText="Save" />}
    </>
  );
}

export default function PageManageUsers() {
  return <AdminLayout component={<Page />} />;
}
