import React from "react";
import { StateContext } from "state.js";
import moment from "moment";
import { formatDate } from "utilities";
import { usePostAddFollowup } from "hooks";
import { useSnackbar } from 'notistack';

import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Card from "components-material-ui/Card/Card"
import CardBody from "components-material-ui/Card/CardBody";
import CardHeader from "components-material-ui/Card/CardHeader";
import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import { ActiveUserDropdown } from "components";
import { Button, IconButton } from "@material-ui/core";

import { CheckRounded } from "@material-ui/icons";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Edit, Add } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export function FollowUpForm (props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [editInitial, setEditInitial] = React.useState(false);

  const [requestBody, setRequestBody] = React.useState();
  const { addFollowupResponse, addFollowupError } = usePostAddFollowup(token, requestBody, props.caseSubmissionId);

  React.useEffect(() => {
    if(addFollowupResponse){
      console.log("addFollowupResponse", addFollowupResponse);
      props.refresh(addFollowupResponse);
      enqueueSnackbar('Followup saved', { 
        variant: 'success',
      });
    }
  }, [addFollowupResponse])

  React.useEffect(() => {
    if (addFollowupError){
      enqueueSnackbar('Followup Failed',
      { 
        variant: 'error',
      });
    }
  }, [addFollowupError]);


  const initial = {
    initialContactComplete: false,
    contactedBy: "",
    spokeWith: "",
    contactMethod: "",
    contactDate: "",
    notes: "",
    isInitialContact: false
  };

  const [followups, setFollowups] = React.useState(props.followups);

  const [newFollowup, setNewFollowup] = React.useState(initial)


  const handleContactedByChange = (id) => {
    setNewFollowup({ ...newFollowup, ["contactedBy"]: id });
  }

  const handleContactedDateChange = (e) => {
    setNewFollowup({ ...newFollowup, ["contactDate"]: e.target.value });
  }

  const handleEdit = () => {
    setEditInitial(true);
  }
  const handleFollowupAdd = ()  => {
    if(props.disabled) return;
    setRequestBody({
      contacted_by_id: newFollowup.contactedBy,
      spoke_to: newFollowup.spokeWith,
      contact_method: newFollowup.contactMethod,
      contact_date: newFollowup.contactDate,
      notes: newFollowup.notes,
      is_initial_contact: newFollowup.isInitialContact
    })
    setEditInitial(false);
    setNewFollowup(initial);
  }

  return (
    <>
      <GridContainer className={classes.root} >
        <GridItem xs={12} sm={12} >

          <h6>Followups <IconButton aria-label="edit" color="primary" onClick={handleEdit} disabled={props.disabled}>
            <Add />
          </IconButton>
          </h6>
          {editInitial && (
            <>
              <ActiveUserDropdown
                label="Contacted By"
                onChange={(val) => {
                  handleContactedByChange(val);
                }}
                value={newFollowup.contactedBy}
              />
              <InputLabel id="method-simple-select-label">Contact Method</InputLabel>
               <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={newFollowup.contactMethod}
                  onChange={event => {
                    const { value } = event.target;
                    setNewFollowup({ ...newFollowup, ["contactMethod"]:  event.target.value  });
                  }}
                >
                  <MenuItem value={"CALL"}>Phone</MenuItem>
                  <MenuItem value={"EMAIL"}>Email</MenuItem>
                  <MenuItem value={"IN-PERSON"}>In-Person</MenuItem>
                  <MenuItem value={"OTHER"}>Other (Add Note)</MenuItem>                  
                </Select>
              <TextField
                id="spokeTo"
                label="Spoke With"
                defaultValue={newFollowup.spokeWith}
                onChange={event => {
                  const { value } = event.target;
                  setNewFollowup({ ...newFollowup, ["spokeWith"]:  event.target.value  });
                }}
              />
              <TextField
                id="contactDate"
                label="Date"
                type="date"
                defaultValue={moment(newFollowup.contactDate, "YYYY-MM-DD").format('YYYY-MM-DD')}
                onChange={(val) => handleContactedDateChange(val)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox                  
                    checked={newFollowup.isInitialContact ?? false}                
                    name="is_initial_contact"
                    color="primary"
                    onChange={event => {
                      setNewFollowup({ ...newFollowup, ["isInitialContact"]:  event.target.checked  });
                    }}
                  />
                }
                label="Initial Contact?"
              />
              <TextField
                id="notes"
                label="Notes"
                multiline
                rows={4}
                defaultValue={newFollowup.notes}
                onChange={event => {
                  const { value } = event.target;
                  setNewFollowup({ ...newFollowup, ["notes"]:  event.target.value  });
                }}
               
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleFollowupAdd}
                className={classes.button}
              >
                Add
              </Button>
            </>
          )}
          
        </GridItem>
        <GridItem xs={12}>
          <GridContainer className={classes.root} >
        
            { props.followups.map((f, i) => {
              return (
                <GridItem xs={12} sm={6} key={f.id}>
                <Card >
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitle}>Contacted By: {f.contacted_by?.first_name} {f.contacted_by?.last_name} {f.is_initial_contact && <CheckRounded /> }</h4>
                    <p>Spoke With: {f.spoke_to} - {formatDate(f.contact_date)}</p>
                  </CardHeader>
                  <CardBody>
                    {f.notes} 
                  </CardBody>
                </Card>
                </GridItem>
              )
              }
              )}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  )
}
