import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function usePostFeaturePhotoApproved(token, id, approve) {
  const [success, setSuccess] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (data) => {
    if (data) setSuccess(data);
    else setError("error");
  };

  React.useEffect(() => {
    if (token && id && approve) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);

      const options = {
        method: "POST",
        headers: headers,
        redirect: "follow",
      };

      // Make request
      fetch(`${PATHS.apiCaseSubmissions}/${id}/approve_photo`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => setError(error));
    }
  }, [token, approve]);

  return { approveSuccess: success, approveError: error };
}
