import { css, createGlobalStyle } from "styled-components";

export const color = {
  black: "#141414",
  gray: "#EFEFEF",
  white: "#FFFFFF",
  blueDark: "#1B365D",
  green: "#3D513B",
  blueLight: "#1EC7E2",
  red: "#ff5454",
};

const label = css`
  color: #888888 !important;
  font-family: "Lato", "Helvetica", "Arial", sans-serif !important;
  font-size: 14px !important;
  opacity: 1 !important;
  text-transform: none !important;
`;

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  .MuiFormControl-root,
  label, 
  input.form-control,
  input.form-control label {
    ${label}

    * {
      text-transform: none !important;
    }

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder { 
      ${label}
    }

    /* Internet Explorer 10-11 */
    &:-ms-input-placeholder { 
      ${label}
    }

    /* Microsoft Edge */
    ::-ms-input-placeholder { 
      ${label}
    }
  }

  .side-bar-wrapper >ul >li > a {
    &, &.active, &.active:focus, &:focus{
      background: rgba(255,255,255,0.1);
    }

    b { top: -4px; }
  }

  /* Table */
  table.hover {
    tbody tr:hover td {
      background: ${color.blueDark};
      color: white;
      cursor: pointer;
    }
  }

  /* Pageination */
  ul.MuiPagination-ul {
    justify-content: center;
  }

  label {
    ${label}
  }

  input,
  input.form-control,
  input[type="text"],
  select,
  .MuiInputBase-input
  .Mui-focused {
    color: ${color.black} !important;
    font-size: 1rem !important;
  }

  /* Select */
  .MuiInputBase-root {
    display: block;
    width: 100%;
  }

  .rdt input.form-control, input.form-control {
    background-image: linear-gradient(${color.black},${color.black}), linear-gradient(${color.black},${color.black}) !important;

    &:focus {
      background-image: linear-gradient(${color.blueLight},${color.blueLight}), linear-gradient(${color.blueLight},${color.blueLight}) !important;
    }
  }

  .MuiInputBase-root::before {
    border-color: ${color.black} !important;
  }
  .MuiInputBase-root::after,.rdt input.form-control {
    border-color: ${color.blueLight} !important;
  }

  .text-area textarea {
    height: 150px !important;
  }

  h1,h2,h3,h4,h5,h6 {
    color: ${color.black};
  }

  button.touchpoint-edit {
    background: transparent;
    border: 0;
    color: ${color.blueDark};
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

`;
