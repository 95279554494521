import React from "react";
import { StateContext } from "state.js";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';

import { 
  useGetFeaturedPhoto, usePostFeaturedPhoto, usePostFeaturePhotoApproved, usePostFeaturePhotoUnapproved, 
  usePostUserPhotoApproval, usePostUserImagesApproved, usePostUserImagesUnapproved, usePostUserImagesRejected } from "hooks";

import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import { Button, IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField, Badge } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import defaultPhoto from "assets/img/default_photo.jpg";
import { Refresh } from "@material-ui/icons";
import Image from "material-ui-image";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));


export function Images (props) {
  const { t, i18n } = useTranslation();

  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [refreshPhoto, setRefreshPhoto] = React.useState(false);
  const { photo } = useGetFeaturedPhoto(token, props.caseSubmissionId, refreshPhoto);
  const [requestBody, setRequestBody] = React.useState();
  const { upload, uploadError } = usePostFeaturedPhoto(token, props.caseSubmissionId, requestBody);

  const [userPhotosToApprove, setUserPhotosToApprove] = React.useState();
  const { userPhotoApprovalResponse, userPhotoApprovalError } = usePostUserPhotoApproval(token, props.caseSubmissionId, userPhotosToApprove);

  const hasImages = props?.submittedImages?.length > 0;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
  const FILE_SIZE = 3 * 1024;

  const formik = useFormik({
    initialValues: {
      file: null,
      title: props.title,
    },
    validate: (values) => {
      const errors = {};
      //if (!values.file) errors.file = "Required";
      if (values.file !== undefined && values.file !== null) {
        const size = Math.round(values.file.size / 1024);
        if (!SUPPORTED_FORMATS.includes(values.file.type)) {
          errors.file = "Unsupported format " + values.file.type;
        }
        if (size > FILE_SIZE) {
          errors.file = "File should not be more than 3MB.";
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setRequestBody({
        file: values.file,
      });
    },
  });

  const [displayPhoto, setDisplayPhoto] = React.useState(defaultPhoto);
  React.useEffect(() => {
    if (photo) {
      setDisplayPhoto(photo.url);
      setRefreshPhoto(false);
    }
  }, [photo]);
 
  React.useEffect(() => {
    if (upload) {
      setRefreshPhoto(true);
      enqueueSnackbar('Featured Photo Uploaded', { 
        variant: 'success',
      });
    }
  }, [upload]);


  /********** User Photo Approval **************/
  const handleApproveUserImages = () => {
    const images = {case_submission: {id: props.caseSubmissionId, approved_images_attributes: approvedImages.map((x) => { return {approved: x.approved, notes: x.notes, id: x.id}})}}
    setUserPhotosToApprove(images);
  };

  const [approvedImages, setApprovedImages] = React.useState(props.submittedImages);
  const approvedCount = approvedImages.filter(x => x.approved).length;

  const onApproveImage = (approved, image) => {
    setApprovedImages(approvedImages.map((item) => (item.id == image.id ? { ...item, approved } : item)));
  };

  React.useEffect(() => {
    if(userPhotoApprovalResponse) {      
      props.refresh(userPhotoApprovalResponse);
      enqueueSnackbar('User Images Approved', { 
        variant: 'success',
      });
    }
  }, [userPhotoApprovalResponse])

  const [shouldApproveUserImages, setShouldApproveUserImages] = React.useState();
  const [shouldUnapproveUserImages, setShouldUnapproveUserImages] = React.useState();
  const [shouldRejectUserImages, setShouldRejectUserImages] = React.useState();
  const { userImagesApprovedSuccess, userImagesApprovedError } = usePostUserImagesApproved(token, props.caseSubmissionId, shouldApproveUserImages);
  const { userImagesUnapprovedSuccess, userImagesUnapprovedError } = usePostUserImagesUnapproved(token, props.caseSubmissionId, shouldUnapproveUserImages);
  const { userImagesRejectedSuccess, userImagesRejectedError } = usePostUserImagesRejected(token, props.caseSubmissionId, shouldRejectUserImages);

  React.useEffect(() => {
    if(userImagesApprovedSuccess) {
      setShouldApproveUserImages(false);
      props.refresh(userImagesApprovedSuccess);
      enqueueSnackbar('User Images Marked as Final', { 
        variant: 'success',
      });
    }
  }, [userImagesApprovedSuccess])
  
  React.useEffect(() => {
    if(userImagesUnapprovedSuccess) {
      setShouldUnapproveUserImages(false);
      props.refresh(userImagesUnapprovedSuccess);
      enqueueSnackbar('User Images Unmarked as Final', { 
        variant: 'info',
      });
    }
  }, [userImagesUnapprovedSuccess])

  const onRejectImage = (reject, image) => {
    if(reject) setShouldRejectUserImages(image.id);
  };

  React.useEffect(() => {
    if(userImagesRejectedSuccess) {
      setShouldRejectUserImages(false);
      props.refresh(userImagesRejectedSuccess);
      enqueueSnackbar('User Images Rejected', { 
        variant: 'info',
      });
    }
  }, [userImagesRejectedSuccess])

  const onFinalUserImageApproval = (approved) => {
    if (approved) {
      setShouldApproveUserImages(true);
    } else {
      setShouldUnapproveUserImages(true);
    }
  }

  const handleNoteChange = (notes, image) => {
    setApprovedImages(approvedImages.map((item) => (item.id == image.id ? { ...item, notes } : item)));
  }
  /********** End User Photo Approval **************/

  /********** Feature Photo Approval **************/
  const [approve, setApproveFeaturedPhoto] = React.useState(false);
  const [unapprove, setUnapproveFeaturedPhoto] = React.useState(false);
  const { approveSuccess, approveError } = usePostFeaturePhotoApproved(token, props.caseSubmissionId, approve);
  const { unapproveSuccess, unapproveError } = usePostFeaturePhotoUnapproved(token, props.caseSubmissionId, unapprove);

  const onApproveFeaturedImage = (approved) => {
    if (approved) {
      setApproveFeaturedPhoto(true);
    } else {
      setUnapproveFeaturedPhoto(true);
    }
  }

  React.useEffect(() => {
    if (approveSuccess) {
      setApproveFeaturedPhoto(false);
      props.refresh(approveSuccess);
      enqueueSnackbar('Feature Photo Approved', { 
        variant: 'success',
      });
    }
  }, [approveSuccess]);

  React.useEffect(() => {
    if (unapproveSuccess) {
      setUnapproveFeaturedPhoto(false);
      props.refresh(unapproveSuccess);
      enqueueSnackbar('Feature Photo Unapproved', { 
        variant: 'info',
      });
    }
  }, [unapproveSuccess]);

  /********** End Feature Photo Approval **************/


  return (
    <>
      <GridContainer className={classes.root} >
        <GridItem xs={12}>

          <h3>Featured Photo</h3>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <form autoComplete="off">
            {uploadError && (
              <Box
                textAlign="center"
                pb={5}
                style={{ background: color.red, padding: "1rem", borderRadius: "4px" }}
                color="white"
                m={0}
                mb={5}
                p="1rem"
              >
                {t("error.victim.documentupload")}
              </Box>
            )}
            {displayPhoto && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <img src={displayPhoto} alt="" style={{ width: "100%", height: "auto" }} />
                </GridItem>
              </GridContainer>
            )}
            <Box textAlign="left" pt={2} pb={2}>
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
                disabled={props.disabled}
              />
              {formik.submitCount > 0 && formik.errors.file && <Danger>{formik.errors.file}</Danger>}
            </Box>

            <p>
              <Button variant="contained" color="primary" onClick={formik.handleSubmit} fullWidth={false} disabled={props.disabled}>
                Upload Featured Photo
              </Button>
            </p>
          </form>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props?.featureApproved}
                name="is_photo_approved"
                color="primary"
                onChange={(evt) => onApproveFeaturedImage(evt.target.checked)}
                disabled={props.disabled || !photo}
              />
            }
            label="Feature Photo Approved"
          />
        </GridItem>
        <GridItem xs={8}>
          <h3>User Submitted Images</h3>
          {!hasImages && (
            <div>No User Submitted Images</div>
          )}
        </GridItem>
        <GridItem xs={4}>
          <Badge badgeContent={approvedCount} color="secondary">
            <Button variant="contained" color="primary" onClick={handleApproveUserImages} fullWidth={false} disabled={props.disabled}>
              Approve User Submitted Images
            </Button>
          </Badge>
          <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.userPhotosApproved}
                    name="approved-final"
                    color="primary"
                    onChange={(evt) =>  onFinalUserImageApproval(evt.target.checked) }
                    disabled={props.disabled}
                  />
                }
                label="Final"
              />
        </GridItem>
        {approvedImages?.map((image) => {
          return (
            <GridItem xs={6} key={"user-images-" + image.id}>
              <div key={image.id}>
                <Image src={image.url} style={{objectFit: 'contain'}} />  
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={image.approved}
                    name={"approved-image-" + image.id}
                    color="primary"
                    onChange={(e) => {
                      onApproveImage(e.target.checked, image);
                    }}
                    disabled={props.disabled}
                  />
                }
                label="Approve Image"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={image.rejected}
                    name={"rejected-image-" + image.id}
                    color="primary"
                    onChange={(e) => {
                      onRejectImage(e.target.checked, image);
                    }}
                    disabled={props.disabled}
                  />
                }
                label="Reject Image"
              />
              <TextField
                id={"user-images-note-" + image.id}
                label="Note"
                defaultValue={image.note}
                onChange={(e) => handleNoteChange(image.id, e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          )
        }
        )}
      </GridContainer>
    </>
  )
}
