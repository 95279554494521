import React from "react";

import { useTranslation } from "react-i18next";

import { cause_of_death, how_case_submitted, gender, race, special_categories, state, case_status, countries } from "config/enums";
import { Danger, InputDate, InputText } from "components";
import { readableEnum } from "utilities";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";

// Material UI
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";

/* eslint-disable react-hooks/exhaustive-deps */

export function FormVictim (props) {
  const { t, i18n } = useTranslation();

  const formik = props.formik;

  const boxSettings = { mb: 6 };
  const HelperText = {
    age: formik.errors.age && formik.submitCount ? <Danger>{formik.errors.age}</Danger> : null,
    county: formik.errors.county && formik.submitCount ? <Danger>{formik.errors.county}</Danger> : null,

    first_name: formik.errors.first_name && formik.submitCount ? <Danger>{formik.errors.first_name}</Danger> : null,
    last_name: formik.errors.last_name && formik.submitCount ? <Danger>{formik.errors.last_name}</Danger> : null,
    cause_of_death:
      formik.errors.cause_of_death && formik.submitCount ? <Danger>{formik.errors.cause_of_death}</Danger> : null,
    date_of_birth:
      formik.errors.date_of_birth && formik.submitCount ? <Danger>{formik.errors.date_of_birth}</Danger> : null,
    date_of_death:
      formik.errors.date_of_death && formik.submitCount ? <Danger>{formik.errors.date_of_death}</Danger> : null,
  };

  var styles = {
    textarea: {
      width: "100%",
    },
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  React.useEffect(() => {
    // set the value of textC, based on textA and textB
    if (
      formik.values.date_of_death &&
      formik.values.date_of_birth
    ) {
      var years = new Date(new Date(formik.values.date_of_death) - new Date(formik.values.date_of_birth)).getFullYear() - 1970;
      formik.setFieldValue("age", years);
    }
  }, [formik.values.date_of_death, formik.values.date_of_birth, formik.setFieldValue]);


  return (
    <React.Fragment>
      <form autoComplete="off">
        <h3>{t("victim.details")}</h3>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["first_name"]}
                label={t("firstname")}
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label={t("lastname")}
                helperText={HelperText["last_name"]}
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label={t("fullname")}
                name="full_name"
                value={formik.values.full_name ?? ""}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label={t("preferredname")}
                name="preferred_name"
                value={formik.values.preferred_name ?? ""}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <FormControl fullWidth>
                <InputDate
                  helperText={HelperText["date_of_birth"]}
                  label={t("dob")}
                  name="date_of_birth"
                  onChange={formik.setFieldValue}
                  value={formik.values.date_of_birth}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <InputText
                label={t("age")}
                helperText={HelperText["age"]}
                name="age"
                type="number"
                value={formik.values.age}
                onChange={formik.handleChange}

              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <InputDate
                helperText={HelperText["date_of_death"]}
                label={t("deathdate")}
                name="date_of_death"
                onChange={formik.setFieldValue}
                value={formik.values.date_of_death}
              />
            </GridItem>

          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("causeofdeath")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.cause_of_death,
                    onChange: (e) => formik.handleChange(e),
                    name: "cause_of_death",
                    id: "cause_of_death",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {cause_of_death.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["cause_of_death"] && <>{HelperText["cause_of_death"]}</>}
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("gender")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.gender,
                    onChange: (e) => formik.handleChange(e),
                    name: "gender",
                    id: "gender",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {gender.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("race")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.race,
                    onChange: (e) => formik.handleChange(e),
                    name: "race",
                    id: "race",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {race.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                label={t("cityofdeath")}
                name="city_of_death"
                type="text"
                value={formik.values.city_of_death}
                onChange={formik.handleChange}

              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("stateofdeath")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.state_of_death ?? "",
                    onChange: (e) => formik.handleChange(e),
                    name: "state_of_death",
                    id: "state_of_death",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {state.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <h3>{t("case.details")}</h3>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InputText
                value={formik.values.investigative_case_number ?? ""}
                helperText={HelperText["investigative_case_number"]}
                label={t("case.investigative")}
                name="investigative_case_number"
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InputText
                value={formik.values.case_number ?? ""}
                helperText={HelperText["case_number"]}
                label={t("case.number")}
                name="case_number"
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("case.status")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.status ?? "",
                    onChange: (e) => formik.handleChange(e),
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {case_status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
          </GridContainer>
        </Box>

        <h4>{t("incident.title")}</h4>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                helperText={HelperText["suspect"]}
                label={t("Suspect")}
                name="suspect"
                value={formik.values.suspect}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                helperText={HelperText["address1"]}
                label={t("address1")}
                name="address1"
                value={formik.values.address1}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                helperText={HelperText["address2"]}
                label={t("address2")}
                name="address2"
                value={formik.values.address2}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InputText
                helperText={HelperText["city"]}
                label={t("city")}
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("state")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.state,
                    onChange: (e) => formik.handleChange(e),
                    name: "state",
                    id: "state",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {state.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                {HelperText["state"] && <>{HelperText["state"]}</>}
              </label>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <InputText
                helperText={HelperText["postal_code"]}
                label={t("zip")}
                name="postal_code"
                value={formik.values.postal_code}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <label>
                {t("country")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.country ?? "",
                    onChange: (e) => formik.handleChange(e),
                    name: "country",
                    id: "country",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {countries.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.v}>
                        {item.k}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["county"]}
                label={t("county")}
                name="county"
                value={formik.values.county}
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                id="description"
                label="Description"
                name="description"
                maxRows={20}
                aria-label="Description"
                multiline
                variant="outlined"
                minRows={10}
                value={formik.values.description}
                className={classes.textarea}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputDate
                label={t("case.date.reported")}
                name="reported_date"
                onChange={formik.setFieldValue}
                value={formik.values.reported_date}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputDate
                  label={t("case.date.submitted")}
                  name="submitted_date"
                  onChange={formik.setFieldValue}
                  value={formik.values.submitted_date}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label>
                {t("case.howsubmitted")}
                <Select
                  onChange={(e) => formik.handleChange(e)}
                  inputProps={{
                    value: formik.values.how_submitted,
                    onChange: (e) => formik.handleChange(e),
                    name: "how_submitted",
                    id: "how_submitted",
                  }}
                >
                  <MenuItem disabled>Choose one</MenuItem>
                  {how_case_submitted.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {readableEnum(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["met_media"]}
                label={t("case.metmedia")}
                name="met_media"
                onChange={formik.handleChange}
                value={formik.values.met_media ?? ""}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputDate
                label={t("case.date.socialpost")}
                name="social_media_post_date"
                onChange={formik.setFieldValue}
                value={formik.values.social_media_post_date}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputDate
                label={t("case.date.spotlight")}
                name="written_spotlight_date"
                onChange={formik.setFieldValue}
                value={formik.values.written_spotlight_date}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputDate
                label={t("case.date.fbfeature")}
                name="fb_live_feature_date"
                onChange={formik.setFieldValue}
                value={formik.values.fb_live_feature_date}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputDate
                label={t("case.date.tv")}
                name="on_tv_date"
                onChange={formik.setFieldValue}
                value={formik.values.on_tv_date}
              />
            </GridItem>
          </GridContainer>
        </Box>

        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                helperText={HelperText["news_outlet"]}
                label={t("case.news")}
                name="news_outlet"
                onChange={formik.handleChange}
                value={formik.values.news_outlet ?? ""}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <InputText
                value={formik.values.law_enforcement_agency ?? ""}
                helperText={HelperText["law_enforcement_agency"]}
                label={t("case.lea")}
                name="law_enforcement_agency"
                onChange={formik.handleChange}
              />
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridItem xs={12} sm={12} md={6}>
            <label>
              {t("case.bylea")}
              <Checkbox
                checked={formik.values.submitted_by_law_enforcement === true}
                onChange={formik.handleChange}
                name="submitted_by_law_enforcement"
              />
            </label>
          </GridItem>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <label>{t("specialcat")}</label>
              <br />

              {special_categories.map((item, index) => {
                return (
                  <label className="checkbox-label" key={`checkbox-${index}`}>
                    <Checkbox
                      name="special_categories"
                      value={item}
                      onChange={(e) => formik.handleChange(e)}
                      checked={Boolean(formik.values.special_categories.indexOf(item) + 1)}
                    />{" "}
                    {readableEnum(item)}
                  </label>
                );
              })}
            </GridItem>
          </GridContainer>
        </Box>
        <Box {...boxSettings}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}></GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Button color="info" fullWidth onClick={formik.handleSubmit}>
                {props?.submitText ? <>{props?.submitText}</> : <>Add</>}
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}></GridItem>
          </GridContainer>
        </Box>
      </form>
    </React.Fragment>
  );
}
