import React from "react";
import PATHS from "config/paths";
import AdminLayout from "layouts/Admin.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { lighten, makeStyles } from '@material-ui/core/styles';

import { StateContext } from "state.js";
import { formatDate, readableEnum } from "utilities";
import { useGetVictims } from "hooks";

// Creative Tim
import Button from "components-material-ui/CustomButtons/Button.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

function Page () {
  const { t, i18n } = useTranslation();
  const classes = useToolbarStyles();

  const State = React.useContext(StateContext);
  const [page, setPage] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [token] = React.useState(State?.user?.value?.token);
  const history = useHistory();

  const [orderDir, setOrderDir] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [sort, setSort] = React.useState();

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDir === 'asc';
    setOrderDir(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSort(`sorted_by=${property}_${orderDir}`);
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { victims, victimsError, victimsPageCount, isLoading } = useGetVictims(
    token,
    page,
    false,
    true,
    false,
    sort);

  const headCells = [
    { id: 'pcc_case_number', numeric: false, disablePadding: false, label: t('case.number') },
    { id: 'first_name_', numeric: false, disablePadding: true, label: t('firstname') },
    { id: 'last_name_', numeric: false, disablePadding: false, label: t('lastname') },
    { id: 'age', numeric: false, disablePadding: false, label: t('age') },
    { id: 'gender_', numeric: false, disablePadding: false, label: t('gender') },
    { id: 'race_', numeric: false, disablePadding: false, label: t('race') },
    { id: 'cause_of_death_', numeric: false, disablePadding: false, label: t('cause') },
    { id: 'incident_city', numeric: false, disablePadding: false, label: t('incident.city') },
    { id: 'incident_state', numeric: false, disablePadding: false, label: t('incident.state') },
    { id: 'incident_date', numeric: false, disablePadding: false, label: 'Incident Date' },
    { id: 'date_of_death', numeric: false, disablePadding: false, label: 'Date of Death' },
  ];

  // Handle errors
  React.useEffect(() => setError(true), [victimsError]);

  const [open, setOpen] = React.useState();
  React.useEffect(() => {
    if (open) {
      if (open.a == "open") {
        history.push(`${PATHS.victimProfile}/${open.id}`);
      }
      if (open.a == "tab") {
        window.open(`${PATHS.victimProfile}/${open.id}`);
      }
      // todo get chrome to open new window
      if (open.a == "window") {
        const target = `_win${open.id}`;
        window.open(`${PATHS.victimProfile}/${open.id}`, target);
      }

    }
  }, [open]);

  return (
    <div>
      {victimsError && (
        <SnackbarContent
          color="info"
          message={t('error.general')}
          open={error}
          onClick={() => {
            setError(false);
          }}
          close
        />
      )}

      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          {t('browse.victims')}
        </Typography>
      </Box>

      <Box textAlign="center" mb={5}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button size="sm" component="a" href="/victims/search">
            {t('advancesearch')}
          </Button>
          <Button size="sm" color="info" component="a" href="/victims/new">
            {t('addnew')}
          </Button>
        </ButtonGroup>
      </Box>

      {isLoading && (
        <Box textAlign="center" pb={5}>
          <p>{t('wait')}</p>
          <CircularProgress />
        </Box>
      )}

      {victims && victims?.length === 0 && !isLoading && (
        <Box textAlign="center" pb={5}>
          {t('victim.none')}
        </Box>
      )}

      {victims && victims?.length > 0 && !isLoading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table" className="hover">
            <TableHead>
              <TableRow>
              <TableCell></TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? orderDir : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? orderDir : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {orderDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {victims.map((victim, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Open</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={""}
                          label="Age"
                          onChange={(e) => { setOpen(e.target.value) }}
                        >
                          <MenuItem value={{ a: "open", id: victim.id }}>Open</MenuItem>
                          <MenuItem value={{ a: "tab", id: victim.id }}>Open in New Tab</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>{victim.case.case_number}</TableCell>
                    <TableCell>{victim.first_name}</TableCell>
                    <TableCell>{victim.last_name}</TableCell>
                    <TableCell>{victim.age}</TableCell>
                    <TableCell>{readableEnum(victim.gender)}</TableCell>
                    <TableCell>{readableEnum(victim.race)}</TableCell>
                    <TableCell>{readableEnum(victim.cause_of_death)}</TableCell>
                    <TableCell>{victim.case.incident?.city || ""}</TableCell>
                    <TableCell>{victim.case.incident?.state || ""}</TableCell>
                    <TableCell>{formatDate(victim.case.incident?.date_of_incident || "")}</TableCell>
                    <TableCell>{formatDate(victim.date_of_death)}</TableCell>
                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {victims && victimsPageCount && (
        <Box textAlign="center" mt={5}>
          <Pagination disabled={isLoading} page={page} count={victimsPageCount} onChange={(e, p) => setPage(p)} />
        </Box>
      )}
    </div>
  );
}

export default function PageVictims () {
  return <AdminLayout component={<Page />} />;
}
