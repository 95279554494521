import React from "react";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useGetDocuments(token, case_id, refresh = false) {
  const [documents, setDocuments] = React.useState();
  const [error, setError] = React.useState();

  const handleResponse = (data) => {
    if (data) setDocuments(data);
    else setError("no-data");
  };

  React.useEffect(() => {
    if ((token && case_id && !documents) || (token && case_id && refresh)) {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const options = {
        method: "GET",
        headers: headers,
        redirect: "follow",
        cache: "no-cache",
      };

      // Make request
      fetch(`${PATHS.apiCase}/${case_id}/documents`, options)
        .then((response) => response.text())
        .then((result) => handleResponse(JSON.parse(result)))
        .catch((error) => setError(error));
    }
  }, [token, case_id, refresh]);

  return { documents, documentsError: error };
}
