import React from "react";
import lodash from "lodash";
import PATHS from "config/paths";

/* eslint-disable react-hooks/exhaustive-deps */

export function useDeleteContactPhone(token, contact_id, requestPhone,  isConfirmed) {
  const [phone, setPhone] = React.useState();
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Compare requestBody to phone so we don't infinite loop
   */
  React.useEffect(() => {
    if (!lodash.isEqual(requestPhone, phone) &&  isConfirmed) {
      setError(undefined);
      setResponse(undefined);
      setPhone(requestPhone);
      setIsLoading(true);
    }
  }, [requestPhone, setPhone,  isConfirmed]);

  /**
   * Make Request
   */
  React.useEffect(() => {
    if (token && phone ) {
      const options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };

      const path = phone.id ? `${PATHS.apiContacts}/${contact_id}/phone_number/${phone.id}` : `${PATHS.apiContacts}/${contact_id}/phone_number`;

      async function postRequest() {
        const request = await fetch(path, options);
        return true;
      }

      // Delete returns 204 No Content so it does not have a body, setResponse to true
      postRequest()
        .then(async (response) => {      
           setResponse(response)
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }, [token, phone, setResponse]);

  return { phoneDeleteSuccess: response, phoneDeleteError: error, phoneIsLoading: isLoading };
}
