import moment from "moment";

export function formatDateTime(string, raw = false) {
  let formattedDate = "";
  let date;

  if (string && string.length > 0) {
    date = moment(string, "YYYY-MM-DD HH:mm");
    formattedDate = date.format("M-D-YYYY hh:mm A");
  }

  if (formattedDate !== "Invalid date") {
    if (raw) {
      return date;
    } else {
      return formattedDate;
    }
  }

  return "";
}
