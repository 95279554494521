import React from "react";
import { StateContext } from "state.js";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { useRef } from "react";

import AdminLayout from "layouts/Admin.js";
import DateFnsUtils from '@date-io/date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem";
import Button from "components-material-ui/CustomButtons/Button.js";

import { TouchPointsReport } from "components/reports/TouchPointsReport";

import { useGetTouchpointsReport } from "hooks";


function Page() {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);

  const dateFormat = 'YYYY-MM-DD'
  /**
   * Adjust the the date to UTC 00:00 time regardless input by date picker keyboard or picked by mouse
   * 
   * @param {Date|null} date 
   * @returns Date|null
   */
   const getDateIgnoreTimezone = (date) =>
      date && date.getUTCHours() !== 0
        ? ((theDate) =>
            new Date(theDate.getTime() - theDate.getTimezoneOffset() * 60 * 1000))(
            new Date(date)
          )
        : date;
  const newDateWithTimezone = (theDate) => {
    return new Date(theDate.getTime() + theDate.getTimezoneOffset() * 60 * 1000);
  };
  const today = new Date();
  const firstOfMonth = newDateWithTimezone( new Date(today.getFullYear(), today.getMonth(), 1));
  const [startDate, setStartDate] = React.useState(firstOfMonth);
  const [endDate, setEndDate] = React.useState(today);
  const [aggregate, setAggregate] = React.useState("month");
  const [runTouchpointReport, setRunTouchpointReport] = React.useState(false);
  const [chartData, setChartData] = React.useState();
  const [tableData, setTableData] = React.useState();
  const [summaryData, setSummaryData] = React.useState({});
  
  const { touchpointData, touchpointDataError } = useGetTouchpointsReport(token, runTouchpointReport, startDate, endDate, aggregate);

  const handleStartDateChange = (val) => {
    const theDate = getDateIgnoreTimezone(val);
    setStartDate(theDate);
  }

  const handleEndDateChange = (val) => {
    const theDate = getDateIgnoreTimezone(val);
    setEndDate(theDate);
  }

  const handleAggregateChange = (e) => {
    const target = e.target;
    setAggregate(target.value)
  }

  React.useEffect(() => {
    if(touchpointData){
      var labels = [];
      var series1 = [];
      var series2 = [];
      
      var tableData = [];
      let i = 0;
      for (const [key, value] of Object.entries(touchpointData.data)) {
        labels.push(moment(key).format(dateFormat));
        series1.push(value["touches"]);
        series2.push(value["survivors"]);
      }
      setChartData({labels: labels, series: [series1, series2]})

      for (const [key, value] of Object.entries(touchpointData.data)) {
        const row = { date: moment(key).format(dateFormat), touches: value["touches"], survivors: value["survivors"] }
        tableData.push(row);
      }
      setTableData(tableData);
      setSummaryData(touchpointData.summary);
      setRunTouchpointReport(false)
    }
  }, [touchpointData])

  const componentRef = useRef();

  return (
    <div>
      <Box paddingBottom={5}>
        <Typography align="center" component="h1" variant="h2">
          Touchpoint Reports
        </Typography>
        <GridContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GridItem xs={12} sm={12} md={3}>
              <label>
                Group By
                <Select 
                  variant="outlined"                 
                  inputProps={{
                    name: "status",
                    id: "status",
                    onChange: handleAggregateChange,
                    value: aggregate
                  }}
                  
                >
                  <MenuItem value={"month"}>Month</MenuItem>
                  <MenuItem value={"week"}>Week</MenuItem>
                  <MenuItem value={"day"}>Day</MenuItem>                  
                </Select>
                
              </label>
            </GridItem>
            <GridItem xs={12} md={3}>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date"
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
            </GridItem>
            <GridItem xs={12} md={3}>
              <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date"
                  label="End Date"
                  value={endDate}
                  minDate={startDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
            </GridItem>
            <GridItem xs={12} md={3}>
              <Button variant="contained" color="primary" onClick={() => { setRunTouchpointReport(true) }}>
                Run Report
              </Button>
            </GridItem>
          </MuiPickersUtilsProvider>
        </GridContainer>
        {chartData &&
        <div>
          
          <TouchPointsReport  ref={componentRef}
            summaryData={summaryData}
            chartData = {chartData}
            tableData = {tableData}
            startDate = {moment(startDate).format(dateFormat)}
            endDate = {moment(endDate).format(dateFormat)}
            />
        </div>
        }
      </Box>
    </div>
  );
}

export default function PageReportsTouchpoints() {
  return <AdminLayout component={<Page />} />;
}
