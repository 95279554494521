import React from "react";
import { Suspense } from "react";
import Cookies from "js-cookie";
import { StateContext } from "state.js";
import { createBrowserHistory } from "history";
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import { GlobalStyle } from "styles/styled";
import { ActionCableProvider } from 'use-action-cable';

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

// Pages
import LoadingScreen from "pages/LoadingScreen.js";
import PageDashboard from "pages/PageDashboard.js";
import PageCalendar from "pages/PageCalendar.js";
import PageLogin from "pages/PageLogin.js";
import PageManageUsers from "pages/PageManageUsers.js";

import PageSurvivors from "pages/PageSurvivors";
import PageSurvivorsNew from "pages/PageSurvivorsNew";
import PageSurvivorProfile from "pages/PageSurvivorProfile";
import PageSurvivorSearch from "pages/PageSurvivorSearch";

import PageVictims from "pages/PageVictims.js";
import PageVictimsSearch from "pages/PageVictimsSearch.js";
import PageVictimsNew from "pages/PageVictimsNew.js";
import PageVictimProfile from "pages/PageVictimProfile.js";

import PageContactsList from "pages/PageContactsList";
import PageContactsView from "pages/PageContactsView";

import PageCaseSubmissions from "pages/PageCaseSubmissions";
import PageCaseSubmissionView from "pages/PageCaseSubmissionView";

import PageReportsTouchpoints from "pages/PageReportsTouchpoints";
import PageReportsCases from "pages/PageReportsCases";

import PageTasksList from "pages/PageTasksList";
import PageTasksView from "pages/PageTasksView";

import PageNotificationsList from "pages/PageNotificationsList";

import PageAdminImport from "pages/PageAdminImport";
/* eslint-disable react-hooks/exhaustive-deps */

const hist = createBrowserHistory();

import { AuthInterceptor } from 'utilities/fetch-intercept-auth';
AuthInterceptor();

export function App() {
  const [cookie] = React.useState(Cookies.get("pcc-user"));
  const [user, setUser] = React.useState(cookie ? JSON.parse(cookie) : undefined);
  const [notifications, setNotifications] = React.useState([]);
  const [notificationsRefresh, setNotificationsRefresh] = React.useState(false);
  const [isAuthed, setIsAuthed] = React.useState(undefined);
  // console.log("user", user);

  /**
   * Handle Log out
   * - Can be called from nav menu
   * - Can be called if token is not valid
   */
  const handleLogOut = () => {
    Cookies.remove("pcc-user");
    window.location = "/";
  };

  const userRole = user?.role || [];
  const canAccess = (role) => {
    if(role == undefined) return true;
    return userRole === role;
  }

  /**
   * Validated bearer token & restart session
   */
  React.useEffect(() => {
    if (!user) {
      setIsAuthed(false);
    } else if (user?.token) {
      setIsAuthed(true);
    } else {
      setIsAuthed(false);
    }
  }, []);

  /**
   * Finish login process
   */
  React.useEffect(() => {
    if (user && isAuthed === false) setIsAuthed(true);
  }, [user, isAuthed]);

  /**
   * Set up context values
   */
  const stateContexValues = {
    user: { value: user, set: setUser },
    handleLogOut: handleLogOut,
    canAccess: canAccess,
    notifications: { value: notifications, set: setNotifications },
    notificationsRefresh: { value: notificationsRefresh, set: setNotificationsRefresh }
  };

  return (
    <Suspense fallback="...is loading">
    <BrowserRouter>
      <StateContext.Provider value={stateContexValues}>
        <GlobalStyle />

        {isAuthed === undefined && <LoadingScreen />}

        {isAuthed === false && <PageLogin />}

        {isAuthed === true && (
          <ActionCableProvider url={`${process.env.REACT_APP_CABLE}?token=${user.token}`}>

          <Router history={hist}>
            <Switch>
              <Route path="/calendar" component={PageCalendar} />

              {/* Victims */}
              <Route path="/victims/browse" component={PageVictims} />
              <Route path="/victims/search" component={PageVictimsSearch} />
              <Route path="/victims/new" component={PageVictimsNew} />
              <Route path="/victims/:id" component={PageVictimProfile} />

              {/* Survivors */}
              <Route path="/survivors/browse" component={PageSurvivors} />
              <Route path="/survivors/search" component={PageSurvivorSearch} />
              <Route path="/survivors/new" component={PageSurvivorsNew} />
              <Route path="/survivors/:id" component={PageSurvivorProfile} />

              {/* Contacts */}
              <Route path="/contacts/browse" component={PageContactsList} />
              <Route path="/contacts/view/:id" component={PageContactsView} />

              {/* Case Submissions */}
              <Route path="/submissions/browse" component={PageCaseSubmissions} />
              <Route path="/submissions/view/:id" component={PageCaseSubmissionView} />

              {/* tasks */}
              <Route path="/tasks/browse" component={PageTasksList} />
              <Route path="/tasks/view/:id" component={PageTasksView} />

              {/* Notifications */}
              <Route path="/notifications/browse" component={PageNotificationsList} />

             
              {/* Admin */}
              <Route path="/admin/import" component={PageAdminImport} />
              <Route path="/manage-users" component={PageManageUsers} />
              {/* Reports */}
              <Route path="/reports/touchpoints" component={PageReportsTouchpoints} />
              <Route path="/reports/cases" component={PageReportsCases} />
              
              
              <Route path="/" component={PageDashboard} />
            </Switch>
          </Router>
          </ActionCableProvider>
        )}
      </StateContext.Provider>
    </BrowserRouter>
    </Suspense>
  );
}
