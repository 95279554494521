import React from "react";
import Cookie from "js-cookie";
import PATHS from "config/paths";
import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { StyledLoginPage } from "styles/StyledLoginPage.js";
import { StateContext } from "state.js";
import { Danger } from "components";

// Tim Creative
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";
import CardHeader from "components-material-ui/Card/CardHeader.js";
import CustomInput from "components-material-ui/CustomInput/CustomInput.js";
import CardFooter from "components-material-ui/Card/CardFooter.js";
import SnackbarContent from "components-material-ui/Snackbar/SnackbarContent.js";

// Material UI
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";

// Icons
import Email from "@material-ui/icons/Email";

var ApiV1 = require("api_v1");

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PageLogin() {
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const State = React.useContext(StateContext);
  const history = useHistory();
  let query = useQuery();
  const logout = query.get("logout");
  const message = query.get("message");
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = "Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
      }

      if (!values.password) {
        errors.password = "Required";
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      setError(false);
      const client = ApiV1.ApiClient.instance;
      client.basePath = process.env.REACT_APP_API_PATH;
      const api = new ApiV1.SessionsApi();
      const opts = {
        email: values.email,
        passwordDigest: values.password,
      };

      api.apiV1SessionsPost(opts).then(
        function (data) {
          const attributes = data.data.attributes;
          Cookie.set("pcc-user", JSON.stringify(attributes));
          State.user.set(attributes);
          history.push(PATHS.root);
        },
        function (error) {
          console.error(error);
          setError(true);
          setIsLoading(false);
        }
      );
    },
  });

  const emailHelperText = formik.errors.email && formik.submitCount ? <Danger>{formik.errors.email}</Danger> : null;
  const passwordHelperText =
    formik.errors.password && formik.submitCount ? <Danger>{formik.errors.password}</Danger> : null;

  return (
    <StyledLoginPage>
      <form onSubmit={formik.handleSubmit}>
        <input type="submit" style={{ display: "none" }} />

        {logout && (
          <Box mb={5}>
            <Card>
              <CardBody>
                <Box textAlign="center">{message ? <>{message}</> : <>You have been signed out!</>}</Box>
              </CardBody>
            </Card>
          </Box>
        )}

        <Card>
          <CardHeader color="primary">
            <Box component="h4" textAlign="center">
              Project Cold Case Login
            </Box>
          </CardHeader>
          <CardBody>
            {error && <SnackbarContent message={"Failed to log in"} color="rose" />}

            <CustomInput
              helperText={emailHelperText}
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => formik.handleChange(e),
                name: "email",
                endAdornment: (
                  <InputAdornment position="end">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <CustomInput
              helperText={passwordHelperText}
              labelText="Password"
              id="password"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: (e) => formik.handleChange(e),
                name: "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon>lock_outline</Icon>
                  </InputAdornment>
                ),
                type: "password",
                autoComplete: "off",
              }}
            />
          </CardBody>
          <CardFooter>
            <Box textAlign="center" width="100%">
              <Button
                color="primary"
                disabled={isLoading ? true : false}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Log in
              </Button>
            </Box>
          </CardFooter>
        </Card>
      </form>
    </StyledLoginPage>
  );
}
