import React from "react";
import { Danger, DownloadDocument, InputText } from "components";
import { useFormik } from "formik";
import { StateContext } from "state.js";
import { useGetDocuments, usePostDocuments } from "hooks";

// Creative Tim
import GridContainer from "components-material-ui/Grid/GridContainer.js";
import GridItem from "components-material-ui/Grid/GridItem.js";
import Button from "components-material-ui/CustomButtons/Button.js";
import Card from "components-material-ui/Card/Card.js";
import CardBody from "components-material-ui/Card/CardBody.js";

// Material UI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { color } from "styles/styled";
import  Snackbar  from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';


/* eslint-disable react-hooks/exhaustive-deps */

export function Documents(props) {
  const State = React.useContext(StateContext);
  const [token] = React.useState(State?.user?.value?.token);
  const [requestBody, setRequestBody] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [view, setView] = React.useState("documents");
  const [cashedDocuments, setCachedDocuments] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const { documents } = useGetDocuments(token, props?.case?.id, refresh);
  const { upload, uploadError } = usePostDocuments(token, props?.case?.id, requestBody);
  const [selectedDocument, setSelectedDocument] = React.useState();
  const [showArchived, setShowArchived] = React.useState(false);

  const FILE_SIZE = 25 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpeg", 
    "image/png", 
    "image/jpg", 
    "application/pdf", 
    "application/rtf", 
    "text/plain",
    "application/msword", 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const formik = useFormik({
    initialValues: {
      file: null,
      title: selectedDocument?.title ? selectedDocument.title : "",
      description: selectedDocument?.description ? selectedDocument.description : "",
      note: selectedDocument?.note ? selectedDocument.note : "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.title) errors.title = "Required";
      if (!values.description) errors.description = "Required";
      if (!values.note) errors.note = "Required";

      if (values.file == undefined && values.file == null) errors.file = "Required";

      if (values.file !== undefined && values.file !== null) {
        const size = Math.round(values.file.size / 1024);
        if (!SUPPORTED_FORMATS.includes(values.file.type)) {
          errors.file = "Unsupported format " + values.file.type;
        }
        if (size > FILE_SIZE) {
          errors.file = "File should not be more than 25MB.";
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setView("loading");
      setRequestBody({
          update: false,
          body: {
          file: values.file,
          title: values.title,
          document_type: values.file.type,
          description: values.description,
          note: values.note,
        }
      });
    },
  });

  const formik2 = useFormik({
    initialValues: {
      id: selectedDocument?.id,
      title: selectedDocument?.title ? selectedDocument.title : "",
      description: selectedDocument?.description ? selectedDocument.description : "",
      note: selectedDocument?.note ? selectedDocument.note : "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.title) errors.title = "Required";
      if (!values.description) errors.description = "Required";
      if (!values.note) errors.note = "Required";

      return errors;
    },
    onSubmit: (values) => {
      setView("loading");
      setRequestBody({
          update: true,
          body: {
            id: values.id,
          title: values.title,
          description: values.description,
          note: values.note,
        }
      });
    },
    enableReinitialize: true,
  });

  const handleReset = () => {
    formik.handleReset();
    formik2.handleReset();
    setView("documents");
    setRequestBody(undefined);
    setSelectedDocument(undefined);
  };

  const handleArchive = (value) => {
    setView("loading");
      setRequestBody({
        update: true,
        body:
        {
          id: value.id,
          archived: !value.archived
        }
      });
  }

  const handleEdit = (value) => {
    setSelectedDocument(value);
  }

  const HelperText = {
    file: formik.errors.file && formik.submitCount ? <Danger>{formik.errors.file}</Danger> : null,
    title: formik.errors.title && formik.submitCount ? <Danger>{formik.errors.title}</Danger> : null,
    description: formik.errors.description && formik.submitCount ? <Danger>{formik.errors.description}</Danger> : null,
    note: formik.errors.note && formik.submitCount ? <Danger>{formik.errors.note}</Danger> : null,
  };

  const getFreshFileUrl = (fileId) => {
    // do stuff
  };

  const handleClose = () => {
    setSuccess(false);
    setError(false);
  }
  const handleShowArchived = () => {
    setShowArchived(!showArchived);
  }

  React.useEffect(() => {
    if(selectedDocument) {
      setView("edit");
    }
  }, [selectedDocument])

  React.useEffect(() => {
    if (upload) {
      setRefresh(true);
      handleReset();
      setSuccess(true);
    }

    if (uploadError) setError(true);
  }, [upload, uploadError]);

  React.useEffect(() => {
    if (documents) {
      setRefresh(false);
      setCachedDocuments(documents);
    }
  }, [documents]);

  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Box component="h3" mt="7px" mb={0}>
                Documents
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Box textAlign="right">
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button size="sm" onClick={() => handleReset()} disabled={view === "documents" ? true : false}>
                    View Documents
                  </Button>
                  <Button size="sm" onClick={() => setView("upload")} disabled={view === "upload" ? true : false}>
                    New Document
                  </Button>
                </ButtonGroup>
              </Box>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      {success && (
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Document Update succesful!
          </Alert>
      </Snackbar>
      )}

      {error && (       
        <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
            Error uploading document, please try again.
            </Alert>
        </Snackbar>
      )}

      {view === "loading" && (
        <Box textAlign="center" pb={5}>
          <p>Please wait</p>
          <CircularProgress />
        </Box>
      )}

      {view === "upload" && (
        <Card>
          <CardBody>
            <Box mb={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputText
                    helperText={HelperText.title}
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputText
                    helperText={HelperText.description}
                    label="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </GridItem>
              </GridContainer>
            </Box>

            <Box mb={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputText
                    helperText={HelperText.note}
                    label="Note"
                    name="note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    onChange={(event) => {
                      formik.setFieldValue("file", event.currentTarget.files[0]);
                    }}
                  />
                  {formik.submitCount > 0 && formik.errors.file && <Danger>{formik.errors.file}</Danger>}
                </GridItem>
              </GridContainer>
            </Box>

            <Box mb={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}></GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button color="info" onClick={formik.handleSubmit} fullWidth={true}>
                    Upload
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}></GridItem>
              </GridContainer>
            </Box>
          </CardBody>
        </Card>
      )}

      {view === "edit" && (
        <Card>
          <CardBody>
            <Box mb={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputText
                    helperText={HelperText.title}
                    label="Title"
                    name="title"
                    value={formik2.values.title}
                    onChange={formik2.handleChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputText
                    helperText={HelperText.description}
                    label="Description"
                    name="description"
                    value={formik2.values.description}
                    onChange={formik2.handleChange}
                  />
                </GridItem>
              </GridContainer>
            </Box>

            <Box mb={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputText
                    helperText={HelperText.note}
                    label="Note"
                    name="note"
                    value={formik2.values.note}
                    onChange={formik2.handleChange}
                  />
                </GridItem>
               
              </GridContainer>
            </Box>

            <Box mb={6}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}></GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button color="info" onClick={formik2.handleSubmit} fullWidth={true}>
                    Save
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}></GridItem>
              </GridContainer>
            </Box>
          </CardBody>
        </Card>
      )}
      {view === "documents" && (
       
        <Card>
          <CardBody>
          <FormControlLabel
            control={
              <Checkbox
                checked={showArchived}
                onChange={handleShowArchived}
                name="showArchived"
                color="primary"
              />
            }
                label="Show Archived"
            />
          </CardBody>
        </Card>
      )}

      {view === "documents" && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Note</TableCell>
                <TableCell>File Type</TableCell>
                <TableCell style={{ width: "200px" }}></TableCell>
                <TableCell style={{ width: "200px" }}>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cashedDocuments.map((doc, index) => {
                if(showArchived || !doc.archived){
                return (
                  <TableRow key={index}>
                    <TableCell>{doc.title}</TableCell>
                    <TableCell>{doc.description}</TableCell>
                    <TableCell>{doc.note}</TableCell>
                    <TableCell>{doc.document_type}</TableCell>
                    <TableCell style={{ width: "200px" }}>
                    <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(doc)}>
                      <EditIcon />
                    </IconButton>
                    <Tooltip title={doc.archived ? "Unarchive" : "Archive"}>

                    <IconButton aria-label="archive" color={doc.archived ? "primary" : "secondary"} onClick={() => handleArchive(doc)}>
                      {doc.archived && ( <UnarchiveIcon /> )}
                      {!doc.archived && ( <ArchiveIcon /> )}
                    </IconButton>
                    </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: "200px" }}>
                      <DownloadDocument id={doc.id} />
                    </TableCell>
                  </TableRow>
                );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
